import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown,
  faDollar,
  faPlaneDeparture
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../Components/Layout/Layout'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import PackageDetailCard from '../../Components/PackageDetailCard/PackageDetailCard'
import bgimage from '../../Images/Packages/kabapic.jpg'
import { useSelector } from 'react-redux';
import PackageSearch from '../../Components/SearchBar/PackageSearch'
import Helmet from 'react-helmet';
function SearchTour () {
  const ToursDetail = useSelector((state) => state.hotels.toursdetail);
  const amounts = ToursDetail.tours.map(tour => tour.quad_grand_total_amount);
  
      var minValue1=0;
      var maxValue1 = 0;
      if(amounts.length !==0){
        minValue1 = Math.min(...amounts);
         maxValue1 = Math.max(...amounts);
      };
      const [showfilter, setShowfilter] = useState({departure:true,rating:true});
  const [minValue, setMinValue] = useState(minValue1);
  const [maxValue, setMaxValue] = useState(maxValue1);
  const [filterData, setFilterData] = useState('');
  const [rangeValue, setRangeValue] = useState([minValue1, maxValue1]);
  const [ShowPriceFilter, setShowPriceFilter] = useState(false);
    const [ShowAirportFilter, setShowAirportFilter] = useState(false);
    const [ShowRatingFilter, setShowRatingFilter] = useState(false);
  const [filterAirports, setFilterAirports] = useState([]);
  const [airportFilter, setAirportFilter] = useState({type:'airport'});
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const sliderRef = useRef(null);
  useEffect(() => {
    getdepartureairports();
    const slider = sliderRef.current
    noUiSlider.create(slider, {
      start: [minValue, maxValue],
      connect: true, // Initial values
      range: {
        min: minValue1, // Minimum value
        max: maxValue1 // Maximum value
      }
    })

    slider.noUiSlider.on('update', (values, handle) => {
      if (handle === 0) {
        setMinValue(parseInt(values[handle]))
      } else {
        setMaxValue(parseInt(values[handle]))
      }
    })

    return () => slider.noUiSlider.destroy()
  }, []);

  const handlefilter =()=>{
    setShowPriceFilter(false);
    var data={min:minValue,max:maxValue,type:'price'};
    setFilterData(data);
  };
 
  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;

    setStarRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="0") {
        updatedStarRating[name]="0";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };
  const StarFilter=()=>{
    setShowRatingFilter(false);
    setFilterData(starRating);
  };
const getdepartureairports=()=>{
  var airports=[];
  ToursDetail.tours.forEach(element => {
     var flightdetail=JSON.parse(element.flights_details);
      if(flightdetail !==null){
        var departureAirportCode = flightdetail[0].departure_airport_code;

        // Check if the departure airport code is not already in the array
        if (!airports.includes(departureAirportCode)) {
          airports.push(departureAirportCode);
        }
      }
  });
  
  setFilterAirports(airports);
};

const handleairportsvheckbox=(event)=>{
  const {name,value}=event.target;
  setAirportFilter(prevData => {
    const updatedData = { ...prevData };
  
    if (updatedData.hasOwnProperty(name)) {
      // If the key already exists, remove it
      delete updatedData[name];
    } else {
      // If the key doesn't exist, add it
      updatedData[name] = value;
    }
  
    return updatedData;
  });
};

const Showfilter=(num)=>{
  if(num===1){
    setShowfilter(prevData => ({ ...prevData, departure: !prevData.departure }));
  }
  if(num===2){
    setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }));
  }
};

const filterAirportscall =()=>{
  setShowAirportFilter(false);
  setFilterData(airportFilter);
};

const TogglePriceFilter=()=>{
  setShowPriceFilter(!ShowPriceFilter);
};
const ToggleAirportFilter=()=>{
  setShowAirportFilter(!ShowAirportFilter);
};
const ToggleRatingFilter=()=>{
  setShowRatingFilter(!ShowRatingFilter);
};

const handleChange = (newRangeValue) => {
  setRangeValue(newRangeValue);
  setMinValue(newRangeValue[0]);
    setMaxValue(newRangeValue[1]);
};

  return (
    <>
        <Modal isOpen={ShowPriceFilter} toggle={TogglePriceFilter}>
          <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  {/* <label className='form-label'>Price Level</label> */}
                  {/* <div ref={sliderRef} /> */}
                  <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={minValue1}
                      tooltip={false}
                      max={maxValue1}
                      step={1}
                    />
                  <div className='pt-5'>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{rangeValue[0]}</span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{rangeValue[1]}</span>
                    </div>
                  </div>
                  <button onClick={handlefilter} className='btn select-styling search-btn1 mb-1'>Filter</button>
                </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowAirportFilter} toggle={ToggleAirportFilter}>
          <ModalHeader toggle={ToggleAirportFilter}>Departure Airport Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
               
                <div className='filter-show-hide'>                
                {/* <h3>Filter by Departure Airport</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  {filterAirports.map((item,index)=>(
                  <li key={index}>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleairportsvheckbox}  name={`item${index+1}`} value={item} />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>{' '}
                      {item}
                      </span>
                    </label>
                  </li>
                   ))}
                  </ul>
                  <button onClick={filterAirportscall} className='btn select-styling search-btn1 mb-1'>Filter</button>
                  </div>

                </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                {/* <h3>Star Rating</h3>                 */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
               <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={StarFilter} className='btn select-styling search-btn1'>Filter</button>
                </div>
               
              </div>
          </ModalBody>
        </Modal>
      <Helmet>
        <meta property="og:title" content="Book Umrah Package | Select From Available All-Inclusive Umrah Deals & Luxury Umrah Offers to Cheapest Umrah Packages"/>
          <title>Book Umrah Package | Select From Available All-Inclusive Umrah Deals & Luxury Umrah Offers to Cheapest Umrah Packages</title>
           <meta name="keywords" content="Umrah Packages, Low Cost Umrah Packages, Umrah Packages from Birmingham, 5 star Umrah packages, Makkah Hotels, Medina Hotels, Umrah visa, tourist visa, Umrah with flights, book hotels,Umrah 2024, Umrah 2023" />
           <meta property="og:description" content="Al Hijaz Tours Ltd offers huge range of cautiously devised Umrah packages for families, groups, couples, ladies & individual pilgrims. Select from all-inclusive Umrah deals with 5 star amenities, affordable Umrah packages with 4 star facilities or cheapest Umrah offers designed specifically with flights from UK airports & close to Haram hotels. Avail assistance from expert travel advisors to customize these Umrah packages that suits your requirements & get fast visa processing." />
          <meta name="description" content="Al Hijaz Tours Ltd offers huge range of cautiously devised Umrah packages for families, groups, couples, ladies & individual pilgrims. Select from all-inclusive Umrah deals with 5 star amenities, affordable Umrah packages with 4 star facilities or cheapest Umrah offers designed specifically with flights from UK airports & close to Haram hotels. Avail assistance from expert travel advisors to customize these Umrah packages that suits your requirements & get fast visa processing." />
      </Helmet>
    <Layout>
      <div className='container-fluid px-4'>
        {/* <h3 className='title font-size-24 tc' id='tours_result'>{ToursDetail.tours.length} Result found</h3> */}
        <div className="row mt-4"><PackageSearch/></div>
        <div className='row'>
          <div class="uitk-spacing uitk-spacing-padding-small-block-three uitk-spacing-padding-medium-blockstart-one uitk-spacing-padding-large-blockstart-three">
            <div class="uitk-layout-grid uitk-layout-grid-align-content-center uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid" data-test-id="uitk-progress-indicator">
              <div class="uitk-layout-grid-item uitk-progress-indicator-container uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-extra_large">
                <ul class="uitk-progress-indicator unfade-end">
                  <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 1 of 4. Choose your stay. Current page, Choose your stay">
                    <span class="is-visually-hidden">Current: Choose your stay</span>
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="true">
                      <span><span class="">Choose your stay</span></span>
                    </div>
                    <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                    </svg>
                  </li>
                  <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 2 of 4. Choose departing flight., Choose departing flight">
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="false">
                      <span><span class="">Choose departing flight</span></span>
                    </div>
                    <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                    </svg>
                  </li>
                  <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 3 of 4. Choose returning flight, Choose returning flight">
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="false">
                      <span><span class="">Choose returning flight</span></span>
                    </div>
                    <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                    </svg>
                  </li>
                  <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 4 of 4. Review your trip, Review your trip">
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="false">
                      <span><span class="">Review your trip</span></span>
                    </div>
                  </li>
                </ul>
                <div class="uitk-progress-indicator-fade-start">
                  <div class="uitk-scrim fade-leading secondary-background"></div>
                </div>
                <div class="uitk-progress-indicator-fade-end">
                  <div class="uitk-scrim fade-trailing secondary-background"></div>
                </div>
              </div>
              <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-layout-grid-justify-items-end uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-extra_large"></div>
            </div>
          </div>
          <div className='col-md-3 col-md-pull-9'>
            <div class="mobile-Filter-info">
                <ul>
                    <li><p onClick={TogglePriceFilter}><FontAwesomeIcon icon={faDollar}/> Price</p></li>
                    <li><p onClick={ToggleAirportFilter}><FontAwesomeIcon icon={faPlaneDeparture}/><span > Departure</span></p>
                    </li>
                    <li><p onClick={ToggleRatingFilter}><FontAwesomeIcon icon={faStar}/><span > Rating</span></p>
                    </li>
                </ul>
            </div>
            <div className='page-sidebar hide-page_filter'>
              <div id='map-container-google-2' className='z-depth-1-half map-container mt-3' style={{ height: '500' }}>
                <iframe
                  src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                &q=Mecca+Saudi+Arabia'
                  frameborder='0'
                  allowfullscreen
                  className='rounded-4'
                ></iframe>
              </div>
              <div class="uitk-spacing uitk-spacing-margin-blockstart-six uitk-spacing-padding-block-six uitk-spacing-border-blockend uitk-spacing-border-blockstart">
                <h3 class="uitk-heading uitk-heading-5">Search by property name</h3>
                <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                  <div>
                    <div class="uitk-typeahead">
                      <div>
                        <div class="uitk-field has-floatedLabel-label has-icon">
                          <label class="uitk-field-label is-visually-hidden">e.g. Marriott</label>
                          <input type="text" class="uitk-field-input is-hidden empty-placeholder" placeholder="" aria-required="false" aria-invalid="false" value=""/>
                          <button aria-label="e.g. Marriott" aria-expanded="false" data-stid="" class="uitk-fake-input uitk-form-field-trigger" type="button"></button>
                          <div class="uitk-field-label" aria-hidden="true">e.g. Marriott</div>
                          <svg class="uitk-icon uitk-field-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M14.71 14h.79l4.99 5L19 20.49l-5-4.99v-.79l-.27-.28a6.5 6.5 0 1 1 .7-.7l.28.27zM5 9.5a4.5 4.5 0 1 0 8.99.01A4.5 4.5 0 0 0 5 9.5z" clip-rule="evenodd"></path>
                          </svg>
                        </div>
                        <div class="uitk-field has-floatedLabel-label has-icon">
                          <label class="uitk-field-label is-visually-hidden">e.g. Marriott</label>
                          <input type="text" name="hotelName" value="" class="uitk-field-input is-hidden empty-placeholder" aria-required="false" aria-invalid="false"/>
                          <div class="uitk-field-label" aria-hidden="true">e.g. Marriott</div>
                          <svg class="uitk-icon uitk-field-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M14.71 14h.79l4.99 5L19 20.49l-5-4.99v-.79l-.27-.28a6.5 6.5 0 1 1 .7-.7l.28.27zM5 9.5a4.5 4.5 0 1 0 8.99.01A4.5 4.5 0 0 0 5 9.5z" clip-rule="evenodd"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{opacity:'1'}} />
              <h3 class="uitk-heading uitk-heading-5 mb-3">Filter by</h3>
              <div className='widget widget_price_filter pb-3' style={{borderBottom:'0'}}>
                <div className='mb-3'>
                  <label className='uitk-heading uitk-heading-7 mb-2'>Price Level</label>
                  <div className='py-2 d-flex' style={{justifyContent:'space-between'}}>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{minValue}</span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{maxValue}</span>
                    </div>
                  </div>
                  <div ref={sliderRef} />
                </div>
                  <a onClick={handlefilter} className='select-styling' style={{cursor:'pointer'}}>Filter</a>
              </div>
              <div className='widget widget_has_radio_checkbox pb-3' style={{borderBottom:'0'}}>
                <div className='filter-show-hide'>                
                  <h4 className='uitk-heading uitk-heading-7 mb-2'>Filter by Departure Airport</h4>
                  {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                <div>
                 <ul>
                {filterAirports.map((item,index)=>(
                <li key={index}>
                  <label>
                    <input type='checkbox' className='custom-textbox' onChange={handleairportsvheckbox}  name={`item${index+1}`} value={item} />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>{' '}
                    {item}
                    </span>
                  </label>
                </li>
                  ))}
                </ul>
                <a onClick={filterAirportscall} className='select-styling mb-1' style={{cursor:'pointer'}}>Filter</a>
                </div>
                {/* {showfilter.departure && (
                    )} */}
              </div>
              <div className='widget widget_has_radio_checkbox pb-3' style={{borderBottom:'0'}}>
                <div className='filter-show-hide'>                
                  <h4 className='uitk-heading uitk-heading-7 mb-2'>Star Rating</h4>                
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                <div>
                <ul className='uitk-button-toggle-group'>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10" name='rating1' value='1' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange}/>
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">1</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10"name='rating2' value='2' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange} />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">2</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10" name='rating3' value='3' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange} />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">3</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10" name='rating4' value='4' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange} />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">4</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline" onChange={handleCheckboxChange}>
                    <label class="uitk-button-toggle-content" for="rating5">
                      <input type="checkbox" aria-label="5 star" id="rating5" name='rating5' value='5' class="uitk-button-toggle-input is-visually-hidden" onChange={handleCheckboxChange} aria-checked="false" />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">5</span>
                    </label>
                  </div>
                  {/* <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li> */}
                </ul>
                <a onClick={StarFilter} className='select-styling ' style={{cursor:'pointer'}}>Filter</a>
                </div>
                {/* {showfilter.rating && (
                 )} */}
              </div>
              <div className='widget widget_product_tag_cloud mt-2 pb-3' style={{borderBottom:'0'}}>
                <h4 className='uitk-heading uitk-heading-7 mb-2'>Tags</h4>
                <div className='tagcloud'>
                  <a href='#'>Hotel</a>
                  <a href='#'>Motel</a>
                  <a href='#'>Hostel</a>
                  <a href='#'>Homestay</a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-9 col-md-push-3 px-4'>
              <section data-stid="results-header" class="uitk-spacing uitk-spacing-margin-blockstart-one">
                <header style={{boxShadow:'none !important'}}>
                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid tour-main">
                    <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-5">
                      <div data-stid="results-header-messages">
                        <a href="https://www.expedia.com/lp/b/sort-order-info" target="_blank" rel="noopener" analytics="[object Object]" class="uitk-more-info-trigger uitk-more-info-trigger-size-small uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading">
                          <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme"><b style={{fontWeight:'bold'}}>How our sort order works</b>
                            <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlinestart-one uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-sort">
                      <div class="">
                        <form class="uitk-form">
                          <div>
                            <div class="uitk-field uitk-field-select-field has-floatedLabel-label">
                              <label for="sort-filter-dropdown-sort" class="uitk-field-label is-visually-hidden">Sort by</label>
                              <select id="sort-filter-dropdown-sort" name="sort" class="uitk-field-select">
                                <option selected="" value="RECOMMENDED" aria-selected="true" data-opt-id="RECOMMENDED">Recommended</option>
                                <option value="PRICE_LOW_TO_HIGH" aria-selected="false" data-opt-id="PRICE_LOW_TO_HIGH">Price: low to high</option>
                                <option value="PRICE_HIGH_TO_LOW" aria-selected="false" data-opt-id="PRICE_HIGH_TO_LOW">Price: high to low</option>
                                <option value="DISTANCE" aria-selected="false" data-opt-id="DISTANCE">Distance from downtown</option>
                                <option value="REVIEW_RELEVANT" aria-selected="false" data-opt-id="REVIEW_RELEVANT">Guest rating + our picks</option>
                                <option value="PROPERTY_CLASS" aria-selected="false" data-opt-id="PROPERTY_CLASS">Star rating</option>
                                <option value="PACKAGE_SAVINGS" aria-selected="false" data-opt-id="PACKAGE_SAVINGS">Package discount</option>
                              </select>
                              <svg class="uitk-icon uitk-field-select-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                              </svg>
                              <div class="uitk-field-label" aria-hidden="true">Sort by</div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </header>
              </section>
              <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 my-2">
                <div data-testid="one-key-banner-1" id="one-key-banner-1" className="px-0">
                    <div>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0" data-stid="one-key-message-card">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                                <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                    <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                        <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                    </div>
                                </div>
                                <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                            <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">Earn OneKeyCash on this cruise when you sign in and book</h2>
                                            </div>
                                        </div>
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                            <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                              <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              {ToursDetail.tours.length == 0 ?(
                <div className='text-center'>
                <h3>Sorry! No Package Available.</h3>
                </div>
              ):(
                <PackageDetailCard filterData={filterData} />
              )}
          </div>
        </div>
      </div>
      </Layout>
    </>
  )
}

export default SearchTour
