import React, { useState, useEffect } from 'react'
import logo from './logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba,
  faDownload,
  faGlobe,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import LanguageSwitcher from '../Activity/TranslationTrigger';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
function Navbar2 () {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')
 
  // This function put query that helps to
  // change the language

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
    }
  }, []);

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  };

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal();
    setActiveIndex(index);
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));
        
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    };

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    };
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        sessionStorage.setItem('Catogories',JSON.stringify(response.data.categories));
      setDropdownCat(response.data.categories);
     
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const FindPackageDetail = id => {
    toggleDropdown();
    navigation('/umrah_packages', { state: id })
  };
  const languages = [
    { value: "en", text: "English" },
    { value: "ar-SA", text: "Arabic" },
];

  return (
    <>
      <header style={{ boxShadow: '0 2px 1px 0 rgba(12,14,28,.12)'}}>
        <div className='navbar-top'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 navbar-img col-sm-12'>
              <div className='p-2 d-flex'>
                <img src="https://www.expedia.com/_dms/header/logo.svg?locale=en_US&amp;siteid=1&amp;2&amp;b4e14a36" alt="expedia logo" height={40} />
                {/* <button title="More travel" tabindex="0" data-testid="header-menu-button" aria-expanded="false" data-context="global_navigation" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-tertiary uitk-menu-trigger global-navigation-nav-button d-flex">
                  <h5 style={{color:'black', fontSize:'14px'}}>More travel</h5>
                   <i style={{paddingLeft:'10px'}}><FontAwesomeIcon icon={faAngleDown} /></i>
                </button> */}
              </div>
            </div>
            {/* <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className='navbar-top-right'>
                <a href="https://expe.app.link/?~customer_ad_set_name=EXPEDIA-US&amp;~customer_ad_name=BRAND.EXPEDIA.OWNED.BRANCH.DEFAULT.MAD&amp;~customer_campaign=BRAND&amp;~customer_placement=BEX-NAVBAR-DESKTOP-CTADESKTOP&amp;~placement_id=Homepage&amp;%24desktop_url=https%3A%2F%2Fwww.expedia.com%2Fapp&amp;custom_web_attribute=33609860-2580-4f05-89f0-e368a116768f&amp;%24canonical_url=https%3A%2F%2Fwww.expedia.com%2F" data-context="global_navigation" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-secondary uitk-layout-flex-item d-flex">
                   <i><FontAwesomeIcon icon={faDownload} /></i>
                  <div class="uitk-text uitk-type-300 uitk-text-white-space-nowrap uitk-text-default-theme"><h5 style={{color:'black', fontSize:'14px'}}>Get the app</h5></div>
                </a>
                <div className='d-flex top-button-effect non'><button className='btn btn-success d-flex non'>
                <i><FontAwesomeIcon icon={faGlobe} /></i> <h5 className='non' style={{color:'black', fontSize:'14px'}}>English</h5> </button> </div>
                <div className='d-flex top-button-effect non'>  
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}>List your property</h5> </button>
                </div>
                <div className='d-flex top-button-effect non'>
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}>Support</h5> </button>
                </div>
                <div className='d-flex top-button-effect'>
                  <button className='btn btn-success'> <h5 style={{color:'black', fontSize:'14px'}}> Trips</h5> </button>
                </div>
                <div className='d-flex top-button-effect'>
                  <button className='btn btn-success '> 
                    <FontAwesomeIcon className='navbar-sign-in' icon={faBell} />
                  </button>
                </div>
                <div className='d-flex top-button-effect'>
                  <button className='btn btn-success '>
                    <h5 style={{color:'black', fontSize:'14px'}}>Sign in</h5>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        
      </header>

      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                  countryList.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))
                : // Render the filtered list when there is a search input
                  userCountry.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar2
