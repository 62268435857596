import React from "react";
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowRestore ,faLocationArrow} from '@fortawesome/free-solid-svg-icons'

function Footer2(){
    return(
    <>

       <div data-global-control="footer" id="site-footer-background" class="site-footer-background" role="contentinfo" aria-label="footer">
  <div id="site-footer-wrap" class="site-footer-wrap cols-nested" style={{textAlign:'center'}}>
    <a id="nav-tool-feedback" rel="nofollow" target="_top" href="javascript:void(0)" aria-label="nav-tool-feedback" onclick="xp.nav.trackAnalytics(this,'a','Head:Nav:None:Opinion');OpinionLab_FB.O_LC();return false;" class="trigger tip mb-3">
            <span aria-hidden="true">[+]</span>
Tell us what you think<span class="icon icon-popup" aria-hidden="true"></span><span class="alt">Opens in a new window.</span>        </a>
<ul class="minimal-links">
        <li class="link-item">

            <a id="privacy-minimal-link" href="//www.expedia.com/privacy" target="_blank">
                Privacy Policy  <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span>
            </a>

        </li>
        <li class="link-item">

            <a id="terms-minimal-link" href="//www.expedia.com/terms" target="_blank">
                Terms of Use <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span>
            </a>

        </li>
        <li class="link-item">

            <a id="Accessibility-minimal-link" href="//www.expedia.com/p/info-other/web-accessibility-policy" target="_blank">
                Accessibility <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span>
            </a>

        </li>
        <li class="link-item">

            <a id="Your Privacy Choices-minimal-link" href="//www.expedia.com/dnsmpi" target="_blank">
                Your Privacy Choices <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span>
            </a>

        </li>

            <li class="minimal-copyright">©&nbsp;2024 Expedia, Inc, an Expedia Group Company. All rights reserved.
</li>
</ul>

      <div class="badge-container">
         <a href="https://www.expediagroup.com" target="_blank" rel="noopener noreferrer">
            <img id="EG-logo" alt="Expedia Group Logo. Opens in a new window." src="https://a.travel-assets.com/globalcontrols-service/content/f285fb631b0a976202ef57611c7050e9ef5ca51a/images/EG_Wordmark_blue_RGB.svg"/></a>
      </div>
</div>
</div>
    </>        
    );
}

export default Footer2;