import React, { useEffect, useState } from 'react'
import bgimage from '../../Images/Activity/cover.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocation,
  faCheck,
  faClock,
  faCalendarCheck,
  faCalendarAlt,
  faStar,
  faCalendar,
  faMapLocation,
  faCalendarTimes
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../Components/Layout/Layout'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Carousel from 'react-bootstrap/Carousel'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify'
import {
  ApiEndPoint,
  ActivityToken,
  CustomerDomainName
} from '../../Components/GlobalData/GlobalData'
import ActivitySearch from '../../Components/SearchBar/ActivitySearch'
function ActivityDetail () {
  const navigate = useNavigate()
  const [activityDetail, setActivityDetail] = useState(null)
  const [whatExpect, setWhatExpect] = useState(null)
  const [faqs, setFaqs] = useState(null)
  const [availibalityDays, setAvailibalityDays] = useState(null)
  const [additionalServices, setAdditionalServices] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeIndex1, setActiveIndex1] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectAdults, setSelectAdults] = useState('')
  const [activeButton, setActiveButton] = useState(null)
  const [selectChilds, setSelectChilds] = useState('')
  const [imageArray, setImageArray] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [isModal, setIsModal] = useState(false)
  const DomainURL = CustomerDomainName()
  useEffect(() => {
    GetDetail()
  }, [])

  const GetDetail = async () => {
    var currentURL = window.location.href
    // Extract the last part of the URL
    var urlParts = currentURL.split('/')
    var lastPart = urlParts[urlParts.length - 1]

    var endpoint = ApiEndPoint()
    var token = ActivityToken()
    var data = {
      token: token,
      id: lastPart
    }
    setIsLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/activity-details-react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      setActivityDetail(response.data.data)
      setWhatExpect(JSON.parse(response.data.data.what_expect))
      setFaqs(JSON.parse(response.data.data.faqs_arr))
      setAdditionalServices(
        JSON.parse(response.data.data.addtional_service_arr)
      )
      setImageArray(JSON.parse(response.data.data.gallery_images))
      if (response !== undefined) {
        const start = new Date(response.data.data.start_date) // Example start date
        const end = new Date(response.data.data.end_date) // Example end date
        var resultDates = getDatesBetween(
          start,
          end,
          JSON.parse(response.data.data.Availibilty)
        )
        setAvailibalityDays(resultDates)
      }
      //   console.log(resultDates);
      // dispatch(ActivitiesListing(response.data));
      // navigation('/activities');
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const toggleModal = () => {
    setIsModal(!isModal)
  }
  function getDatesBetween (start, end, daysArray) {
    const enabledDays = []
    const startDateNum = start.getDay()
    let currentDate = new Date(start)

    while (currentDate <= end) {
      const dayNum = currentDate.getDay()

      // Handle Sunday correctly:
      const dayData = dayNum === 0 ? daysArray['7'] : daysArray[dayNum]

      if (dayData && dayData.enable) {
        enabledDays.push({
          date: new Date(currentDate),
          from: dayData.from,
          to: dayData.to
        })
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }
    return enabledDays
  }

  // Custom function to filter dates
  const filterDate = date => {
    // Return true if the date is in the enabledDates array, false otherwise
    return availibalityDays.some(
      enabledDate =>
        date.getDate() === enabledDate.date.getDate() &&
        date.getMonth() === enabledDate.date.getMonth() &&
        date.getFullYear() === enabledDate.date.getFullYear()
    )
  }

  const handleAdult = event => {
    setSelectAdults(event.target.value)
  }
  const handleChild = event => {
    setSelectChilds(event.target.value)
  }

  const SaveData = () => {
    debugger
    if (selectedDate === '') {
      toast.info('Please select Activity date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    if (selectAdults === '' || selectAdults === '0') {
      toast.info('Please select Adult for Activity.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    var newdata = {
      date: selectedDate,
      adults: selectAdults,
      childs: selectChilds,
      adultPrice: activityDetail?.sale_price,
      childrenPrice: activityDetail?.child_sale_price,
      currency: activityDetail?.currency_symbol
    }
    sessionStorage.setItem(
      'AdditionalServices',
      JSON.stringify(selectedServices)
    )
    sessionStorage.setItem('ActivityData', JSON.stringify(newdata))
    sessionStorage.setItem('ActivityDetail', JSON.stringify(activityDetail))

    navigate('/activity_checkout')
  }

  const handleCheckboxChange = (checkboxName, servicetype, serviceprice) => {
    const updatedCheckboxes = [...selectedServices]

    // Check if the checkbox is already in the array
    const existingCheckboxIndex = updatedCheckboxes.findIndex(
      item => item.name === checkboxName
    )

    if (existingCheckboxIndex !== -1) {
      // If it is, remove it
      updatedCheckboxes.splice(existingCheckboxIndex, 1)
    } else {
      // If it is not, add it
      updatedCheckboxes.push({
        name: checkboxName,
        type: servicetype,
        price: serviceprice
      })
    }

    setSelectedServices(updatedCheckboxes)
  }

  const handleAdditionalServicePerson = (event, serviceName) => {
    const newAdultValue = parseInt(event.target.value, 10) // Assuming you get the adult value from the event
    addAdultToService(serviceName, newAdultValue)
  }

  const addAdultToService = (serviceName, newAdultValue) => {
    setSelectedServices(prevServices => {
      return prevServices?.map(service => ({
        ...service,
        ...(service.name === serviceName ? { adult: newAdultValue } : {})
      }))
    })
  }

  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  const show1 = index => {
    const buttons = document.getElementsByClassName('accordion2')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }

  return (
    <>
      <ToastContainer />
      {isLoading && <Loading />}
      <Layout>
        <div className='container-fluid px-4'>
          <div class='uitk-spacing uitk-spacing-padding-block-two uitk-spacing-padding-inline-two'>
            <div
              class='uitk-layout-flex uitk-layout-flex-align-items-center'
              style={{ minHeight: '3rem' }}
            >
              <div class='uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                <button
                  type='button'
                  role='link'
                  class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                >
                  <svg
                    class='uitk-icon uitk-spacing uitk-spacing-margin-inlineend-one uitk-icon-directional uitk-icon-small'
                    aria-hidden='true'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'></path>
                  </svg>
                  See all properties
                </button>
              </div>
              <div data-stid='favorite-save-button'>
                <div>
                  <div data-stid='outlined-save-button'>
                    <div class=''>
                      <button
                        type='button'
                        aria-label='Save property to a trip'
                        class='favorite-button favorite-button-button-with-label'
                      >
                        <svg
                          class='uitk-icon favorite-button-fill favorite-button-fill-pill'
                          aria-hidden='true'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            d='m12 22-8.44-8.69a5.55 5.55 0 0 1 0-7.72C4.53 4.59 5.9 4 7.28 4c1.4 0 2.75.59 3.72 1.59l1 1.02 1-1.02c.97-1 2.32-1.59 3.72-1.59 1.4 0 2.75.59 3.72 1.59a5.55 5.55 0 0 1 0 7.72L12 22Z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg
                          class='uitk-icon favorite-button-border favorite-button-border-pill favorite-button-border-color-highlighed'
                          aria-hidden='true'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            d='m12 22 8.44-8.69a5.55 5.55 0 0 0 0-7.72A5.24 5.24 0 0 0 16.72 4 5.22 5.22 0 0 0 13 5.59L12 6.6l-1-1.02a5.2 5.2 0 0 0-7.44 0 5.55 5.55 0 0 0 0 7.72L12 22Zm0-2.87-7-7.21a3.55 3.55 0 0 1 0-4.94C5.6 6.36 6.44 6 7.28 6c.84 0 1.69.36 2.3.98L12 9.48l2.43-2.5c.6-.62 1.45-.98 2.29-.98.84 0 1.68.36 2.28.98a3.55 3.55 0 0 1 0 4.94l-7 7.2Z'
                            clip-rule='evenodd'
                            opacity='.9'
                          ></path>
                        </svg>
                        <span class='favorite-button-text'>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing bex-homepage-module SimpleContainer px-0 my-2'>
            <div data-testid='one-key-banner-1 px-0' id='one-key-banner-1'>
              <div>
                <div
                  class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0'
                  data-stid='one-key-message-card'
                >
                  <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three'>
                    <div class='uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item'>
                      <div class='uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six'>
                        <img
                          class='uitk-mark uitk-mark-landscape-oriented'
                          alt=''
                          src='https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg'
                          id='onekey__standard__always_light'
                        />
                      </div>
                    </div>
                    <div class='uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                      <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap'>
                        <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1'>
                          <div class='uitk-spacing uitk-spacing-padding-inlineend-two'>
                            <h2 class='uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme'>
                              You'll always get our best prices when you're
                              signed in!
                            </h2>
                          </div>
                        </div>
                        <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item'>
                          <div class='uitk-layout-flex uitk-spacing uitk-layout-flex-item'>
                            <a
                              href='https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F'
                              target='_self'
                              data-stid='one-key-message-card-UIPrimaryButton'
                              class='uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset'
                            >
                              Sign in
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='px-3 py-2'>
            <ActivitySearch />
          </div>
          <div
            class='mt-3 uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme'
            data-stid='uitk-card-content-section-navbar'
            style={{ position: 'sticky' }}
          >
            <div class='uitk-tabs-container'>
              <ul
                class='uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border'
                role='list'
              >
                <li
                  role='listitem'
                  className={
                    activeButton === 'section-1' ? 'current' : 'uitk-tab'
                  }
                >
                  <a
                    href='#section-1'
                    onClick={() => handleButtonClick('section-1')}
                    class='uitk-tab-anchor'
                    aria-current='true'
                    role='link'
                    data-toggle='tab'
                    draggable='false'
                  >
                    <span class='uitk-tab-text'>Overview</span>
                  </a>
                  <li
                    aria-hidden='true'
                    class='uitk-tab-highlighter'
                    style={{
                      transform: 'translateX(0px) scaleY(1) scaleX(1)',
                      width: '94.625px'
                    }}
                  ></li>
                </li>
                <li
                  role='listitem'
                  className={
                    activeButton === 'section-3' ? 'current' : 'uitk-tab'
                  }
                >
                  <a
                    href='#section-3'
                    onClick={() => handleButtonClick('section-3')}
                    className='uitk-tab-anchor'
                    aria-current='false'
                    role='link'
                    data-toggle='tab'
                    draggable='false'
                  >
                    <span class='uitk-tab-text'>Availability</span>
                  </a>
                  <li
                    aria-hidden='true'
                    class='uitk-tab-highlighter'
                    style={{
                      transform: 'translateX(0px) scaleY(1) scaleX(1)',
                      width: '94.625px'
                    }}
                  ></li>
                </li>
                {/* <li role="listitem" className={activeButton === 'section-2' ? 'current' : 'uitk-tab'}>
                  <a href='#section-2' onClick={() => handleButtonClick('section-2')} 
                    className="uitk-tab-anchor" aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">About</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-5' ? 'current' : 'uitk-tab'}>
                  <a href='#section-5'  onClick={() => handleButtonClick('section-5')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Activity</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li> */}
                <li
                  role='listitem'
                  className={
                    activeButton === 'section-6' ? 'current' : 'uitk-tab'
                  }
                >
                  <a
                    href='#section-6'
                    onClick={() => handleButtonClick('section-6')}
                    className='uitk-tab-anchor'
                    aria-current='false'
                    role='link'
                    data-toggle='tab'
                    draggable='false'
                  >
                    <span class='uitk-tab-text'>Included</span>
                  </a>
                  <li
                    aria-hidden='true'
                    class='uitk-tab-highlighter'
                    style={{
                      transform: 'translateX(0px) scaleY(1) scaleX(1)',
                      width: '94.625px'
                    }}
                  ></li>
                </li>
              </ul>
            </div>
            <div class='uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1'>
              <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing'>
                <div class='uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three'>
                  <div data-stid='navbar-quick-reserve-button'>
                    <button
                      data-stid='sticky-button'
                      type='button'
                      class='uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary'
                    >
                      Book now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid mt-2 px-5'>
          <Carousel className='carousel-container'>
            {imageArray?.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  className='d-block w-100'
                  src={DomainURL + '/public/images/activites/' + item}
                  alt='First slide'
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <div className='row mt-3' style={{ color: 'black' }}>
            <div className='col-md-8'>
              <h3>{activityDetail?.title}</h3>
              <div>
                <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                  <FontAwesomeIcon icon={faLocation} />
                </i>{' '}
                {activityDetail?.location}
              </div>
              <p className='mt-2 text-justify'>
                {activityDetail?.activity_content}{' '}
              </p>
              <div className='row mt-2 mb-2 p-2'>
                <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                  <div class='single-tour-feature d-flex align-items-center mb-3'>
                    <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                      <i class='fas fa-check'>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                    </div>
                    <div class='single-feature-titles'>
                      <p style={{ fontSize: '13px' }} class='title fw-bold'>
                        Rating
                      </p>
                      <p
                        className='mt-0 card-star'
                        style={{ fontSize: '12px', color: '#f3ba1a' }}
                      >
                        {activityDetail?.starts_rating === '' ? (
                          <span className='fw-bold'>No Rating</span>
                        ) : (
                          Array(activityDetail?.starts_rating)
                            .fill(0)
                            ?.map((_, index) => (
                              <i key={index} className='fa fa-star'>
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            ))
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                  <div class='single-tour-feature d-flex align-items-center mb-3'>
                    <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                      <i class='fas fa-check'>
                        <FontAwesomeIcon icon={faClock} />
                      </i>
                    </div>
                    <div class='single-feature-titles'>
                      <p style={{ fontSize: '13px' }} class='title fw-bold'>
                        Duration
                      </p>
                      <p
                        className='mt-0'
                        style={{ fontSize: '12px' }}
                        class='title '
                      >
                        {activityDetail?.duration} hours
                      </p>
                    </div>
                  </div>
                </div>
                <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                  <div class='single-tour-feature d-flex align-items-center mb-3'>
                    <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                      <i class='fas fa-check'>
                        <FontAwesomeIcon icon={faCalendarCheck} />
                      </i>
                    </div>
                    <div class='single-feature-titles'>
                      <p style={{ fontSize: '13px' }} class='title fw-bold'>
                        Start Date
                      </p>
                      <p
                        className='mt-0'
                        style={{ fontSize: '12px' }}
                        class='title '
                      >
                        {moment(activityDetail?.start_date).format(
                          'DD-MM-YYYY'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                  <div class='single-tour-feature d-flex align-items-center mb-3'>
                    <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                      <i class='fas fa-check'>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </i>
                    </div>
                    <div class='single-feature-titles'>
                      <p style={{ fontSize: '13px' }} class='title fw-bold'>
                        End Date
                      </p>
                      <p
                        className='mt-0'
                        style={{ fontSize: '12px' }}
                        class='title '
                      >
                        {moment(activityDetail?.end_date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                  <div class='single-tour-feature d-flex align-items-center mb-3'>
                    <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                      <i class='fas fa-check'>
                        <FontAwesomeIcon icon={faMapLocation} />
                      </i>
                    </div>
                    <div class='single-feature-titles'>
                      <p style={{ fontSize: '13px' }} class='title fw-bold'>
                        Location
                      </p>
                      <p
                        className='mt-0'
                        style={{ fontSize: '12px' }}
                        class='title '
                      >
                        {activityDetail?.location}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {activityDetail?.video_link !== null && (
                <div className='text-center mt-3'>
                  <iframe
                    width='100%'
                    height='380'
                    src={activityDetail?.video_link}
                    frameborder='0'
                    allowfullscreen
                  ></iframe>
                </div>
              )}
              {whatExpect !== null && whatExpect?.length !== 0 && (
                <section id='section-2'>
                  <h4 className='mt-3 view-detail-heading'>What to Expect</h4>
                  {whatExpect?.map((item, index) => (
                    <div key={index}>
                      <button
                        className={`accordion  ${
                          activeIndex === index ? 'active' : ''
                        }`}
                        onClick={() => show(index)}
                      >
                        {item.title}
                      </button>
                      <div class='panel'>
                        <p>{item.expect_content}</p>
                      </div>
                    </div>
                  ))}
                </section>
              )}
              <section
                id='section-6'
                data-testid='details-view--staticDetails'
                class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-small uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-column-start '
              >
                <div
                  id='aboutThisActivityContainer'
                  aria-label='about this activity'
                  class='uitk-spacing uitk-spacing-margin-small-blockstart-half uitk-spacing-margin-medium-blockstart-six'
                >
                  <section class='uitk-spacing uitk-spacing-margin-large-blockstart-six'>
                    <div class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-spacing uitk-spacing-padding-large-six uitk-card-has-primary-theme checkout-hotel-detail'>
                      <div class='uitk-spacing' id='inclusionsAndExclusions'>
                        <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid inculded-uitk'>
                          <h4
                            class='uitk-heading uitk-layout-grid-item'
                            tabindex='-1'
                            aria-hidden='false'
                          >
                            What's included, what's not
                          </h4>
                          <div class='uitk-layout-grid-item'>
                            <div class='uitk-spacing uitk-spacing-margin-small-blockstart-unset uitk-spacing-margin-large-blockstart-unset'>
                              <div class='uitk-layout-flex uitk-layout-flex-gap-three'>
                                <div class='uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                                  <ul
                                    class='uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-layout-grid uitk-spacing uitk-spacing-margin-blockstart-two'
                                    role='list'
                                  >
                                    <li
                                      class='uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                      role='listitem'
                                    >
                                      <svg
                                        class='uitk-icon uitk-icon-small'
                                        aria-describedby='0.47279172447270823-description'
                                        aria-label="What's included"
                                        role='img'
                                        viewBox='0 0 24 24'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <title id='0.47279172447270823-title'>
                                          What's included
                                        </title>
                                        <desc id='0.47279172447270823-description'>
                                          What's included
                                        </desc>
                                        <path d='M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'></path>
                                      </svg>{' '}
                                      {activityDetail?.whats_included}
                                    </li>
                                    <li
                                      class='uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                      role='listitem'
                                    >
                                      <svg
                                        class='uitk-icon uitk-icon-small'
                                        aria-describedby='0.01079526080188975-description'
                                        aria-label="What's included"
                                        role='img'
                                        viewBox='0 0 24 24'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <title id='0.01079526080188975-title'>
                                          What's included
                                        </title>
                                        <desc id='0.01079526080188975-description'>
                                          What's included
                                        </desc>
                                        <path d='M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'></path>
                                      </svg>{' '}
                                      {activityDetail?.whats_excluded}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class='uitk-spacing uitk-spacing-margin-blockstart-six'
                        id='knowBeforeYouBook'
                      >
                        <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid inculded-uitk'>
                          <h4
                            class='uitk-heading uitk-layout-grid-item'
                            tabindex='-1'
                            aria-hidden='false'
                          >
                            Know before you book
                          </h4>
                          <div class='uitk-layout-grid-item'>
                            <div class='uitk-spacing uitk-spacing-margin-small-blockstart-unset uitk-spacing-margin-large-blockstart-unset'>
                              <div class='uitk-layout-flex uitk-layout-flex-gap-three'>
                                <div class='uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                                  <ul
                                    class='uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-layout-grid uitk-spacing uitk-spacing-margin-blockstart-two'
                                    role='list'
                                  >
                                    <li
                                      class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                      role='listitem'
                                    >
                                      <span
                                        aria-hidden='true'
                                        class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                      ></span>{' '}
                                      <span class='uitk-typelist-item-child pl-2'>
                                        {' '}
                                        Wheelchair accessible
                                      </span>
                                    </li>
                                    <li
                                      class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                      role='listitem'
                                    >
                                      <span
                                        aria-hidden='true'
                                        class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                      ></span>
                                      {'  '}
                                      <span class='uitk-typelist-item-child'>
                                        {' '}
                                        Infants and small children can ride in a
                                        pram or stroller
                                      </span>
                                    </li>
                                    <li
                                      class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                      role='listitem'
                                    >
                                      <span
                                        aria-hidden='true'
                                        class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                      ></span>
                                      <span class='uitk-typelist-item-child'>
                                        {'  '} Public transportation options are
                                        available nearby
                                      </span>
                                    </li>
                                    <li
                                      class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                      role='listitem'
                                    >
                                      <span
                                        aria-hidden='true'
                                        class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                      ></span>
                                      <span class='uitk-typelist-item-child'>
                                        {'  '} Suitable for all physical fitness
                                        levels
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                        <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid inculded-uitk'>
                          <h4
                            class='uitk-heading uitk-layout-grid-item'
                            aria-hidden='false'
                          >
                            Activity itinerary
                          </h4>
                          <section class='uitk-expando-list uitk-layout-grid-item'>
                            <div>
                              <div class='uitk-expando-peek'>
                                <div class='uitk-expando-peek-main uitk-expando-peek-main-inactive'>
                                  <div
                                    class='uitk-expando-peek-inner display-items'
                                    data-items-to-display='1'
                                  >
                                    <div class='uitk-spacing'>
                                      <div class='uitk-layout-flex uitk-layout-flex-gap-three'>
                                        <svg
                                          class='uitk-icon uitk-layout-flex-item uitk-icon-small'
                                          aria-hidden='true'
                                          viewBox='0 0 24 24'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <path
                                            fill-rule='evenodd'
                                            d='M5 9a7 7 0 1 1 14 0c0 5.25-7 13-7 13S5 14.25 5 9zm4.5 0a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0z'
                                            clip-rule='evenodd'
                                          ></path>
                                        </svg>
                                        <div class='uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                                          <h6
                                            class='uitk-heading uitk-heading-7'
                                            aria-hidden='false'
                                          >
                                            {' '}
                                            {activityDetail?.location}
                                          </h6>
                                          <ul
                                            class='uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-layout-grid uitk-spacing uitk-spacing-margin-blockstart-two'
                                            role='list'
                                          >
                                            <li
                                              class='uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2'
                                              role='listitem'
                                            >
                                              <svg
                                                class='uitk-icon uitk-icon-small'
                                                aria-hidden='true'
                                                viewBox='0 0 24 24'
                                                xmlns='http://www.w3.org/2000/svg'
                                              >
                                                <path
                                                  fill-rule='evenodd'
                                                  d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z'
                                                  clip-rule='evenodd'
                                                ></path>
                                              </svg>{' '}
                                              {'  '} {activityDetail?.duration}h
                                            </li>
                                          </ul>
                                          <div class='uitk-text uitk-type-300 uitk-text-white-space-pre-line uitk-text-default-theme uitk-spacing uitk-spacing-margin-blockstart-two'>
                                            Pakistan Travel Tourism proudly
                                            offers the Sightseeing Lahore tour
                                            to see spectacular Lahore through
                                            open roof double decker Bus. Once
                                            inside the bus you will be enchanted
                                            by the city fascinating colours,
                                            busling bazars, rich history &amp;
                                            culture, modern architecture and
                                            bastion of the Mughal spirit. But
                                            don’t take our words for it, join us
                                            and experience the world class
                                            services for yourself.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                      {/* <div class="uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-margin-blockstart-six uitk-card-has-primary-theme">
                        <section class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid" id="location">
                          <h4 class="uitk-heading uitk-heading-4 uitk-layout-grid-item" tabindex="-1" aria-hidden="false">Location</h4>
                          <div class="uitk-layout-grid-item">
                            <div class="uitk-layout-flex">
                              <button type="button" aria-labelledby="View in a map" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width uitk-card-has-primary-theme">
                                  <figure class="uitk-image uitk-image-ratio-21-9 uitk-image-ratio">
                                    <div class="uitk-image-placeholder">
                                      <img alt="Activity and redemption location map" class="uitk-image-media" src="https://maps.googleapis.com/maps/api/staticmap?channel=LocalExpert&amp;size=760x325&amp;maptype=roadmap&amp;sensor=false&amp;format=png8&amp;scale=2&amp;markers=icon:https://a.travel-assets.com/travel-assets-manager/eg-maps/focal-activity.png%7C31.53944,74.30348&amp;markers=icon:https://a.travel-assets.com/travel-assets-manager/eg-maps/people.png%7C31.5120672,74.3288284&amp;key=AIzaSyDh-Ugy9lVot1JYCv5wtzqsPxsGMhsLN4k&amp;signature=Raxpwj6AEhxotsfksnh-U-G-4qc=" />
                                    </div>
                                  </figure>
                                  <div class="uitk-card-content-section uitk-card-content-section-padded">
                                    <div class="uitk-layout-flex uitk-layout-flex-justify-content-center">View in a map</div>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <section class="uitk-spacing uitk-spacing-margin-blockstart-three" data-stid="activity-card-section">
                              <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-small uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                <div class="uitk-layout-grid-item">
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-one">
                                    <div class="uitk-layout-flex uitk-layout-flex-gap-three">
                                      <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                        <h4 class="uitk-heading uitk-heading-6" aria-hidden="false">Activity location</h4>
                                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-layout-grid uitk-spacing uitk-spacing-margin-blockstart-two" role="list">
                                          <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2" role="listitem">
                                            <svg class="uitk-icon uitk-icon-small" aria-describedby="0.9866370847944936-description" aria-label="LOB_ACTIVITIES" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                                              <title id="0.9866370847944936-title">LOB_ACTIVITIES</title>
                                              <desc id="0.9866370847944936-description">LOB_ACTIVITIES</desc>
                                              <path d="M22 10.18V6H2v4.18c.9 0 1.66.75 1.66 1.66 0 .9-.76 1.66-1.66 1.66v4.18h20V13.5c-.9 0-1.66-.76-1.66-1.66 0-.9.76-1.66 1.66-1.66zm-4.16 4.57c0 .23-.2.43-.43.43H6.59a.43.43 0 0 1-.43-.43V8.93c0-.23.2-.43.43-.43h10.82c.23 0 .43.2.43.43v5.82zm-10-4.57h8.32v3.32H7.84v-3.32z"></path>
                                            </svg>Lahore
                                            <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2" role="listitem">Lahore, Pakistan, Pakistan</li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="uitk-layout-grid-item">
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-one">
                                    <div class="uitk-layout-flex uitk-layout-flex-gap-three">
                                      <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                        <h4 class="uitk-heading uitk-heading-6" aria-hidden="false">Meeting/Redemption Point</h4>
                                        <div class="uitk-expando-peek uitk-spacing uitk-spacing-margin-blockstart-two">
                                          <div class="uitk-expando-peek-main">
                                            <div class="uitk-expando-peek-inner display-items" data-items-to-display="4">
                                              <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2" role="listitem">
                                                  <svg class="uitk-icon uitk-icon-small" aria-describedby="0.259324370871318-description" aria-label="PEOPLE" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <title id="0.259324370871318-title">PEOPLE</title>
                                                    <desc id="0.259324370871318-description">PEOPLE</desc>
                                                    <path fill-rule="evenodd" d="M10.99 8A3 3 0 1 1 5 8a3 3 0 0 1 6 0zm8 0A3 3 0 1 1 13 8a3 3 0 0 1 6 0zM8 13c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm7.03.05c.35-.03.68-.05.97-.05 2.33 0 7 1.17 7 3.5V19h-6v-2.5c0-1.48-.81-2.61-1.97-3.45z" clip-rule="evenodd"></path>
                                                  </svg>Qazafi Stadium
                                                  <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2" role="listitem">Ferozepur Road</li>
                                                    <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2" role="listitem">Lahore, Punjab, Pakistan</li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </section>
                      </div> */}
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <div className='col-md-4'>
              <div className='checkout-hotel-detail p-3'>
              <div id='section-3'>
                <div class='uitk-layout-grid uitk-layout-grid-align-items-center uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-space uitk-layout-grid-display-grid'>
                  <h4
                    class='uitk-heading uitk-heading-4 uitk-layout-grid-item'
                    tabindex='-1'
                  >
                    Check availability
                  </h4>
                </div>
                <hr class='uitk-spacing uitk-spacing-margin-block-four uitk-spacing-border-blockstart uitk-spacing-hr'></hr>
                <div class='uitk-spacing uitk-spacing-margin-small-blockstart-six uitk-spacing-margin-medium-blockstart-unset'>
                  <div class='uitk-date-range-container'>
                    <ul role='tablist' class='d-flex flex-wrap'>
                      {availibalityDays?.map((item, index) => (
                        <li
                          key={index}
                          class='uitk-date-range-button-wrapper-natural'
                          role='presentation'
                        >
                          <button
                            type='button'
                            aria-selected='true'
                            class='uitk-date-range-button checkout-hotel-detail'
                            style={{ height: '100px' }}
                            role='tab'
                          >
                            <span class='uitk-date-range-button-text'>
                              {moment(item.date).format('dddd')} -{' '}
                              {moment(item.date).format('DD-MM-YYYY')}
                            </span>
                            <span class='uitk-date-range-button-date-text'>
                              Open: {item.from}
                            </span>
                            <span class='uitk-date-range-button-date-text'>
                              Close: {item.to}
                            </span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
                <div>
                  <h4>Select Activity</h4>
                </div>
                <div class='d-flex justify-content-between mt-2'>
                  <div>
                    <h6 class='card-title'>Start Date:</h6>
                  </div>
                  <div>
                    {' '}
                    {moment(activityDetail?.start_date).format('DD-MM-YYYY')}
                  </div>
                </div>
                <div class='d-flex justify-content-between mt-2'>
                  <div>
                    <h6 class='card-title'>End Date:</h6>
                  </div>
                  <div>
                    {' '}
                    {moment(activityDetail?.end_date).format('DD-MM-YYYY')}
                  </div>
                </div>
                <div class='mt-2'>
                  <div>
                    <h6 class='card-title'>Travel Date:</h6>
                    <div className='field-icon-wrap mt-2'>
                      <span className='form-control-feedback'>
                        <FontAwesomeIcon icon={faCalendar} />{' '}
                      </span>
                      <DatePicker
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        placeholderText='Select Date'
                        filterDate={filterDate}
                        minDate={new Date()}
                        dateFormat='dd/MM/yyyy' // Customize date format as needed
                        className='form-control text-start select-styling ps-5'
                      />
                    </div>
                  </div>
                </div>
                <div class='d-flex justify-content-between mt-2'>
                  <div>
                    <h6 class='card-title'>Adult Price:</h6>
                  </div>
                  <div>
                    {' '}
                    {activityDetail?.currency_symbol}{' '}
                    {activityDetail?.sale_price}
                  </div>
                </div>
                {activityDetail?.child_sale_price !== null && (
                  <div class='d-flex justify-content-between mt-2'>
                    <div>
                      <h6 class='card-title'>Child Price:</h6>
                    </div>
                    <div>
                      {' '}
                      {activityDetail?.currency_symbol}{' '}
                      {activityDetail?.child_sale_price}
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-md-6'>
                    <div class='form-group mt-2'>
                      <label for='exampleFormControlSelect1'>
                        Select Adults
                      </label>
                      <select
                        onChange={handleAdult}
                        value={selectAdults}
                        className='form-control form-select select-styling'
                        id='exampleFormControlSelect1'
                      >
                        <option selected value='0'>
                          0
                        </option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class='form-group mt-2'>
                      <label for='exampleFormControlSelect1'>
                        Select Child
                      </label>
                      <select
                        value={selectChilds}
                        onChange={handleChild}
                        disabled={activityDetail?.child_sale_price == null}
                        className='form-control form-select select-styling'
                        id='exampleFormControlSelect1'
                      >
                        <option selected value='0'>
                          0
                        </option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                      </select>
                    </div>
                  </div>
                </div>
                {additionalServices !== null &&
                  additionalServices?.length !== 0 && (
                    <div className='mt-2'>
                      <h4>Additional Services</h4>
                    </div>
                  )}
                {additionalServices?.map((item, index) => (
                  <div key={index}>
                    <div class='form-group' data-toggle='buttons'>
                      <label class='btn btn-default w-100 fw-bold mt-2  umrah-package-modal-checkbox2 select-room--checkbox info'>
                        <i class='fa fa-fw'></i>
                        <input
                          id='0'
                          autocomplete='off'
                          checked={
                            selectedServices.length > 0 &&
                            selectedServices.some(
                              data => data.name === item.service_name
                            )
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              item.service_name,
                              item.service_type,
                              item.service_price
                            )
                          }
                          class='room-check'
                          type='checkbox'
                        />
                        {item.service_name}
                      </label>
                    </div>

                    <div class='d-flex justify-content-between mt-2'>
                      <div>
                        <h6 class='card-title'>Price:</h6>
                      </div>
                      <div>
                        {' '}
                        {activityDetail?.currency_symbol} {item.service_price}
                      </div>
                    </div>
                    <div class='d-flex justify-content-between mt-2'>
                      <div>
                        <h6 class='card-title'>Service Type:</h6>
                      </div>
                      <div> {item.service_type}</div>
                    </div>
                    {item.service_type === 'Per Person' && (
                      <div class='form-group mt-2'>
                        <h6 class='card-title'>Select Person:</h6>
                        <select
                          value={
                            selectedServices.length > 0
                              ? selectedServices.find(
                                  data => data.name === item.service_name
                                )?.adult || '0'
                              : '0'
                          }
                          onChange={event =>
                            handleAdditionalServicePerson(
                              event,
                              item.service_name
                            )
                          }
                          disabled={
                            !(
                              selectedServices.length > 0 &&
                              selectedServices.some(
                                data => data.name === item.service_name
                              )
                            )
                          }
                          className='form-control form-select select-styling'
                          id='exampleFormControlSelect1'
                        >
                          <option selected value='0'>
                            0
                          </option>
                          {Array.from(
                            { length: selectAdults },
                            (_, index) => index + 1
                          )?.map(optionValue => (
                            <option key={optionValue} value={optionValue}>
                              {optionValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                ))}

                <div className='mt-2'>
                  <a onClick={toggleModal} className='activity_booking-Box'>
                    <FontAwesomeIcon icon={faCalendarTimes} /> Cancellation
                    Policy ?
                  </a>
                  <a
                    onClick={SaveData}
                    class='btn btn-primary select-styling search-btn1 form-control mt-2'
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            
          </div>
          {faqs !== null && faqs?.length !== 0 && (
            <div className=''>
              <h4 className='mt-5 flight-heading '>
                Frequently Asked Questions
              </h4>
              {faqs?.map((item, index) => (
                <div key={index}>
                  <button
                    className={`accordion2  ${
                      activeIndex1 === index ? 'active' : ''
                    }`}
                    onClick={() => show1(index)}
                  >
                    {item.title}
                  </button>
                  <div class='panel'>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Modal isOpen={isModal} className='t-0' toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Cancellation Policy</ModalHeader>
          <ModalBody>
            <div className='form-group'>
              <p>{activityDetail?.cancellation_policy}</p>
            </div>
          </ModalBody>
        </Modal>
      </Layout>
    </>
  )
}

export default ActivityDetail
