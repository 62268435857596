import React from "react";
import Navbar2 from "../Navbar/Navbar2";
import Footer2 from "../Footer/Footer2";

function WithoutLayout({ children }){
    return(
        <>
           <Navbar2 />
            {children}
            <Footer2 />
        </>
    )
}

export default WithoutLayout;