import React, { useState, useEffect } from 'react'
import FlightDetail from './FlightCard'
import Faqs from '../../Components/FAQs/FAQs'
import Layout from '../../Components/Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faPhone,
  faMapMarker,
  faPersonCirclePlus,
  faPersonCircleMinus,
  faCalendar,
  faMoon,
  faClipboardCheck,
  faBus,
  faBuildingCircleCheck,
  faPlaneDeparture,
  faPlaneArrival,
  faBed,
  faClock,
  faDollar,
  faHeadphones,
  faStar,
  faLock,
  faMailBulk,
  faEnvelope,
  faCalendarCheck,
  faCalendarMinus
} from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import kaba from '../../Images/Packages/kabapic.jpg';
import busimage from '../../Images/Packages/Logo/bus.jpg' 
import { CustomerDomainName } from '../../Components/GlobalData/GlobalData'
import moment from 'moment'
import Images from '../../Components/Images/images'
import iternary from '../../Images/Packages/iternary.webp'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
function ViewDeail () {
  var url = CustomerDomainName();
  const tourDetail = useSelector(state => state.hotels.viewtourdetail);
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const tourlocation = JSON.parse(tourDetail.tours.tour_location);
  const transportationdetail = JSON.parse(
    tourDetail.tours.transportation_details
  )
  const Itinerarydetail = JSON.parse(tourDetail.tours.Itinerary_details)
  var Itinerarydetailmore=[]
  if(tourDetail.tours.tour_itinerary_details_1 !== null){
  Itinerarydetailmore = JSON.parse(
    tourDetail.tours.tour_itinerary_details_1
  )
}
  const accomodationdetail = JSON.parse(tourDetail.tours.accomodation_details)
  const accomodationdetailmore = JSON.parse(
    tourDetail.tours.accomodation_details_more
  )
  const transportationdetailmore = tourDetail.tours.transportation_details_more
  ? JSON.parse(tourDetail.tours.transportation_details_more)
  : [];
  const images = JSON.parse(tourDetail.tours.gallery_images)
  const [opencartmodel, setOpencartmodel] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)
  const [minValue, setMinValue] = useState({price:0,currency:''})
  const [modalAdultRoom, setModalAdultRoom] = useState({double:'',triple:'',quad:''});
  const [modalAdultAdult, setModalAdultAdult] = useState({double:'',triple:'',quad:''});
  const [modalAdultTotal, setModalAdultTotal] = useState({double:0,triple:0,quad:0});

  const [activeButton, setActiveButton] = useState(null)

  // const handleAdultRoomChange = (event, price) => {
  //   var { name, value } = event.target;

  //   if(name==='double_rooms'){
  //     setModalAdultRoom((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //      double: value
  //     }));
  //     var adult=value*2;
  //     setModalAdultAdult((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: adult
  //     }));

  //     var p=adult*price;
  //     setModalAdultTotal((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: p
  //     }));

  //   }
  // };
  // const handleAdultAdultChange = (event, price) => {
  //   var { name, value } = event.target;

  //   if(name==='double_adult'){
  //     setModalAdultAdult((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: value
  //     }));

  //     var p=value*price;
  //     setModalAdultTotal((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: p
  //     }));

  //   }
  // };
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  useEffect(() => {
    calcalutemin();
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  const toggleModal2=()=>{
    setCancelModal(!cancelModal)
  }
const calcalutemin=()=>{
  var min_value = Math.min(tourDetail.tours.double_grand_total_amount, tourDetail.tours.triple_grand_total_amount, tourDetail.tours.quad_grand_total_amount);
  if (minValue.price !== min_value) {
   setMinValue({price:min_value,currency:tourDetail.tours.currency_symbol});
 }

};
  return (
    <>
      <Layout>
          {/* <div className="row mt-2 p-3 hotel-top  modify-flight-search"> <PackageSearch/></div> */}
        <div className='container-fluid px-4'>
          <div class="px-3 pb-0 uitk-spacing uitk-spacing-padding-small-block-three uitk-spacing-padding-medium-blockstart-one uitk-spacing-padding-large-blockstart-three">
            <div class="uitk-layout-grid uitk-layout-grid-align-content-center uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid" data-test-id="uitk-progress-indicator">
              <div class="uitk-layout-grid-item uitk-progress-indicator-container uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-extra_large">
                <ul class="uitk-progress-indicator">
                  <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 1 of 4. Choose your stay. Current page, Choose your stay" style={{fontWeight:'900'}}>
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="true" style={{fontWeight:'900'}}>
                      <span style={{fontWeight:'900'}}><b>Choose your stay</b></span>
                    </div>
                    <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                    </svg>
                  </li>
                  <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 2 of 4. Choose departing flight., Choose departing flight">
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="false">
                      <span><span class="">Choose departing flight</span></span>
                    </div>
                    <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                    </svg>
                  </li>
                  {/* <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 4 of 4. Review your trip, Review your trip">
                    <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="false">
                      <span><span class="">Review</span></span>
                    </div>
                  </li> */}
                </ul>
                {/* <div class="uitk-progress-indicator-fade-start">
                  <div class="uitk-scrim fade-leading secondary-background"></div>
                </div>
                <div class="uitk-progress-indicator-fade-end">
                  <div class="uitk-scrim fade-trailing secondary-background"></div>
                </div> */}
              </div>
              {/* <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-layout-grid-justify-items-end uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-extra_large"></div> */}
            </div>
          </div>
          <div class="pt-0 uitk-spacing uitk-spacing-padding-block-two uitk-spacing-padding-inline-two">
            <div class="uitk-layout-flex uitk-layout-flex-align-items-center" style={{minHeight:'3rem'}}>
              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                <button type="button" role="link" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-one uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>See all properties
                </button>
              </div>
              <div data-stid="favorite-save-button"><div></div></div>
            </div>
          </div>
          <Images data={images} setting={1} />
          <div class="uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme" data-stid="uitk-card-content-section-navbar" style={{position:'sticky'}}>
            <div class="uitk-tabs-container">
              <ul class="uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border" role="list">
                <li role="listitem" className={activeButton === 'section-1' ? 'current' : 'uitk-tab'}>
                  <a href='#section-1' onClick={() => handleButtonClick('section-1')} class="uitk-tab-anchor"
                      aria-current="true" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Overview</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-2' ? 'current' : 'uitk-tab'}>
                  <a href='#section-2' onClick={() => handleButtonClick('section-2')} 
                    className="uitk-tab-anchor" aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> Schedule</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-3' ? 'current' : 'uitk-tab'}>
                  <a href='#section-3' onClick={() => handleButtonClick('section-3')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Flights</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-4' ? 'current' : 'uitk-tab'}>
                  <a href='#section-4' onClick={() => handleButtonClick('section-4')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> Accomodation</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-5' ? 'current' : 'uitk-tab'}>
                  <a href='#section-5'  onClick={() => handleButtonClick('section-5')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text">Transportion</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-6' ? 'current' : 'uitk-tab'}>
                  <a href='#section-6'  onClick={() => handleButtonClick('section-6')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> Visa</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
                <li role="listitem" className={activeButton === 'section-7' ? 'current' : 'uitk-tab'}>
                  <a href='#section-7'  onClick={() => handleButtonClick('section-7')} className="uitk-tab-anchor"
                      aria-current="false" role="link" data-toggle="tab" draggable="false">
                    <span class="uitk-tab-text"> FAQ</span>
                  </a>
                  <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                </li>
              </ul>
            </div>
            <div class="uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1"> 
              <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing">
                <div class="uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three">
                  <div data-stid="navbar-quick-reserve-button">
                    <button data-stid="sticky-button" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary">Price Start From : {minValue.currency} {minValue.price}</button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div className='container-fluid px-5'>
          <div className='row'>
            <div class='col-sm-12 col-md-9'>
              <section id='section-1' className='p-3 hotel-checkout-shadow rounded-4 mt-2' style={{color:'black'}}>
                <div class='row'>
                  <div class='col-md-12'>
                    <div class='product-title'>
                      <h2 className='view-detail-title'>{tourDetail.tours.title}</h2>
                    </div>
                    <div class='product-address'>
                      <span>
                        {' '}
                        <i class='fa-solid tc fa-location-dot'>
                          <FontAwesomeIcon icon={faMapMarker} />
                        </i>
                        &nbsp;
                        {tourlocation.map((location, index) => (
                          <span key={index}>
                            {location}
                            {index !== tourlocation.length - 1 ? ' ' : ''}
                          </span>
                        ))}
                        &nbsp;
                        <i class='fa-solid tc fa-phone'>
                          <FontAwesomeIcon icon={faPhone} />
                        </i>
                        &nbsp; 0121 777 2522
                      </span>
                    </div>
                    <p class='mt-2 mb-2'>{tourDetail.tours.content}</p>
                  </div>
                  <div class='col-md-6'>
                    <h6>
                      {' '}
                      <i class='fa-solid fa-person-circle-plus'>
                        {' '}
                        <FontAwesomeIcon icon={faPersonCirclePlus} />
                      </i>{' '}
                      Whats Included?
                    </h6>
                    <p></p>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.tours.whats_included
                        }}
                      ></span>
                      <br />
                    </p>
                    <p></p>
                  </div>
                  <div class='col-md-6'>
                    <h6>
                      <i class='fa-solid fa-person-circle-minus'>
                        <FontAwesomeIcon icon={faPersonCircleMinus} />
                      </i>{' '}
                      Whats Excluded?
                    </h6>
                    <p></p>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.tours.whats_excluded
                        }}
                      ></span>
                      <br />
                    </p>
                    <p></p>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6> Departure Date</h6>
                      <p>
                        <i class='fa tc fa-calendar' aria-hidden='true'>
                          <FontAwesomeIcon icon={faCalendar} />
                        </i>{' '}
                        {moment(tourDetail.tours.start_date).format(
                          'DD-MM-YYYY'
                        )}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6> Return Date</h6>
                      <p>
                        <i class='fa tc fa-calendar' aria-hidden='true'>
                          <FontAwesomeIcon icon={faCalendar} />
                        </i>{' '}
                        {moment(tourDetail.tours.end_date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Time length</h6>
                      <p>
                        <i class='fa-regular tc fa-moon'>
                          {' '}
                          <FontAwesomeIcon icon={faMoon} />
                        </i>{' '}
                        {tourDetail.tours.time_duration} Nights
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Category</h6>
                      <p>
                        <i class='fa-solid tc fa-clipboard-check'>
                          <FontAwesomeIcon icon={faClipboardCheck} />
                        </i>{' '}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Transport included</h6>
                      <p>
                        <i class='fa fa-bus tc' aria-hidden='true'>
                          <FontAwesomeIcon icon={faBus} />
                        </i>
                        {transportationdetail[0].transportation_vehicle_type}
                      </p>
                    </div>
                  </div>
                  <div class='col-6 col-md-4 col-sm-6 '>
                    <div class='item'>
                      <h6>Destinations</h6>
                      <p title='Makkah, Madina'>
                        <i class='fa-solid tc fa-building-circle-check'>
                          {' '}
                          <FontAwesomeIcon icon={faBuildingCircleCheck} />
                        </i>
                        {tourlocation.map((location, index) => (
                          <span key={index}>
                            {location},
                            {index !== tourlocation.length - 1 ? ' ' : ''}
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div class='col-12 col-md-12 col-sm-12 gap-2 d-flex mt-4'>
                    <button onClick={toggleModal2} className='btn select-styling detail-view-btn search-btn1'>
                      Cancellation Policy ?
                    </button>
                    <button className='btn select-styling detail-view-btn search-btn1'>
                    Send Email Inquiry
                    </button>
                  </div>
                </div>
                <table class='ticket-price mt-5'>
                  <thead>
                    <tr className='text-center ticket-size'>
                      <th class='adult p-2'>
                        <span>Double Price</span>
                      </th>
                      <th class='adult tr-border'>
                        <span>Triple Price</span>
                      </th>

                      <th class='kid'>
                        <span>Quad Price</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr className='text-center tc table-border'>
                      <td class='adult'>
                        <span class='amount double fw-bold'>
                          {tourDetail.tours.currency_symbol}{' '}
                          {tourDetail.tours.double_grand_total_amount}
                        </span>
                      </td>
                      <td class='adult tr-border'>
                        <span class='amount fw-bold'>
                        {tourDetail.tours.currency_symbol}{' '}
                          {tourDetail.tours.triple_grand_total_amount}
                        </span>
                      </td>
                      <td class='kid'>
                        <span class='amount fw-bold'>
                        {tourDetail.tours.currency_symbol}{' '}
                          {tourDetail.tours.quad_grand_total_amount}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 my-4">
                <div data-testid="one-key-banner-1" id="one-key-banner-1" className='px-0'>
                    <div>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0" data-stid="one-key-message-card">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                                <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                    <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                        <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                    </div>
                                </div>
                                <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                            <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">You'll always get our best prices when you're signed in!</h2>
                                            </div>
                                        </div>
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                            <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                              <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <section id='section-2' className='hotel-checkout-shadow rounded-4 mb-3 p-3'>
                <h4 className='view-detail-heading '>Itinerary Schedule </h4>
                <div className='row mt-5'>
                  <div>
                <button
                        className={`accordion  ${activeIndex === 0 ? 'active' : ''}`}
                        onClick={() => show(0)}
                      >
                        {Itinerarydetail[0].Itinerary_title}:{' '}
                        {Itinerarydetail[0].Itinerary_content}
                      </button>
                      <div className='panel'>
                        <p>{Itinerarydetail[0].Itinerary_city}</p>
                      </div>
                      {Itinerarydetailmore.map((item, index) => (
                        <div key={index}>
                          <button
                            className={`accordion  ${
                              activeIndex === index + 1 ? 'active' : ''
                            }`}
                            onClick={() => show(index + 1)}
                          >
                            {item.more_Itinerary_title} : {item.more_Itinerary_city}
                          </button>
                          <div class='panel'>
                            <p>{item.more_Itinerary_content}</p>
                          </div>
                        </div>
                      ))}
                      </div>
                </div>
              </section>
              <section id='section-3'>
                <h4 className='view-detail-heading mb-2'>Flight Details</h4>
                <FlightDetail />
              </section>
              <section id='section-4' className='hotel-detail-padding hotel-checkout-shadow rounded-4 p-3 my-3'>
                {accomodationdetail.map((item, index) => {
                  const hotels = accomodationdetailmore.filter(
                    x => x.more_hotel_city.toUpperCase() === item.hotel_city_name.toUpperCase()
                  )
                  return (
                    <div key={index} class='hotel-details-top'>
                      <h4 className='view-detail-heading mt-1 pt-1'>
                        {item.hotel_city_name} Hotel Details
                      </h4>
                      <div class='row mt-4'>
                        <div class='col-sm-12 col-md-3'>
                          <img
                            src={
                              url +
                              '/public/uploads/package_imgs/' +
                              item.accomodation_image[0]
                            }
                            alt=''
                          />
                        </div>
                        <div class='col-sm-12 col-md-5'>
                          <h5>{item.acc_hotel_name}</h5>
                          <ul>
                            <li className='mt-1'>
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faBed} />
                              </i>{' '}
                              Room Type: {item.acc_type}
                              {hotels.map((a, index) => (
                                <span key={index}>/{a.more_acc_type} </span>
                              ))}
                              <span></span>
                            </li>
                            <li className='mt-1'>
                              {' '}
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faCalendarCheck} />
                              </i>{' '}
                              Check In : {moment(item.acc_check_in).format('DD-MM-YYYY')}
                            </li>
                            <li className='mt-1'>
                              {' '}
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faCalendarMinus} />
                              </i>{' '}
                              Check Out : {moment(item.acc_check_out).format('DD-MM-YYYY')}
                            </li>
                            <li className='mt-1'>
                              {' '}
                              <i class='fa-solid fa-bed'>
                                <FontAwesomeIcon icon={faMoon} />
                              </i>{' '}
                              No Of Nights : {item.acc_no_of_nightst}
                            </li>
                          </ul>
                        </div>
                        <div class='col-sm-12 col-md-4'>
                          <h5>Room Amenities </h5>
                          <p>{item.hotel_whats_included}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </section>
              <section id='section-5' className='hotel-checkout-shadow rounded-4 p-3'>
                <h4 className='view-detail-heading '>Transfer Details</h4>
                <div className='row mt-5'>
                  <div className='col-sm-6 col-md-3 text-center'>
                    <div class='image-thumb'>
                      <img
                        src={busimage}
                        alt=''
                        height='100'
                      />
                    </div>
                    <div class='tr-vehicle-name'>
                      <span>
                        Vehicle:{' '}
                        {transportationdetail[0].transportation_vehicle_type}
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 text-center item-from'>
                    <div class=''>
                      <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                        Pickup Location
                      </h3>
                      <h6 style={{ fontSize: '1rem' }}>
                        {
                          transportationdetail[0]
                            .transportation_pick_up_location
                        }
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Pickup Date :{' '}
                        {moment(
                          transportationdetail[0].transportation_pick_up_date
                        ).format('DD-MM-YYYY')}{' '}
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Pickup Date :{' '}
                        {moment(
                          transportationdetail[0].transportation_pick_up_date
                        ).format('LT')}{' '}
                      </h6>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 item-from'>
                    <div class='item-time'>
                      <i class='fa fa-clock-o'>
                        <FontAwesomeIcon icon={faClock} />
                      </i>
                      <span>
                        {transportationdetail[0].transportation_total_Time}
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 text-center item-from'>
                    <div class=''>
                      <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                        Drop off Location
                      </h3>
                      <h6 style={{ fontSize: '1rem' }}>
                        {
                          transportationdetail[0]
                            .transportation_drop_off_location
                        }
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Drop off Date :{' '}
                        {moment(
                          transportationdetail[0].transportation_drop_of_date
                        ).format('DD-MM-YYYY')}{' '}
                      </h6>
                      <h6 style={{ fontSize: '.8rem' }}>
                        Drop off Time :{' '}
                        {moment(
                          transportationdetail[0].transportation_drop_of_date
                        ).format('LT')}{' '}
                      </h6>
                    </div>
                  </div>
                </div>
                {transportationdetailmore.map((item, index) => (
                  <div
                    key={index}
                    className='row  mt-4'
                    style={{ borderTop: '2px solid #8080804d' }}
                  >
                    <div className='col-sm-6 col-md-3 text-center'>
                      <div class='image-thumb'>
                        <img
                          src='https://demo2.synchronousdigital.com/public/admin_package/frontend/images/bus.jpg'
                          alt=''
                          height='100'
                        />
                      </div>
                      <div class='tr-vehicle-name'>
                        <span>
                          Vehicle:{' '}
                          {transportationdetail[0].transportation_vehicle_type}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 text-center item-from'>
                      <div class=''>
                        <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                          Pickup Location
                        </h3>
                        <h6 style={{ fontSize: '1rem' }}>
                          {item.more_transportation_pick_up_location}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Pickup Date :{' '}
                          {moment(item.more_transportation_pick_up_date).format(
                            'DD-MM-YYYY'
                          )}{' '}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Pickup Date :{' '}
                          {moment(item.more_transportation_pick_up_date).format(
                            'LT'
                          )}{' '}
                        </h6>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 item-from'>
                      <div class='item-time'>
                        <i class='fa fa-clock-o'>
                          <FontAwesomeIcon icon={faClock} />
                        </i>
                        <span>{item.more_transportation_total_Time}</span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 text-center item-from'>
                      <div class=''>
                        <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                          Drop off Location
                        </h3>
                        <h6 style={{ fontSize: '1rem' }}>
                          {item.more_transportation_drop_off_location}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Drop off Date :{' '}
                          {moment(item.more_transportation_drop_of_date).format(
                            'DD-MM-YYYY'
                          )}{' '}
                        </h6>
                        <h6 style={{ fontSize: '.8rem' }}>
                          Drop off Time :{' '}
                          {moment(item.more_transportation_drop_of_date).format(
                            'LT'
                          )}{' '}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
              <section id='section-6'>
                <div className='row mt-5 hotel-checkout-shadow mx-2 rounded-4 pb-3'>
                  <div className='col-sm-6 col-4 col-md-4 item-from'>
                    <h4 className='px-3' style={{color:'black'}}>Visa Details</h4>
                  </div>
                  <div className='col-sm-6 col-8 col-md-3 text-center item-from'>
                    <div class='image-thumb'>
                      <img
                        src={
                          url +
                          '/public/uploads/package_imgs/' +
                          tourDetail.tours.visa_image
                        }
                        alt=''
                        height='100'
                      />
                    </div>
                    <div class=''>
                      <h6 style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                        Visa Type
                      </h6>
                      <h6 style={{ fontSize: '1rem', color: 'gray' }}>
                        {tourDetail.tours.visa_type}
                      </h6>
                    </div>
                  </div>
                  <div className='col-sm-12 col-8 col-md-5 text-justify item-from'>
                    <h6 style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                      Visa Requirements
                    </h6>
                    <h6 style={{ fontSize: '.8rem', color: 'gray' }}>
                      {/* {tourDetail.tours.visa_detials} */}
                    </h6>
                  </div>
                </div>
              </section>
              {/* <div id='section-2' data-stid="cruise-itinerary" tabindex="-1">
                <div class="mb-4 uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-margin-blockstart-six uitk-card-has-primary-theme hotel-checkout-shadow">
                  <h2 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-four" tabindex="-1">Itinerary</h2>
                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-rows uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-four uitk-iternary">
                    <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end uitk-iternary1">
                      <div>
                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-iternary1-ol">
                          <div>
                            <ol class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 px-0" role="list">
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 1</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Zeebrugge</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Belgium</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">6:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>6:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 2</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Le Havre</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">France</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">9:00am to 9:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>9:00am — 9:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 3</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Southampton</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">United Kingdom</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">7:00am to 8:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>7:00am — 8:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 4</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">At Sea</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true"></div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 5</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Hamburg</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Germany</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">7:00am to 9:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>7:00am — 9:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 6</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">At Sea</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true"></div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 7</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Rotterdam</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Netherlands</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">8:00am to 8:00pm</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>8:00am — 8:00pm
                                </div>
                                <hr class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-border-blockstart uitk-spacing-hr"/>
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-none uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                                <h3 class="uitk-subheading">Day 8</h3>
                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-default-theme">Zeebrugge</div>
                                <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Belgium</div>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">7:00am</div>
                                <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme" aria-hidden="true">
                                  <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-padding-inlineend-half uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z" clip-rule="evenodd"></path>
                                  </svg>7:00am
                                </div>
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start uitk-iternary2">
                      <div class="uitk-gallery-carousel">
                        <h3 class="is-visually-hidden">7 night Europe</h3>
                        <div class="uitk-gallery-carousel-items ratio-16-9">
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                <img alt="Zeebrugge, Belgium" class="uitk-image-media" src={iternary} data-loaded="false"/>
                              </div>
                            </figure>
                          </div>
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder">
                                <img alt="Zeebrugge, Belgium" class="uitk-image-media" src={iternary}/>
                              </div>
                              <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                <span class="is-visually-hidden">Zeebrugge, Belgium</span>
                              </button>
                            </figure>
                          </div>
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                <img alt="Le Havre which includes a city and general coastal views" class="uitk-image-media" src={iternary} data-loaded="false"/>
                              </div>
                            </figure>
                          </div>
                          <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                            <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                              <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                <img alt="Southampton featuring heritage architecture, chateau or palace and heritage elements" class="uitk-image-media" src={iternary} data-loaded="false"/>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div class="uitk-gallery-carousel-paging-controls">
                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Previous item" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <title id="prev-button-sailing-details-carousel-title">Previous item</title>
                              <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                            </svg>
                          </button>
                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Next item" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <title id="next-button-sailing-details-carousel-title">Next item</title>
                              <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                            </svg>
                          </button>
                        </div>
                        <div class="uitk-scrim uitk-gallery-carousel-scrim fade-bottom">
                          <div class="uitk-gallery-carousel-footer">
                            <div class="uitk-gallery-carousel-footer-media-caption">Zeebrugge, Belgium</div>
                            <div class="uitk-action-buttons">
                              <button type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-overlay uitk-gallery-button">
                                <svg class="uitk-icon uitk-icon-leading" aria-label="Show all 6 items" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <title id="photo_library-sailing-details-carousel-title">Show all 6 items</title>
                                  <path fill-rule="evenodd" d="M22 16V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2zm-11-4 2.03 2.71L16 11l4 5H8l3-4zm-9 8V6h2v14h14v2H4a2 2 0 0 1-2-2z" clip-rule="evenodd"></path>
                                </svg>
                                <span aria-hidden="true">6</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            
              {/* <section id='section-2'>
                <div className='row mt-5'>
                  <div className='col-md-4'>
                     <h4 className='mt-3' style={{color:'black'}}>Itinerary Schedule</h4>
                  </div>
                  <div className='col-md-8'>
                      <button
                        className={`accordion  ${activeIndex === 0 ? 'active' : ''}`}
                        onClick={() => show(0)}
                      >
                        {Itinerarydetail[0].Itinerary_title}:{' '}
                        {Itinerarydetail[0].Itinerary_content}
                      </button>
                      <div className='panel'>
                        <p>{Itinerarydetail[0].Itinerary_city}</p>
                      </div>
                      {Itinerarydetailmore.map((item, index) => (
                        <div key={index}>
                          <button
                            className={`accordion  ${
                              activeIndex === index + 1 ? 'active' : ''
                            }`}
                            onClick={() => show(index + 1)}
                          >
                            {item.more_Itinerary_title} : {item.more_Itinerary_city}
                          </button>
                          <div class='panel'>
                            <p>{item.more_Itinerary_content}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section> */}
              <div id="whats-included" class="uitk-card mt-3 uitk-card-roundcorner-all uitk-spacing uitk-spacing-margin-blockstart-four uitk-card-has-primary-theme hotel-checkout-shadow" tabindex="-1">
                <div class="uitk-spacing uitk-spacing-padding-four">
                  <h2 class="uitk-heading uitk-heading-5" tabindex="-1">What's included</h2>
                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">These are already included in the cost of your cruise</div>
                  <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                      <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid included-uitk">
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="local_dining-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="local_dining-title">AMENITY</title>
                            <desc id="local_dining-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M20.15 10.15c-1.59 1.59-3.74 2.09-5.27 1.38L13.41 13l6.88 6.88-1.41 1.41L12 14.41l-6.89 6.87-1.41-1.41 9.76-9.76c-.71-1.53-.21-3.68 1.38-5.27 1.92-1.91 4.66-2.27 6.12-.81 1.47 1.47 1.1 4.21-.81 6.12zm-9.22.36L8.1 13.34 3.91 9.16a4 4 0 0 1 0-5.66l7.02 7.01z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Dining</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Main dining, buffet meals, and snacks</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="lob_activities-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="lob_activities-title">AMENITY</title>
                            <desc id="lob_activities-description">AMENITY</desc>
                            <path d="M22 10.18V6H2v4.18c.9 0 1.66.75 1.66 1.66 0 .9-.76 1.66-1.66 1.66v4.18h20V13.5c-.9 0-1.66-.76-1.66-1.66 0-.9.76-1.66 1.66-1.66zm-4.16 4.57c0 .23-.2.43-.43.43H6.59a.43.43 0 0 1-.43-.43V8.93c0-.23.2-.43.43-.43h10.82c.23 0 .43.2.43.43v5.82zm-10-4.57h8.32v3.32H7.84v-3.32z"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Onboard entertainment</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Broadway-style shows and live music</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="kids_activities-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="kids_activities-title">AMENITY</title>
                            <desc id="kids_activities-description">AMENITY</desc>
                            <path d="M12.45 5.91h-.05l-.47 1.48h.98l-.46-1.48z"></path>
                            <path fill-rule="evenodd" d="M9.78 2.67c-1 0-1.78.8-1.78 1.77v5.34c0 .96.82 1.78 1.78 1.78h5.33c1 0 1.78-.8 1.78-1.78V4.44c0-.98-.8-1.77-1.78-1.77H9.78zm4.43 6.22h-.85l-.28-.9h-1.32l-.3.9h-.78l1.3-3.76h.93l1.3 3.76z" clip-rule="evenodd"></path>
                            <path d="M6.5 15.51v.96h.6c.42 0 .65-.18.65-.48s-.21-.48-.59-.48H6.5zm0 1.49v1.07h.7c.46 0 .7-.2.7-.55 0-.34-.25-.52-.71-.52H6.5z"></path><path fill-rule="evenodd" d="M4.44 12.44c-.98 0-1.77.8-1.77 1.78v5.33c0 .97.82 1.79 1.77 1.78h5.34c.99 0 1.78-.8 1.78-1.77v-5.34c0-.99-.8-1.78-1.78-1.78H4.44zm4.27 5.15c0 .66-.5 1.08-1.3 1.08H5.7V14.9h1.65c.73 0 1.17.36 1.17.94 0 .38-.3.74-.68.8v.05c.5.03.86.4.86.9zm6.4-5.15c-.99 0-1.78.8-1.78 1.78v5.33c0 .97.82 1.79 1.78 1.78h5.34c.98 0 1.77-.8 1.77-1.77v-5.34c0-.99-.8-1.78-1.78-1.78h-5.33zm4.38 4.95c-.07.81-.73 1.37-1.64 1.37-1.09 0-1.76-.75-1.76-1.97s.67-1.98 1.76-1.98c.9 0 1.58.6 1.63 1.41h-.76a.84.84 0 0 0-.87-.71c-.59 0-.96.48-.96 1.28 0 .79.37 1.28.97 1.28.45 0 .79-.27.86-.68h.77z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Youth programs</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Ages 3-17</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="room_service-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="room_service-title">AMENITY</title>
                            <desc id="room_service-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M14 7c0 .28-.06.55-.16.79A9.01 9.01 0 0 1 21 16H3a9.01 9.01 0 0 1 7.16-8.21A2 2 0 0 1 12 5a2 2 0 0 1 2 2zm8 12v-2H2v2h20z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Room service</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">24-hour in-room dining option</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="local_bar-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="local_bar-title">AMENITY</title>
                            <desc id="local_bar-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M21 3v2l-8 9v5h5v2H6v-2h5v-5L3 5V3h18zM5.66 5l1.77 2h9.14l1.78-2H5.66z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Drinks</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Water and non-specialty coffee and tea</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="spa-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="spa-title">AMENITY</title>
                            <desc id="spa-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M12.06 2a11.8 11.8 0 0 1 3.43 7.63A14.36 14.36 0 0 0 12 12.26a13.87 13.87 0 0 0-3.49-2.63A12.19 12.19 0 0 1 12.06 2zM2 10a12.17 12.17 0 0 0 10 12 12.17 12.17 0 0 0 10-12c-4.18 0-7.85 2.17-10 5.45A11.95 11.95 0 0 0 2 10z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Spa facilities</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Relaxing wellness spaces</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="fitness_center-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="fitness_center-title">AMENITY</title>
                            <desc id="fitness_center-description">AMENITY</desc>
                            <path d="M20.57 14.86 22 13.43 20.57 12 17 15.57 8.43 7 12 3.43 10.57 2 9.14 3.43 7.71 2 5.57 4.14 4.14 2.71 2.71 4.14l1.43 1.43L2 7.71l1.43 1.43L2 10.57 3.43 12 7 8.43 15.57 17 12 20.57 13.43 22l1.43-1.43L16.29 22l2.14-2.14 1.43 1.43 1.43-1.43-1.43-1.43L22 16.29l-1.43-1.43z"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Fitness facilities</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Cardio and exercise equipment</div>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="uitk-spacing uitk-spacing-padding-four">
                  <h2 class="uitk-heading uitk-heading-5" tabindex="-1">Additional costs to consider</h2>
                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">Purchase these individually or choose an offer that includes them</div>
                  <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2" role="list">
                      <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid included-uitk">
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="local_bar-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="local_bar-title">AMENITY</title>
                            <desc id="local_bar-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M21 3v2l-8 9v5h5v2H6v-2h5v-5L3 5V3h18zM5.66 5l1.77 2h9.14l1.78-2H5.66z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Alcoholic drinks</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Beer, wine, and spirits</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="monetization_on-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="monetization_on-title">AMENITY</title>
                            <desc id="monetization_on-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11.41 8v-1.91c1.74-.33 3.11-1.33 3.12-3.16 0-2.52-2.16-3.39-4.18-3.91-2.02-.52-2.67-1.07-2.67-1.91 0-.96.9-1.64 2.4-1.64 1.58 0 2.17.76 2.22 1.87h1.96a3.54 3.54 0 0 0-2.85-3.39V4h-2.67v1.93c-1.72.37-3.11 1.49-3.11 3.21 0 2.05 1.7 3.07 4.18 3.67 2.23.53 2.67 1.31 2.67 2.14 0 .61-.44 1.59-2.4 1.59-1.83 0-2.55-.82-2.65-1.87H7.47c.11 1.94 1.56 3.04 3.27 3.4V20h2.67z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Gratuities</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">From $12 - $18 USD per person per day</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="wifi-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="wifi-title">AMENITY</title>
                            <desc id="wifi-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="m1 9 2 2a12.73 12.73 0 0 1 18 0l2-2A15.57 15.57 0 0 0 1 9zm8 8 3 3 3-3a4.24 4.24 0 0 0-6 0zm-2-2-2-2a9.91 9.91 0 0 1 14 0l-2 2a7.07 7.07 0 0 0-10 0z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">WiFi</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Stay connected on board</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="local_dining-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="local_dining-title">AMENITY</title>
                            <desc id="local_dining-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M20.15 10.15c-1.59 1.59-3.74 2.09-5.27 1.38L13.41 13l6.88 6.88-1.41 1.41L12 14.41l-6.89 6.87-1.41-1.41 9.76-9.76c-.71-1.53-.21-3.68 1.38-5.27 1.92-1.91 4.66-2.27 6.12-.81 1.47 1.47 1.1 4.21-.81 6.12zm-9.22.36L8.1 13.34 3.91 9.16a4 4 0 0 1 0-5.66l7.02 7.01z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Specialty dining</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">À la carte restaurants, lounges, and cafes</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="local_bar-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="local_bar-title">AMENITY</title>
                            <desc id="local_bar-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M21 3v2l-8 9v5h5v2H6v-2h5v-5L3 5V3h18zM5.66 5l1.77 2h9.14l1.78-2H5.66z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Specialty drinks</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Specialty coffee and tea, soft drinks, juice, and bottled water</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="airport_shuttle-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="airport_shuttle-title">AMENITY</title>
                            <desc id="airport_shuttle-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M3 5h14l6 6v5h-2.5a3 3 0 0 1-6 0H9a3 3 0 0 1-6 0H1V7a2 2 0 0 1 2-2zm0 2v4h4V7H3zm3 10.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM9 11h4V7H9v4zm8.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM15 7v4h5l-4-4h-1z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Shore excursions</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Immersive port of call experiences</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="spa-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="spa-title">AMENITY</title>
                            <desc id="spa-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M12.06 2a11.8 11.8 0 0 1 3.43 7.63A14.36 14.36 0 0 0 12 12.26a13.87 13.87 0 0 0-3.49-2.63A12.19 12.19 0 0 1 12.06 2zM2 10a12.17 12.17 0 0 0 10 12 12.17 12.17 0 0 0 10-12c-4.18 0-7.85 2.17-10 5.45A11.95 11.95 0 0 0 2 10z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Spa treatments</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Massages and wellness treatments</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="spa-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="spa-title">AMENITY</title>
                            <desc id="spa-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M12.06 2a11.8 11.8 0 0 1 3.43 7.63A14.36 14.36 0 0 0 12 12.26a13.87 13.87 0 0 0-3.49-2.63A12.19 12.19 0 0 1 12.06 2zM2 10a12.17 12.17 0 0 0 10 12 12.17 12.17 0 0 0 10-12c-4.18 0-7.85 2.17-10 5.45A11.95 11.95 0 0 0 2 10z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Salon services</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Hair care, manicures, and pedicures</div>
                          </div>
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="fitness_center-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="fitness_center-title">AMENITY</title>
                            <desc id="fitness_center-description">AMENITY</desc>
                            <path d="M20.57 14.86 22 13.43 20.57 12 17 15.57 8.43 7 12 3.43 10.57 2 9.14 3.43 7.71 2 5.57 4.14 4.14 2.71 2.71 4.14l1.43 1.43L2 7.71l1.43 1.43L2 10.57 3.43 12 7 8.43 15.57 17 12 20.57 13.43 22l1.43-1.43L16.29 22l2.14-2.14 1.43 1.43 1.43-1.43-1.43-1.43L22 16.29l-1.43-1.43z"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Fitness classes</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Variety of instructor-led exercise sessions</div>
                          </div> 
                        </li>
                        <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-alternate uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-indent" role="listitem">
                          <svg class="uitk-icon" aria-describedby="local_laundry_service-description" aria-label="AMENITY" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title id="local_laundry_service-title">AMENITY</title>
                            <desc id="local_laundry_service-description">AMENITY</desc>
                            <path fill-rule="evenodd" d="M18 2.01 6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4c0-1.11-.89-1.99-2-1.99zM11 5a1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1zM9.17 16.83a4 4 0 0 0 5.66-5.66l-5.66 5.66zM7 4a1 1 0 0 1 1 1 1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1zM6 14a6 6 0 1 0 12.01-.01A6 6 0 0 0 6 14z" clip-rule="evenodd"></path>
                          </svg>
                          <div>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">Laundry services</div>
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wash and press amenities</div>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <section id='section-7'>
                 <Faqs />
              </section>
            </div>
            <div className='col-md-3'>
              <div className='theiaStickySidebar'>
                <div className=' book-package-2'>
                  <h6>Why Book with us?</h6>
                  <ul class='list-items book-package-3  list-items-2 mt-2 py-2'>
                  <li className=' fs-6'>
                      <span className='book-package-side-box'><FontAwesomeIcon icon={faDollar}/></span>No-hassle best price guarantee
                    </li>
                    <div className='border-line'></div>
                    <li className='mt-2 fs-6'>
                      <span className='book-package-side-box'><FontAwesomeIcon icon={faHeadphones}/></span>Customer care available 24/7
                    </li>
                    <div className='border-line'></div>
                    <li className='mt-2 fs-6'>
                      <span className='book-package-side-box'><FontAwesomeIcon icon={faStar}/></span>Picked Packages to your Need
                    </li>
                    <div className='border-line'></div>
                    <li className='mt-2 fs-6'>
                      <span className='book-package-side-box'><FontAwesomeIcon icon={faLock}/></span>Secure Payment & Privacy
                    </li>
                  </ul>
                </div>
                <div className='book-package-4'>
                  <h3 >Got a Question?</h3>
                  <div>
                    <p  className='mt-3'>Do not hesitate to give us a call. Our expert team would be happy to help you.</p>
                    <h5  className='mt-3'><FontAwesomeIcon icon={faPhone}/> 0121 777 2522</h5>
                    <h5  className='mt-3'><FontAwesomeIcon icon={faEnvelope}/> info@expedia.net</h5>
                  </div>
                </div>
                <div className='book-now text-center cart-btn'>
                  <NavLink to='/book_package'>
                    <button class='btn select-styling search-btn1 mt-2 detail-view-btn' > Add to Cart</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div class="uitk-spacing SimpleContainer ">
              <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme hotel-checkout-shadow">
                      <figure class="uitk-image uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-image-ratio-3-2 uitk-image-ratio figure-tree">
                          <div class="uitk-image-placeholder">
                              <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/footer/bnaBanners/BEX_Footer.jpg"/>
                          </div>
                      </figure>
                      <div class="uitk-card-content-section uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-tree">
                          <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-six">
                              <div role="region" aria-labelledby="marquee-title" class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start width-q">
                                  <h2 class="uitk-heading uitk-heading-3" id="marquee-title">Go further with the Expedia app</h2>
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-type-paragraph-300 uitk-spacing-padding-blockstart-two uitk-spacing-padding-inlineend-four">Save on select hotels and earn OneKeyCash on bookings in the app. Our app deals help you to save on trips so you can travel more and manage it all on the go.</div>
                                  <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-twelve uitk-spacing-padding-inlineend-four uitk-spacing-padding-blockend-two">Scan the QR code with your device camera and download our app</div>
                                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid switch">
                                      <div class="uitk-spacing uitk-spacing-padding-blockstart-six uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                          <a id="download-btn" name="download-btn" href="https://expe.app.link/?~customer_ad_set_name=EXPEDIA-US&amp;~customer_ad_name=BRAND.EXPEDIA.OWNED.BRANCH.DEFAULT.MAD&amp;~customer_campaign=BRAND&amp;~customer_placement=BEX-HP-MARQUEEDESKTOP&amp;~placement_id=Homepage&amp;%24canonical_url=https%3A%2F%2Fwww.expedia.com%2F&amp;%24desktop_url=https%3A%2F%2Fwww.expedia.com%2Fapp&amp;custom_web_attribute=add49b7a-17c6-412c-957e-78218a70f45a" class="uitk-button uitk-button-large uitk-button-fullWidth uitk-button-has-text uitk-button-as-link uitk-button-primary">Switch to the app</a>
                                      </div>
                                  </div>
                              </div>
                              <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start QR-code">
                                  <div class="uitk-spacing uitk-spacing-padding-inlinestart-four">
                                      <figure class="uitk-image uitk-image-ratio-1-1 uitk-image-ratio">
                                          <div class="uitk-image-placeholder">
                                              <img alt="QR code" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/qr-code/footer_qr_hp/1_QR_SHEET_CONTROL_FOOTER_HP.png"/>
                                          </div>
                                      </figure>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={cancelModal} toggle={toggleModal2}>
        <ModalHeader toggle={toggleModal2}> Cancellation Policy</ModalHeader>
        <ModalBody>
          <div className='form-group'>
           <p>{tourDetail.tours.cancellation_policy}</p>
          </div>
        </ModalBody>
      </Modal>
      <div id="mybutton">
        <NavLink to='/book_package'>
          <button class="btn feedback  select-styling search-btn1 mt-2 detail-view-btn">Add to Cart</button>
        </NavLink>
      </div>
    </>
  )
}

export default ViewDeail
