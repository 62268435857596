import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faPlaneDeparture,
  faPlaneArrival,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Axios from 'axios'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Loading/Loader'
import {
  CustomerDomainName,
  Hotelapitoken,
  ApiEndPoint
} from '../GlobalData/GlobalData'
function PackageDetailCard ({ filterData }) {
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [FilterData, setFilterData] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const imageurl = CustomerDomainName()
  const TourData = useSelector(state => state.hotels.toursdetail);
  const ToursDetail =TourData.tours.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
  var apiendpoint = ApiEndPoint();

  const fetchViewDetail = async (event, id, otherprovider,name) => {
    event.preventDefault()
    var apitoken = Hotelapitoken()
    setLoading(true)
    if (otherprovider === 0) {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: ''
      }
    } else {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: otherprovider
      }
    }

    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      Dispatch(ViewTourDetail(response.data));
      const result = name.split(' ').join('-');
      navigation(`/umrah-package/${result}`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    DataFilter()
  }, [filterData])

  const DataFilter = () => {
    if (filterData !== '') {
      if (filterData.type === 'price') {
        const filteredTours = ToursDetail.filter(tour => {
          const price = tour.quad_grand_total_amount
          return price >= filterData.min && price <= filterData.max
        })
        setFilterData(filteredTours)
        setShowFilter(false)
      } else if (filterData.type === 'Stars') {
        if (
          filterData.rating1 === '0' &&
          filterData.rating2 === '0' &&
          filterData.rating3 === '0' &&
          filterData.rating4 === '0' &&
          filterData.rating5 === '0'
        ) {
          setFilterData(ToursDetail)
          setShowFilter(false)
        } else {
          const filteredTours = ToursDetail.filter(tour => {
            const hotelRating = tour.starts_rating
            if (hotelRating !== '') {
              return Object.keys(filterData).some(
                ratingKey =>
                  Number(hotelRating) === Number(filterData[ratingKey])
              )
            }
          })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
      }else if (filterData.type === 'airport') {
        var length= Object.keys(filterData).length;
        if(length === 1){
          setFilterData(ToursDetail)
          setShowFilter(false)
        }else{
          
          const filteredTours = ToursDetail.filter(tour => {
            var flightdetail=JSON.parse(tour.flights_details);
           
            var name=flightdetail===null ? '' : flightdetail[0].departure_airport_code;
  
            return Object.keys(filterData).some(
              ratingKey =>
              name === filterData[ratingKey]
            )        })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
       
      }
    }
  };
  
  return (
    <>
      {loading && <Loading />}
      {showFilter ? (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                {ToursDetail.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                    <div key={index} className='wow animate__animated animate__slideInRight mt-3'
                        data-wow-duration="1s"
                        data-wow-delay="0.2s">
                      <div className='row parent_row p-0 rounded-4' style={{cursor:'pointer'}} onClick={event => fetchViewDetail(event, item.id,0,item.title)} >
                        <div className='col-md-3 item-from px-0'>
                            <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative">
                              <figure class="uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                <span>
                                  <span>
                                    <div class="uitk-gallery-carousel">
                                      <div class="uitk-gallery-carousel-items ratio-21-9 rounded-start-4">
                                        <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                          <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                            <div class="uitk-image-placeholder">
                                                <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                  <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                                    <div class="uitk-image-placeholder">
                                                      <img alt="Room" class="uitk-image-media" src={imageurl +'public/uploads/package_imgs/' + item.tour_banner_image}  />
                                                    </div>
                                                    <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                      <span class="is-visually-hidden">Room</span>
                                                    </button>
                                                  </figure>
                                                </div>
                                            </div>
                                          </figure>
                                        </div>
                                        <div class="uitk-gallery-carousel-paging-controls">
                                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <title id="prev-button-lodging-card-gallery-carousel-16249642-1-title">Show previous image for Swissôtel Al Maqam Makkah</title>
                                              <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                            </svg>
                                          </button>
                                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <title id="next-button-lodging-card-gallery-carousel-16249642-1-title">Show next image for Swissôtel Al Maqam Makkah</title>
                                              <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </span>
                              </figure>
                              {/* <div class="uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                <div data-stid="outlined-save-button">
                                  <div class="favorite-button-wrapper">
                                    <button type="button" aria-label="Save Swissôtel Al Maqam Makkah to a trip" class="favorite-button favorite-button-button-no-label">
                                      <svg class="uitk-icon favorite-button-fill favorite-button-fill-default" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="m12 22-8.44-8.69a5.55 5.55 0 0 1 0-7.72C4.53 4.59 5.9 4 7.28 4c1.4 0 2.75.59 3.72 1.59l1 1.02 1-1.02c.97-1 2.32-1.59 3.72-1.59 1.4 0 2.75.59 3.72 1.59a5.55 5.55 0 0 1 0 7.72L12 22Z" clip-rule="evenodd"></path>
                                      </svg>
                                      <svg class="uitk-icon favorite-button-border favorite-button-border-default favorite-button-border-color-highlighed" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="m12 22 8.44-8.69a5.55 5.55 0 0 0 0-7.72A5.24 5.24 0 0 0 16.72 4 5.22 5.22 0 0 0 13 5.59L12 6.6l-1-1.02a5.2 5.2 0 0 0-7.44 0 5.55 5.55 0 0 0 0 7.72L12 22Zm0-2.87-7-7.21a3.55 3.55 0 0 1 0-4.94C5.6 6.36 6.44 6 7.28 6c.84 0 1.69.36 2.3.98L12 9.48l2.43-2.5c.6-.62 1.45-.98 2.29-.98.84 0 1.68.36 2.28.98a3.55 3.55 0 0 1 0 4.94l-7 7.2Z" clip-rule="evenodd" opacity=".9"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div> 
                              </div> */}
                            </div>
                        </div>
                        <div className='col-md-9'>
                          <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                            <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                              <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                                <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two">
                                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-rows uitk-layout-grid-display-grid uitk-layout-flex-item">
                                    <h3 class="uitk-heading uitk-heading-5 overflow-wrap uitk-layout-grid-item uitk-layout-grid-item-has-row-start" onClick={event => fetchViewDetail(event, item.id,0,item.title)}>{item.title}</h3>
                                      {/* <p style={{color:'#4d5167'}}>
                                        {item.starts_rating === 'No_Rating' ? (
                                            <span className='fw-bold'>No Rating</span>
                                          ) : (
                                            <>
                                              {Array.from({
                                                length: parseInt(item.starts_rating, 10)
                                              }).map((_, index) => (
                                                <i key={index} className='fa fa-star'>
                                                  {' '}
                                                  <FontAwesomeIcon icon={faStar} />
                                                </i>
                                              ))}
                                            </>
                                          )}
                                      </p> */}
                                  </div>
                                </div>
                                <div class="uitk-text uitk-text-spacing-half truncate-lines-2 uitk-type-300 uitk-text-default-theme" aria-hidden="false">
                                    <div class="uitk-text uitk-type-200 uitk-type-bold uitk-text-default-theme py-2">  
                                      <div className='row f-13'>
                                        <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                                          <p>
                                            <i className='fa-solid fa-plane-departure'>
                                              <FontAwesomeIcon icon={faPlaneDeparture} />
                                            </i>{' '}
                                            Departure From{' '}
                                          </p>
                                        </div>
                                        <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                          <span> {flightDetails === null ? '':flightDetails[0].departure_airport_code} &nbsp;</span> ({moment(item.start_date).format('LL')}{' '})
                                        </div>
                                        <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                                          <p>
                                            <i className='fa-solid fa-plane-arrival'>
                                              <FontAwesomeIcon icon={faPlaneArrival} />
                                            </i>{' '}
                                            Return Date{' '}
                                          </p>
                                        </div>
                                        <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                          <p>
                                            {' '}
                                            {moment(item.end_date).format('LL')} &nbsp;
                                          </p>
                                        </div>
                                        <div className='col-md-5 col-5 col-sm-5 mt-1  p-car-departure'>
                                          <p>
                                            <i className='fa-solid fa-check'>
                                              <FontAwesomeIcon icon={faCheck} />
                                            </i>{' '}
                                            Available Spaces{' '}
                                          </p>
                                        </div>
                                        <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                          <p> {item.no_of_pax_days} &nbsp;</p>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x mt-3">
                                  <div class="uitk-text uitk-type-200 uitk-text-default-theme">{item.content}</div>
                                </div>
                              </div>
                              <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                  <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                        <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                          <span class="uitk-badge-base-text" aria-hidden="true">
                                              {item.starts_rating === 'No_Rating' ? (
                                                  <span className='fw-bold'>No Rating</span>
                                                ) : (
                                                  <>
                                                    {item.starts_rating}.0
                                                  </>
                                                )}
                                          </span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                          <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                            <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                            <span class="is-visually-hidden">  
                                              <p className='card-star'>
                                              {item.starts_rating === 'No_Rating' ? (
                                                  <span className='fw-bold'>No Rating</span>
                                                ) : (
                                                  <>
                                                    {item.starts_rating}.0
                                                    {Array.from({
                                                      length: parseInt(item.starts_rating, 10)
                                                    }).map((_, index) => (
                                                      <i key={index} className='fa fa-star'>
                                                        {' '}
                                                        <FontAwesomeIcon icon={faStar} />
                                                      </i>
                                                    ))}
                                                  </>
                                                )}
                                              </p>
                                            </span>
                                          </span>
                                        </div>
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                          <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                            <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                            <span class="is-visually-hidden">(555 reviews)</span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end">
                                  <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                    <div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                          <span aria-hidden="true" className='uitk-lockup-price'>
                                            <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">
                                              <h4 className='uitk-lockup-price'>
                                                {item.currency_symbol}
                                                <super>
                                                  {item.quad_grand_total_amount !== null
                                                  ? item.quad_grand_total_amount
                                                  : item.triple_grand_total_amount !== null
                                                  ? item.triple_grand_total_amount
                                                  : item.double_grand_total_amount !== null
                                                  ? item.double_grand_total_amount
                                                  : 'N/A'}  
                                                </super>
                                              </h4>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                        <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme"> 
                                          <div className='time_length mt-2'>
                                            <i className='fa fa-moon-o' aria-hidden='true'></i>
                                            Tour Length{' '}<span> {item.time_duration} Night</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                        <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a rel="noopener" data-stid="open-package-information" target="_blank"  onClick={event => fetchViewDetail(event, item.id,0,item.title)} class="uitk-card-link">
                          <span class="is-visually-hidden">{item.title}</span>
                        </a>
                      </div>
                    </div>
                  )
                })}
                <div className='my-4'> <h5 style={{color:'black'}}>Packages From Other Providers</h5> </div>
                {TourData.other_providers_tours.map((provider, index) => (
                  <div key={index}>
                    {provider[0].map((item, index) => {
                      const flightDetails = JSON.parse(item.flights_details)
                      return (
                        <div key={index} className='mt-2'>
                            <div className='row parent_row p-0 rounded-4' style={{cursor:'pointer'}} 
                            // onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                             >
                              <div className='col-md-3 item-from px-0'>
                                  <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative">
                                    <figure class="uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                      <span>
                                        <span>
                                          <div class="uitk-gallery-carousel">
                                            <div class="uitk-gallery-carousel-items ratio-21-9 rounded-start-4">
                                              <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                                  <div class="uitk-image-placeholder">
                                                      <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                                          <div class="uitk-image-placeholder">
                                                            <img alt="Room" class="uitk-image-media" src={provider[1].webiste_Address +'/public/uploads/package_imgs/' + item.tour_banner_image}  />
                                                          </div>
                                                          <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                            <span class="is-visually-hidden">Room</span>
                                                          </button>
                                                        </figure>
                                                      </div>
                                                  </div>
                                                </figure>
                                              </div>
                                              <div class="uitk-gallery-carousel-paging-controls">
                                                <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                                                  <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <title id="prev-button-lodging-card-gallery-carousel-16249642-1-title">Show previous image for Swissôtel Al Maqam Makkah</title>
                                                    <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                                  </svg>
                                                </button>
                                                <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                                                  <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <title id="next-button-lodging-card-gallery-carousel-16249642-1-title">Show next image for Swissôtel Al Maqam Makkah</title>
                                                    <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </span>
                                      </span>
                                    </figure>
                                  </div>
                              </div>
                              <div className='col-md-9'>
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                                  <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                                    <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                                      <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two">
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-rows uitk-layout-grid-display-grid uitk-layout-flex-item">
                                          <h3 class="uitk-heading uitk-heading-5 overflow-wrap uitk-layout-grid-item uitk-layout-grid-item-has-row-start" onClick={event => fetchViewDetail(event, item.id,0,item.title)}>{item.title}</h3>
                                            {/* <p style={{color:'#4d5167'}}>
                                              {item.starts_rating === 'No_Rating' ? (
                                                  <span className='fw-bold'>No Rating</span>
                                                ) : (
                                                  <>
                                                    {Array.from({
                                                      length: parseInt(item.starts_rating, 10)
                                                    }).map((_, index) => (
                                                      <i key={index} className='fa fa-star'>
                                                        {' '}
                                                        <FontAwesomeIcon icon={faStar} />
                                                      </i>
                                                    ))}
                                                  </>
                                                )}
                                            </p> */}
                                        </div>
                                      </div>
                                      <div class="uitk-text uitk-text-spacing-half truncate-lines-2 uitk-type-300 uitk-text-default-theme" aria-hidden="false">
                                          <div class="uitk-text uitk-type-200 uitk-type-bold uitk-text-default-theme py-2">  
                                          {item.content}
                                          </div>
                                      </div>
                                      <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x">
                                        <div class="uitk-text uitk-type-200 uitk-text-default-theme">
                                          <div className='row f-13'>
                                            <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                                              <p>
                                                <i className='fa-solid fa-plane-departure'>
                                                  <FontAwesomeIcon icon={faPlaneDeparture} />
                                                </i>{' '}
                                                Departure From{' '}
                                              </p>
                                            </div>
                                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                              <span> {flightDetails === null ? '':flightDetails[0].departure_airport_code} &nbsp;</span> ({moment(item.start_date).format('LL')}{' '})
                                            </div>
                                            <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                                              <p>
                                                <i className='fa-solid fa-plane-arrival'>
                                                  <FontAwesomeIcon icon={faPlaneArrival} />
                                                </i>{' '}
                                                Return Date{' '}
                                              </p>
                                            </div>
                                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                              <p>
                                                {' '}
                                                {moment(item.end_date).format('LL')} &nbsp;
                                              </p>
                                            </div>
                                            <div className='col-md-5 col-5 col-sm-5 mt-1  p-car-departure'>
                                              <p>
                                                <i className='fa-solid fa-check'>
                                                  <FontAwesomeIcon icon={faCheck} />
                                                </i>{' '}
                                                Available Spaces{' '}
                                              </p>
                                            </div>
                                            <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                              <p> {item.no_of_pax_days} &nbsp;</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                                      <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                              <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                                <span class="uitk-badge-base-text" aria-hidden="true">
                                                    {item.starts_rating === 'No_Rating' ? (
                                                        <span className='fw-bold'>No Rating</span>
                                                      ) : (
                                                        <>
                                                          {item.starts_rating}.0
                                                        </>
                                                      )}
                                                </span>
                                              </span>
                                            </div>
                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                              <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                                <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                  <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                                  <span class="is-visually-hidden">  
                                                    <p className='card-star'>
                                                    {item.starts_rating === 'No_Rating' ? (
                                                        <span className='fw-bold'>No Rating</span>
                                                      ) : (
                                                        <>
                                                          {item.starts_rating}.0
                                                          {Array.from({
                                                            length: parseInt(item.starts_rating, 10)
                                                          }).map((_, index) => (
                                                            <i key={index} className='fa fa-star'>
                                                              {' '}
                                                              <FontAwesomeIcon icon={faStar} />
                                                            </i>
                                                          ))}
                                                        </>
                                                      )}
                                                    </p>
                                                  </span>
                                                </span>
                                              </div>
                                              <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                                <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                  <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                                  <span class="is-visually-hidden">(555 reviews)</span>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                          <div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                <span aria-hidden="true" className='uitk-lockup-price'>
                                                  <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">
                                                    <h4>
                                                      {item.currency_symbol}
                                                      <super>{item.double_grand_total_amount} </super>{' '}
                                                      <sub>PP</sub>
                                                    </h4>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme"> 
                                                <div className='time_length mt-2'>
                                                  <i className='fa fa-moon-o' aria-hidden='true'></i>
                                                  Tour Length{' '}<span> {item.time_duration} Night</span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <a rel="noopener" target="_blank"  onClick={event => fetchViewDetail(event, item.id,0,item.title)} class="uitk-card-link">
                                <span class="is-visually-hidden">{item.title}</span>
                              </a> */}
                            </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                {FilterData.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                    <div key={index} className='mt-3'>
                      <div className='row parent_row p-0 rounded-4' style={{cursor:'pointer'}} onClick={event => fetchViewDetail(event, item.id,0,item.title)} >
                        <div className='col-md-3 item-from px-0'>
                            <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative">
                              <figure class="uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                <span>
                                  <span>
                                    <div class="uitk-gallery-carousel">
                                      <div class="uitk-gallery-carousel-items ratio-21-9 rounded-start-4">
                                        <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                          <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                            <div class="uitk-image-placeholder">
                                                <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                  <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                                    <div class="uitk-image-placeholder">
                                                      <img alt="Room" class="uitk-image-media" src={imageurl +'public/uploads/package_imgs/' + item.tour_banner_image}  />
                                                    </div>
                                                    <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                      <span class="is-visually-hidden">Room</span>
                                                    </button>
                                                  </figure>
                                                </div>
                                            </div>
                                          </figure>
                                        </div>
                                        <div class="uitk-gallery-carousel-paging-controls">
                                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <title id="prev-button-lodging-card-gallery-carousel-16249642-1-title">Show previous image for Swissôtel Al Maqam Makkah</title>
                                              <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                            </svg>
                                          </button>
                                          <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <title id="next-button-lodging-card-gallery-carousel-16249642-1-title">Show next image for Swissôtel Al Maqam Makkah</title>
                                              <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </span>
                              </figure>
                            </div>
                        </div>
                        <div className='col-md-9'>
                          <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                            <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                              <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                                <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two">
                                  <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-rows uitk-layout-grid-display-grid uitk-layout-flex-item">
                                    <h3 class="uitk-heading uitk-heading-5 overflow-wrap uitk-layout-grid-item uitk-layout-grid-item-has-row-start" onClick={event => fetchViewDetail(event, item.id,0,item.title)}>{item.title}</h3>
                                      {/* <p style={{color:'#4d5167'}}>
                                        {item.starts_rating === 'No_Rating' ? (
                                            <span className='fw-bold'>No Rating</span>
                                          ) : (
                                            <>
                                              {Array.from({
                                                length: parseInt(item.starts_rating, 10)
                                              }).map((_, index) => (
                                                <i key={index} className='fa fa-star'>
                                                  {' '}
                                                  <FontAwesomeIcon icon={faStar} />
                                                </i>
                                              ))}
                                            </>
                                          )}
                                      </p> */}
                                  </div>
                                </div>
                                <div class="uitk-text uitk-text-spacing-half truncate-lines-2 uitk-type-300 uitk-text-default-theme" aria-hidden="false">
                                    <div class="uitk-text uitk-type-200 uitk-type-bold uitk-text-default-theme py-2">  
                                    {item.content}
                                    </div>
                                </div>
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x">
                                  <div class="uitk-text uitk-type-200 uitk-text-default-theme">
                                    <div className='row f-13'>
                                      <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                                        <p>
                                          <i className='fa-solid fa-plane-departure'>
                                            <FontAwesomeIcon icon={faPlaneDeparture} />
                                          </i>{' '}
                                          Departure From{' '}
                                        </p>
                                      </div>
                                      <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                        <span> {flightDetails === null ? '':flightDetails[0].departure_airport_code} &nbsp;</span> ({moment(item.start_date).format('LL')}{' '})
                                      </div>
                                      <div className='col-md-5 col-5 col-sm-5 mt-1 p-car-departure'>
                                        <p>
                                          <i className='fa-solid fa-plane-arrival'>
                                            <FontAwesomeIcon icon={faPlaneArrival} />
                                          </i>{' '}
                                          Return Date{' '}
                                        </p>
                                      </div>
                                      <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                        <p>
                                          {' '}
                                          {moment(item.end_date).format('LL')} &nbsp;
                                        </p>
                                      </div>
                                      <div className='col-md-5 col-5 col-sm-5 mt-1  p-car-departure'>
                                        <p>
                                          <i className='fa-solid fa-check'>
                                            <FontAwesomeIcon icon={faCheck} />
                                          </i>{' '}
                                          Available Spaces{' '}
                                        </p>
                                      </div>
                                      <div className='col-md-7 col-7 col-sm-7 mt-1 p-car-departure'>
                                        <p> {item.no_of_pax_days} &nbsp;</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                  <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                        <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                          <span class="uitk-badge-base-text" aria-hidden="true">
                                              {item.starts_rating === 'No_Rating' ? (
                                                  <span className='fw-bold'>No Rating</span>
                                                ) : (
                                                  <>
                                                    {item.starts_rating}.0
                                                  </>
                                                )}
                                          </span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                          <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                            <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                            <span class="is-visually-hidden">  
                                              <p className='card-star'>
                                              {item.starts_rating === 'No_Rating' ? (
                                                  <span className='fw-bold'>No Rating</span>
                                                ) : (
                                                  <>
                                                    {item.starts_rating}.0
                                                    {Array.from({
                                                      length: parseInt(item.starts_rating, 10)
                                                    }).map((_, index) => (
                                                      <i key={index} className='fa fa-star'>
                                                        {' '}
                                                        <FontAwesomeIcon icon={faStar} />
                                                      </i>
                                                    ))}
                                                  </>
                                                )}
                                              </p>
                                            </span>
                                          </span>
                                        </div>
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                          <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                            <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                            <span class="is-visually-hidden">(555 reviews)</span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end">
                                  <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                    <div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                          <span aria-hidden="true" className='uitk-lockup-price'>
                                            <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">
                                              <h4 className='uitk-lockup-price'>
                                                {item.currency_symbol}
                                                <super>
                                                  {item.quad_grand_total_amount !== null
                                                  ? item.quad_grand_total_amount
                                                  : item.triple_grand_total_amount !== null
                                                  ? item.triple_grand_total_amount
                                                  : item.double_grand_total_amount !== null
                                                  ? item.double_grand_total_amount
                                                  : 'N/A'}  
                                                </super>
                                              </h4>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                        <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme"> 
                                          <div className='time_length mt-2'>
                                            <i className='fa fa-moon-o' aria-hidden='true'></i>
                                            Tour Length{' '}<span> {item.time_duration} Night</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                        <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a rel="noopener" data-stid="open-hotel-information" target="_blank"  onClick={event => fetchViewDetail(event, item.id,0,item.title)} class="uitk-card-link">
                          <span class="is-visually-hidden">{item.title}</span>
                        </a>
                      </div>
                    </div>
                  )
                })}
                {/* <div className='mt-3'>
                <h5 className='flight-heading'>
                  Packages From Other Providers
                </h5>
              </div>
              {ToursDetail.other_providers_tours.map((provider, index) => (
                <div key={index}>
                  {provider[0].map((item, index) => {
                    const flightDetails = JSON.parse(item.flights_details)
                    return (
                      <div key={index} className='mt-2'>
                        <div className='row parent_row'>
                          <div className='col-md-3'>
                            <div className=''>
                              <img
                                className='tour-img mb-1'
                                src={provider[1].webiste_Address+'/public/uploads/package_imgs/' +item.tour_banner_image}  alt=''
                              />
                              <div
                                id='map-container-google-2'
                                className='z-depth-1-half map-container'
                                style={{ height: '500' }}
                              >
                                <iframe
                                  src='https://maps.google.com/maps?q=chicago&t=&z=13&ie=UTF8&iwloc=&output=embed'
                                  frameborder='0'
                                  allowfullscreen
                                ></iframe>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <h5 className='card-title'>
                              <a href='#' className='p-card-title'>
                                {item.title}
                              </a>
                            </h5>
                            <h6 className='departure-date'>
                              <i className='fa-solid fa-plane-departure'>
                                <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                              </i>
                              {moment(item.start_date).format('LL')}{' '}
                            </h6>

                            <p className='card-star'>
                              {item.starts_rating === 'No_Rating' ? (
                                <span className='fw-bold'>No Rating</span>
                              ) : (
                                <>
                                  {item.starts_rating}.0
                                  {Array.from({
                                    length: parseInt(item.starts_rating, 10)
                                  }).map((_, index) => (
                                    <i key={index} className='fa fa-star'>
                                      {' '}
                                      <FontAwesomeIcon icon={faStar} />
                                    </i>
                                  ))}
                                </>
                              )}
                            </p>
                            <p></p>
                            <p className='tour-description'>{item.content}</p>
                            <hr />
                            <div className='row f-13'>
                              <div className='col-md-5 mt-1 p-car-departure'>
                                <p>
                                  <i className='fa-solid fa-plane-departure'>
                                    <FontAwesomeIcon icon={faPlaneDeparture} />
                                  </i>{' '}
                                  Departure From{' '}
                                </p>
                              </div>
                              <div className='col-md-7 mt-1 p-car-departure'>
                                <p>
                                  {' '}
                                  {flightDetails[0].departure_airport_code}{' '}
                                  &nbsp;
                                </p>
                              </div>
                              <div className='col-md-5 mt-1 p-car-departure'>
                                <p>
                                  <i className='fa-solid fa-plane-arrival'>
                                    <FontAwesomeIcon icon={faPlaneArrival} />
                                  </i>{' '}
                                  Return Date{' '}
                                </p>
                              </div>
                              <div className='col-md-7 mt-1 p-car-departure'>
                                <p>
                                  {' '}
                                  {moment(item.end_date).format('LL')} &nbsp;
                                </p>
                              </div>
                              <div className='col-md-5 mt-1  p-car-departure'>
                                <p>
                                  <i className='fa-solid fa-check'>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </i>{' '}
                                  Available Spaces{' '}
                                </p>
                              </div>
                              <div className='col-md-7 mt-1 p-car-departure'>
                                <p> {item.no_of_pax_days} &nbsp;</p>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-3 text-center card-price-section pt-2'>
                            <div className='price text-center p-card-price mt-2'>
                              <h6>
                                {item.currency_symbol}
                                <super>
                                  {item.double_grand_total_amount}
                                </super>{' '}
                                <sub>PP</sub>
                              </h6>
                            </div>
                            <div className='time_length mt-2'>
                              <i
                                className='fa fa-moon-o'
                                aria-hidden='true'
                              ></i>
                              Tour Length{' '}
                              <span className='tour_length'>
                                {item.time_duration} Night
                              </span>
                            </div>
                            <h6 className='text-center p-view-detail mt-2'>
                              <NavLink className='nav-link' to='/view-detail'>
                                View Details
                              </NavLink>
                            </h6>
                            <a
                                
                              className='btn mt-2 btn-primary select-styling search-btn1 form-control'
                            >
                              Book Now
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ))} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PackageDetailCard
