import React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInfoCircle,
    faStar,
  faGlobe,
  faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import { CustomerDomainName,ApiEndPoint,ActivityToken } from "../GlobalData/GlobalData";
function ActivityCard({newActivityListing}){
  const [isLoading, setIsLoading] = useState(false);
  var Domainpath=CustomerDomainName();
  var navigation=useNavigate();
const showDetail=(id)=>{
  navigation(`/activity_details/${id}`);
};
 
    return (
        <>
         <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
             {newActivityListing.map((item,index)=>(
                <div key={index} className='row'>
                  <div className='col-md-12 mb-4 uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme'> 
                    <div className='row parent_row' onClick={()=>showDetail(item.id)} style={{cursor:'pointer'}}>
                      <div className='col-md-4 item-from px-0'>
                        <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative">
                          <figure class="uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                            <span>
                              <span>
                                <div class="uitk-gallery-carousel">
                                  <div class="uitk-gallery-carousel-items ratio-21-9">
                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                      <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                        <div class="uitk-image-placeholder">
                                            <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                              <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                                <div class="uitk-image-placeholder">
                                                  <img alt="Room" class="uitk-image-media" src={Domainpath+'/public/images/activites/'+item.featured_image} />
                                                </div>
                                                <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                  <span class="is-visually-hidden">Room</span>
                                                </button>
                                              </figure>
                                            </div>
                                        </div>
                                      </figure>
                                    </div>
                                    <div class="uitk-gallery-carousel-paging-controls">
                                      <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                                        <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="prev-button-lodging-card-gallery-carousel-16249642-1-title">Show previous image for Swissôtel Al Maqam Makkah</title>
                                          <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                        </svg>
                                      </button>
                                      <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                                        <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="next-button-lodging-card-gallery-carousel-16249642-1-title">Show next image for Swissôtel Al Maqam Makkah</title>
                                          <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </span>
                          </figure>
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                          <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                            <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                              <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-rows uitk-layout-grid-display-grid uitk-layout-flex-item">
                                  <h3 class="uitk-heading uitk-heading-5 overflow-wrap uitk-layout-grid-item uitk-layout-grid-item-has-row-start" onClick={()=>showDetail(item.id)}> {item.title}</h3>
                                </div>
                              </div>
                              <div class="uitk-text uitk-text-spacing-half truncate-lines-2 uitk-type-300 uitk-text-default-theme" aria-hidden="false">
                                  <div class="uitk-text uitk-type-200 uitk-type-bold uitk-text-default-theme py-2">  
                                     {' '}<FontAwesomeIcon icon={faGlobe} />{' '} {item.location}
                                  </div>
                              </div>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x">
                                <div class="uitk-text uitk-type-200 uitk-text-default-theme">
                                  <div class='item-address' style={{ color: 'green' }}>
                                    <i class='awe-icon awe-icon-marker-2'>
                                      <FontAwesomeIcon icon={faCalendarCheck} />
                                    </i>{' '}
                                      {item.activity_date}{' '}
                                  </div>
                                </div>
                              </div>
                              <p className="my-3" style={{fontSize:'13px'}}>{item.activity_content}</p>
                            </div>
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                              <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                        <span class="uitk-badge-base-text" aria-hidden="true">8.6</span>
                                        <span class="is-visually-hidden">8.6 out of 10</span>
                                      </span>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                          <span class="is-visually-hidden">  
                                            <p className='card-star'>
                                              {item.starts_rating === '' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                Array(item.starts_rating )
                                                  .fill(0)
                                                  .map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))
                                              )}
                                              (Activity Hours : {item.duration})
                                            </p>
                                          </span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                          <span class="is-visually-hidden">(555 reviews)</span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                  <div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                      <div class="uitk-spacing uitk-spacing-padding-block-half">
                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">The price is 
                                           {item.currency_symbol} {item.sale_price}
                                        </div>
                                        <span aria-hidden="true">
                                          <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">
                                              {item.currency_symbol} {item.sale_price} 
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                      <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme"> 
                                        {item.currency_symbol} {item.sale_price} 
                                      </div>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                      <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a rel="noopener" data-stid="open-hotel-information" target="_blank" onClick={()=>showDetail(item.id)} class="uitk-card-link" style={{cursor:'pointer'}}>
                        <span class="is-visually-hidden">{item.title}</span>
                      </a>
                        {/* {hotelDetails[item.hotel_id] && (
                          <div class='item-address'>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faGlobe} />
                            </i>{' '}
                            {hotelDetails[item.hotel_id].details_data.address}{' '}
                          </div>
                        )} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
    )
}

export default ActivityCard;