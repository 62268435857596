import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import WithoutLayout from '../../Components/Layout/WithoutLayout'
import bgimage from '../../Images/Packages/kabapic.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import moment from 'moment'
import Select from 'react-select';
import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify'
import {
  CustomerDomainName,
  Hotelapitoken,
  ApiEndPoint,
  StripeClientSecret,
 
} from '../../Components/GlobalData/GlobalData'
import { Stripe } from 'stripe';
import { useStripe, useElements,CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { useSelector,useDispatch}  from 'react-redux'
import { PackageInvoiceData } from '../../Redux/Actions/actions'
import {
  faCalendarDays,
  faCheck,
  faHeadset,
  faAngleDown,
  faAngleRight,
  faWindowRestore,
  faCancel,
  faClock,
  faDollar,
  faHeadphones,
  faStar,
  faLock,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import PackageSearch from '../../Components/SearchBar/PackageSearch'
var StripePayment=0;
var StripeCurrency='';
function PackageCheckout () {
  const Dispatch=useDispatch();
  const navigation=useNavigate();
  var endpoint=ApiEndPoint();
  const [selectedImage, setSelectedImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [cardInfo, setCardInfo] = useState({
    name: '',
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryListnew, setCountryListnew] = useState([]);
  var Secretkey=StripeClientSecret();
  const stripe = new Stripe(Secretkey);
  const elements = useElements();
  const stripeInstance = useStripe();
  const [error, setError] = useState(null);
  const [personData, setPersonData] = useState({
    title: '',
    fname: '',
    lname: '',
    email: '',
    dob: '',
    gender: '',
    nationality: '',
    phno: '',
    pasportno: '',
    pasportexpiry: ''
  });
  const tourDetail = useSelector(state => state.hotels.viewtourdetail.tours)
  const Packagedetail = useSelector(state => state.hotels.packagedata)
  const accomodationdetail = JSON.parse(tourDetail.accomodation_details)
 
  const adultsCount1 = Packagedetail.total_adults;
  const childrenCount1 = Packagedetail.total_childs;
  const infantsCount1 = Packagedetail.total_Infants;
  const [countryList, setCountryList] = useState([]);
  const [showOtherData, setShowOtherData] = useState(false);
  const childsArray = Array.from({ length: childrenCount1 });
  const adultsArray = Array.from({ length: adultsCount1 - 1 });
  const infantsArray = Array.from({ length: infantsCount1 });
  const [adultsData, setAdultsData] = useState(
    Array(adultsCount1).fill({ type: 'adults' })
  );
  const [childrenData, setChildrenData] = useState(
    Array(childrenCount1).fill({ type: 'child' })
  );
  const [infantsData, setInfantsData] = useState(
    Array(infantsCount1).fill({ type: 'infant' })
  );
  useEffect(() => {
    fetchData();
    fetchDatanew();
  }, []);
  var url = CustomerDomainName();

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    console.log(selectedOption);
    setPersonData(prevdata => ({
      ...prevdata,
      nationality: selectedOption.value,
      phno:selectedOption.phoneCode
    }));
  };
  async function fetchDatanew () {
    Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
      .then(response => {
        const countryOptions = response?.data.map((country) => ({
            value:country.name.common,
            label: country.name.common,
            flag: country.flags.png,
            phoneCode: country.idd.root+country.idd.suffixes[0],
          }));
        setCountryListnew(countryOptions)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  };
  async function fetchData () {
    Axios.get(endpoint+'/api/countries/fetch')
      .then(response => {
        setCountryList(response.data.countries)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  };
  const handletitlechange = event => {
    setPersonData(prevdata => ({
      ...prevdata,
      title: event.target.value
    }))
  };

  const handledata = event => {
    const { name, value } = event.target
    setPersonData(prevdata => ({
      ...prevdata,
      [name]: value
    }))
  };
  const showotherData=()=>{
    setShowOtherData(!showOtherData);
  };
  // const handleImageSelect = event => {
  //   const file = event.target.files[0]
  //   const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif']
  //   if (file && allowedTypes.includes(file.type)) {
  //     if (file) {
  //       const reader = new FileReader()

  //       reader.onload = e => {
  //         setSelectedImage(e.target.result)
  //       }

  //       reader.readAsDataURL(file)
  //     }
  //   } else {
  //     toast.error('Please select a valid image file.', {
  //       position: toast.POSITION.TOP_RIGHT
  //     })
  //   }
  // }
  const BookPackage = async() => {
    
    var apitoken = Hotelapitoken()
    if(personData.title !==''&& personData.fname!=='' && personData.lname !=='' && personData.email !== '' && personData.pasportno !==''){
      setIsLoading(true);
      var status=await handlePayment();
      if(status.success===false){
        setIsLoading(false);
        return;
      };
      var leaduserdata = {
      _token: apitoken,
      lead_title: personData.title,
      name: personData.fname,
      lname: personData.lname,
      email: personData.email,
      passengerType: 'adults',
      country: personData.nationality,
      date_of_birth: personData.dob,
      phone: personData.phno,
      passport_lead: personData.pasportno,
      passport_exp_lead: personData.pasportexpiry,
      gender: personData.gender
    };
    var visa={
      "126":{
         "visa_actual_price":"66.67",
         "double_adult_visa_type":null,
         "double_adult_visa_persons":null,
         "visa_price_double_purc_rate":"300",
         "visa_price_double_exchange_rate":"4.5",
         "visa_actual_price_change":"66.67",
         "triple_adult_visa_type":null,
         "triple_adult_visa_persons":null,
         "visa_price_triple_purc_rate":"300",
         "visa_price_triple_exchange_rate":"4.5",
         "visa_actual_price_change_triple":"66.67",
         "quad_adult_visa_type":null,
         "quad_adult_visa_persons":null,
         "visa_price_quad_purc_rate":"300",
         "visa_price_quad_exchange_rate":"4.5",
         "visa_actual_price_change_quad":"66.67",
         "without_acc_adult_visa_type":null,
         "without_acc_adult_visa_persons":null,
         "visa_price_without_acc_purc_rate":"300",
         "visa_price_without_acc_exchange_rate":"4.5",
         "visa_actual_price_change_without_acc":"66.67",
         "without_acc_child_visa_type":null,
         "without_acc_child_visa_persons":null,
         "visa_price_without_acc_child_purc_rate":"300",
         "visa_price_without_acc_child_exchange_rate":"4.5",
         "visa_actual_price_change_without_acc_child":"66.67",
         "double_child_visa_type":null,
         "double_child_visa_persons":null,
         "visa_price_double_child_purc_rate":"300",
         "visa_price_double_child_exchange_rate":"4.5",
         "visa_actual_price_change_double_child":"66.67",
         "triple_child_visa_type":null,
         "triple_child_visa_persons":null,
         "visa_price_triple_child_purc_rate":"300",
         "visa_price_triple_child_exchange_rate":"4.5",
         "visa_actual_price_change_triple_child":"66.67",
         "quad_child_visa_type":null,
         "quad_child_visa_persons":null,
         "visa_price_quad_child_purc_rate":"300",
         "visa_price_quad_child_exchange_rate":"4.5",
         "visa_actual_price_change_quad_child":"66.67",
         "without_acc_infant_visa_type":null,
         "without_acc_infant_visa_persons":null,
         "visa_price_without_acc_infant_purc_rate":"300",
         "visa_price_without_acc_infant_exchange_rate":"4.5",
         "visa_actual_price_change_without_acc_infant":"66.67",
         "double_infant_visa_type":null,
         "double_infant_visa_persons":null,
         "visa_price_double_infant_purc_rate":"300",
         "visa_price_double_infant_exchange_rate":"4.5",
         "visa_actual_price_change_double_infant":"66.67",
         "triple_infant_visa_type":null,
         "triple_infant_visa_persons":null,
         "visa_price_triple_infant_purc_rate":"300",
         "visa_price_triple_infant_exchange_rate":"4.5",
         "visa_actual_price_change_triple_infant":"66.67",
         "quad_infant_visa_type":null,
         "quad_infant_visa_persons":null,
         "visa_price_quad_infant_purc_rate":"300",
         "visa_price_quad_infant_exchange_rate":"4.5",
         "visa_actual_price_change_quad_infant":"66.67"
      }
   };
   var leaddata=[];
   leaddata[0]=leaduserdata;
   var cartdata=[];
   var x=[];
   x[0]=Packagedetail;
   cartdata[0]=x;
   cartdata[1]='tour';
    var data = {
      'token': apitoken,
      'request_data': JSON.stringify(leaddata),
      'request_form': 'web',
      'adults':adultsData.length > 1 ? JSON.stringify(adultsData.map((adult, index) => ({
        token: apitoken,
        passengerType: adult.type,
        passengerName: adult.firstName,
        lname: adult.lastName,
        country: adult.nationality,
        date_of_birth: adult.dob,
        passport_lead: adult.passportno,
        passport_exp_lead: adult.passportexpiry,
        gender: adult.gender,
      }))) : null,
      'childs': childrenData.length>1 ? JSON.stringify( childrenData.map((adult, index) => ({
        token: apitoken,
        passengerType: adult.type,
        passengerName: adult.firstName,
        lname: adult.lastName,
        country: adult.nationality  ,
        date_of_birth: adult.dob  ,
        passport_lead: adult.passportno   ,
        passport_exp_lead: adult.passportexpiry     ,
        gender: adult.gender     ,
      }))) : null,
      'infants':infantsData.length>1 ? JSON.stringify( infantsData.map((adult, index) => ({
        token: apitoken,
        passengerType: adult.type,
        passengerName: adult.firstName,
        lname: adult.lastName,
        country: adult.nationality  ,
        date_of_birth: adult.dob  ,
        passport_lead: adult.passportno   ,
        passport_exp_lead: adult.passportexpiry     ,
        gender: adult.gender     ,
      }))) : null,
      'cart_data':JSON.stringify(cartdata),
      'cart_visa' : JSON.stringify(visa),
      'booking_person':'user',
      'stripe_payment_response':status.data

    };
   
    try {
      const response = await Axios.post(
        endpoint+'/api/save_booking_react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }

      )

      // console.log(response);
      setIsLoading(false);
      var id=response.data.invoice_id;
      navigation(`/package_invoice/${id}`, {
        state: {id}
      });
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error)
    }
  }else{
    setIsLoading(false);
    toast.error('Please Fill Lead Passenger all the details. ', {
      position: toast.POSITION.TOP_RIGHT
    })
  }
  };

  // const invoicedata=async(id)=>{
  //   var tkn=Hotelapitoken();
  //   var data={
  //       'token':tkn,
  //       'booking_id':'AHT6731893'
  //   }
  //   try {
  //       const response = await Axios.post(
  //         endpoint+'/api/invoice_data_react',
  //         data,
  //         {
  //           headers: {
  //             'Access-Control-Allow-Origin': '*',
  //             // Required for CORS support to work
  //             'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
  //             'Access-Control-Allow-Headers':
  //               'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
  //             // "Access-Control-Allow-Methods": "POST, OPTIONS"
  //           }
  //         }
  
  //       )
  //       Dispatch(PackageInvoiceData(response.data));

  //        navigation(`/package_invoice/${id}`, {
  //         state: {id}
  //       });
       
  //     } catch (error) {
  //       // Handle errors here
  //       console.error('Error:', error)
  //     }
  // };
  const handlePayment = async () => {
    if(cardInfo.name===""){
      setError("Enter Card Holder Name.");
      return { success: false};
    };
    const response = await stripe.paymentIntents.create({
      amount: Number(StripePayment)*100, // Amount in cents
      currency: 'GBP',
      description: 'Example payment',
      payment_method_types: ['card'],
    });

    var Client_Secret= response.client_secret;
    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(Client_Secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name:cardInfo.name,
          },
        },
      });

      if (error) {
         setError(error.message);
        console.error(error);
        return { success: false};
      } else {
        return { success: true,data:paymentIntent};
      }
    } catch (error) {
      console.error('Error:', error);
      return { success: false};
    }
  };

  const handlecarddetail=(e)=>{
    const {value,name}=e.target;
    setCardInfo(prevcardinfo=>({...prevcardinfo,
    [name]:value,
    }));
  };

  const otherGuestInfo = (e, guestIndex, isChild) => {
    const selectedValue = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          gender: selectedValue
        }
        return updatedInfants
      })
    }else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          gender: selectedValue
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          gender: selectedValue
        }
        return updatedGuests
      })
    }
  }
  const otherGuestFirstName = (e, guestIndex, isChild) => {
    // Handle the first name input and update the state
    const firstName = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          firstName: firstName
        }
        return updatedInfants
      })
    }else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          firstName: firstName
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          firstName: firstName
        }
        return updatedGuests
      })
    }
  };
  const otherGuestLastName = (e, guestIndex, isChild) => {
    // Handle the last name input and update the state
    const lastName = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          lastName: lastName
        }
        return updatedInfants
      })
    } else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          lastName: lastName
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          lastName: lastName
        }
        return updatedGuests
      })
    }
  }
  const otherGuestNationality = (e, guestIndex, isChild) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          nationality: newvalue
        }
        return updatedInfants
      })
    }else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          nationality: newvalue
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          nationality: newvalue
        }
        return updatedGuests
      })
    }
  }
  const otherGuestdob = (e, guestIndex, isChild) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          dob: newvalue
        }
        return updatedInfants
      })
    } else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          dob: newvalue
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          dob: newvalue
        }
        return updatedGuests
      })
    }
  }
  const otherGuestpassportnumber = (e, guestIndex, isChild) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          passportno: newvalue
        }
        return updatedInfants
      })
    }else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          passportno: newvalue
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          passportno: newvalue
        }
        return updatedGuests
      })
    }
  }
  const otherGuestpassportexpiry = (e, guestIndex, isChild) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value
    if(isChild==2){
      setInfantsData(prevInfants => {
        const updatedInfants = [...prevInfants]
        updatedInfants[guestIndex] = {
          ...updatedInfants[guestIndex],
          passportexpiry: newvalue
        }
        return updatedInfants
      })
    }else if (isChild) {
      setChildrenData(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          passportexpiry: newvalue
        }
        return updatedChilds
      })
    } else {
      setAdultsData(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          passportexpiry: newvalue
        }
        return updatedGuests
      })
    }
  };

  return (
    <>
    {isLoading && (<Loading/>)}
      <WithoutLayout>
        <ToastContainer />
        <div className='container mt-2'>
           <h1 class="section-header-main" style={{fontWeight:'30px'}}>Secure booking</h1>
          {/* <div className='row'>
            <div className='col-md-8'>
              <div class='checkout-note'>
                <p>
                  <i class='fa-regular fa-calendar-days'>
                    <FontAwesomeIcon icon={faHeadset} />{' '}
                  </i>{' '}
                  FREE CUSTOMER SERVICE AVAILABLE FOR 365/24/7{' '}
                </p>
              </div>
            </div>
            <div className='col-md-4'>
              <div class='checkout-note'>
                <p>
                  <i class='fa-regular fa-calendar-days'>
                    <FontAwesomeIcon icon={faCalendarDays} />{' '}
                  </i>{' '}
                  We have limited availability at this price - book now!{' '}
                </p>
              </div>
            </div>
          </div> */}
          <div className='row mt-3'>
            <div className='col-lg-8 margin-checkout'>
              <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 mb-3 rounded-0">
                <div data-testid="one-key-banner-1" id="one-key-banner-1" className='px-0 rounded-0'>
                    <div className='rounded-0'>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme rounded-0 chech-theme" data-stid="one-key-message-card">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three rounded-0">
                                <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                    <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                        <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                    </div>
                                </div>
                                <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                            <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                {/* <p class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme"> */}
                                                  <span class="one-key-loyalty-text" data-user-type="guest">Sign in or create an account to earn <strong><span data-price-update="oneKeyLoyaltyEarnModulePoints">$15.53</span></strong> in OneKeyCash™ after this trip.</span>
                                                {/* </p> */}
                                            </div>
                                        </div>
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                            <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                              <span class="universal-login-forward-icon" data-user-type="guest">
                                                  <span class="icon icon-toggle90" aria-hidden="true" style={{color:'white', fontSize:'20px'}}><FontAwesomeIcon icon={faAngleRight} /></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class=''>
                <div className='hotel-checkout-shadow p-2 mb-2'>
                  {accomodationdetail.map((item, index) => (
                    <div key={index} className='row'>
                      <div className='col-lg-3 item-from '>
                        <div>
                          <img
                            class='tour-img'
                            src={
                              url
                              +'public/uploads/package_imgs/' +
                              item.accomodation_image[0]
                            }
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='col-lg-9 '>
                        <h5 class='card-title mt-2'>
                          <a class='p-card-title'>{item.acc_hotel_name}</a>
                        </h5>
                        <div className='row  mt-2 mb-2 p-2'>
                          <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                            <div class='single-tour-feature d-flex align-items-center mb-3'>
                              <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                <i class='fas fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>
                              </div>
                              <div class='single-feature-titles'>
                                <p
                                  style={{ fontSize: '13px' }}
                                  class='title fw-bold'
                                >
                                  Check in
                                </p>
                                <p
                                  className='mt-0'
                                  style={{ fontSize: '12px' }}
                                  class='title '
                                >
                                  {moment(item.acc_check_in).format(
                                    'DD-MM-YYYY'
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                            <div class='single-tour-feature d-flex align-items-center mb-2'>
                              <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                <i class='fas fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>
                              </div>
                              <div class='single-feature-titles'>
                                <p
                                  style={{ fontSize: '13px' }}
                                  class='title fw-bold'
                                >
                                  Check Out
                                </p>
                                <p
                                  className='mt-0'
                                  style={{ fontSize: '12px' }}
                                  class='title '
                                >
                                  {moment(item.acc_check_out).format(
                                    'DD-MM-YYYY'
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                            <div class='single-tour-feature d-flex align-items-center mb-2'>
                              <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                <i class='fas fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>
                              </div>
                              <div class='single-feature-titles'>
                                <p
                                  style={{ fontSize: '13px' }}
                                  class='title fw-bold'
                                >
                                  Nights
                                </p>
                                <p
                                  className='mt-0'
                                  style={{ fontSize: '12px' }}
                                  class='title '
                                >
                                  {item.acc_no_of_nightst}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                            <div class='single-tour-feature d-flex align-items-center mb-2'>
                              <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                <i class='fas fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>
                              </div>
                              <div class='single-feature-titles'>
                                <p
                                  style={{ fontSize: '13px' }}
                                  class='title fw-bold'
                                >
                                  Transport
                                </p>
                                <p
                                  className='mt-0'
                                  style={{ fontSize: '12px' }}
                                  class='title '
                                >
                                  Included
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className=' col-sm-3 col-6 col-md-4 col-lg-4 mt-1'>
                            <div class='single-tour-feature d-flex align-items-center mb-2'>
                              <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                <i class='fas fa-check'>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>
                              </div>
                              <div class='single-feature-titles'>
                                <p
                                  style={{ fontSize: '13px' }}
                                  class='title fw-bold'
                                >
                                  Visa
                                </p>
                                <p
                                  className='mt-0'
                                  style={{ fontSize: '12px' }}
                                  class='title '
                                >
                                  Included
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='hotel-checkout-shadow p-3'>
                  <div className='row'>
                    <div>
                      <h4 style={{color:'black'}}>Let us know who you are?</h4>
                    </div>
                    <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Title</label>
                      <select
                        value={personData.title}
                        onChange={handletitlechange}
                        id='inputState'
                        name='title'
                        class='form-control form-select select-styling mt-2'
                      >
                        <option selected>Select Title</option>
                        <option value='Mr.'>Mr.</option>
                        <option value='Mrs.'>Mrs.</option>
                      </select>
                    </div>
                    <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>First Name</label>
                      <input
                        type='text'
                        value={personData.fname}
                        onChange={handledata}
                        class='form-control mt-2'
                        name='fname'
                        placeholder='First Name'
                      />
                    </div>
                    <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Last Name</label>
                      <input
                        value={personData.lname}
                        onChange={handledata}
                        type='text'
                        class='form-control mt-2'
                        placeholder='Last Name'
                        name='lname'
                      />
                    </div>
                    <div class='form-group col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Email</label>
                      <input
                        value={personData.email}
                        onChange={handledata}
                        type='email'
                        class='form-control mt-2'
                        name='email'
                        placeholder='Email'
                      />
                    </div>
                    <div class='form-group col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Date Of Birth</label>
                      <input
                        value={personData.dob}
                        onChange={handledata}
                        type='date'
                        class='form-control mt-2'
                        name='dob'
                      />
                    </div>
                    <div className='form-group col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Gender:</label>
                      <select
                        value={personData.gender}
                        name='gender'
                        onChange={handledata}
                        class='form-control  mt-2 form-select select-styling'
                      >
                        <option selected>Select Gender</option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                      </select>
                    </div>
                    <div class='form-group field-icon-wrap  col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Nationality</label>
                      <Select
                                        options={countryListnew}
                                        isSearchable={true}
                                        className="mt-2"
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        getOptionLabel={(option) => (
                                            <div>
                                            <img
                                                src={option.flag}
                                                alt={option.label}
                                                style={{ width: '20px', marginRight: '8px' }}
                                            />
                                            {option.label} ({option.phoneCode})
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        />
                    </div>
                    <div class='form-group col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Phone Number</label>
                      <input
                        type='text mt-2'
                        class='form-control mt-2'
                        value={personData.phno}
                        onChange={handledata}
                        name='phno'
                        placeholder='Phone Number'
                      />
                    </div>
                    <div class='form-group col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Passport Number</label>
                      <input
                        type='text'
                        value={personData.pasportno}
                        onChange={handledata}
                        class='form-control mt-2 '
                        name='pasportno'
                        placeholder='Passport Number'
                      />
                    </div>
                    <div class='form-group col-md-4 col-sm-6 mt-2'>
                      <label className='fw-bold'>Passport Expiry</label>
                      <input
                        type='date'
                        value={personData.pasportexpiry}
                        onChange={handledata}
                        class='form-control mt-2'
                        name='pasportexpiry'
                      />
                    </div>
                    {/* <div className='form-group col-md-8 col-sm-6 mt-2'>
                      <label className='fw-bold'>Upload Passport</label>
                      <input
                        type='file'
                        accept='image/*'
                        class='form-control mt-2'
                        placeholder='Upload Passport'
                        onChange={handleImageSelect}
                      />
                    </div> */}
                    {/* <div>
                      {selectedImage && (
                        <div className='mt-2 passport-preview'>
                          <label className='fw-bold'>Passport Preview:</label>
                          <img
                            src={selectedImage}
                            alt='Selected'
                            style={{ maxWidth: '100%' }}
                          />
                        </div>
                      )}
                    </div> */}

                    <div className='mt-2 text-end'>
                      {/* <button
                      onClick={BookPackage}
                        style={{ width: '30%' }}
                        class='btn btn-primary btn-block select-styling search-btn1'
                      >
                        Book Package
                      </button> */}
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className='hotel-checkout-shadow mt-4 p-3'>
                <div className='row mt-2'>
                  <div className='d-flex justify-content-between'>
                    <h4 style={{color:'black'}}>Other Passenger Detail (Optional)</h4>
                    <span onClick={showotherData} className='mt-auto currency-modal mb-auto'><FontAwesomeIcon icon={faAngleDown}/></span>
                  </div>
                    {showOtherData && (
                    <div>
                    {adultsArray.map((_, index) => (
                      <div className='row' key={index}>
                        <div className='mt-4'>
                          <h5>Adult {index + 1} Detail</h5>
                        </div>
                        <input
                          type='hidden'
                          name={`adultType${index}`}
                          value='adults'
                        />
                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>First Name</label>
                          <input
                            type='text'
                            value={adultsData.firstname}
                            name='firstname'
                            placeholder='First Name'
                            onChange={e => otherGuestFirstName(e, index)}
                            class='form-control mt-2'
                          />
                        </div>
                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Last Name</label>
                          <input
                            type='text'
                            class='form-control mt-2'
                            value={adultsData.lastname}
                            name='lastname'
                            placeholder='Last Name'
                            onChange={e => otherGuestLastName(e, index)}
                          />
                        </div>

                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Date Of Birth</label>
                          <input
                            type='date'
                            class='form-control mt-2'
                            value={adultsData.dob}
                            name='dob'
                            onChange={e => otherGuestdob(e, index)}
                            placeholder='First Name'
                          />
                        </div>
                        <div className='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Gender:</label>
                          <select
                            value={adultsData.gender}
                            name='gender'
                            onChange={e => otherGuestInfo(e, index)}
                            class='form-control  mt-2 form-select select-styling'
                          >
                            <option selected>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='Female'>Female</option>
                          </select>
                        </div>
                        <div class='form-group field-icon-wrap  col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Nationality</label>
                          <select
                            value={adultsData.nationality} // Set the selected value from the state
                            onChange={e => otherGuestNationality(e, index)}
                            className='form-control  mt-2 form-select select-styling'
                            aria-label='Default select example'
                          >
                            <option selected>Select Nationality</option>
                            {countryList.map(item => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div class='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Passport Number</label>
                          <input
                            type='text'
                            class='form-control  mt-2'
                            value={adultsData.passportno}
                            name='passportno'
                            onChange={e => otherGuestpassportnumber(e, index)}
                            placeholder='Passport Number'
                          />
                        </div>
                        <div class='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Passport Expiry</label>
                          <input
                            type='date'
                            class='form-control mt-2'
                            value={adultsData.passportexpiry}
                            name='passportexpiry'
                            onChange={e => otherGuestpassportexpiry(e, index)}
                          />
                        </div>
                      </div>
                    ))}
                    {childsArray.map((_, index) => (
                      <div className='row' key={index}>
                        <div className='mt-4'>
                          <h5>Child {index + 1} Detail</h5>
                        </div>
                        <input
                          type='hidden'
                          name={`adultType${index}`}
                          value='adults'
                        />
                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>First Name</label>
                          <input
                            type='text'
                            value={childrenData.firstname}
                            name='firstname'
                            placeholder='First Name'
                            onChange={e => otherGuestFirstName(e, index,true)}
                            class='form-control mt-2'
                          />
                        </div>
                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Last Name</label>
                          <input
                            type='text'
                            class='form-control mt-2'
                            value={childrenData.lastname}
                            name='lastname'
                            placeholder='Last Name'
                            onChange={e => otherGuestLastName(e, index,true)}
                          />
                        </div>

                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Date Of Birth</label>
                          <input
                            type='date'
                            class='form-control mt-2'
                            value={childrenData.dob}
                            name='dob'
                            onChange={e => otherGuestdob(e, index,true)}
                            placeholder='First Name'
                          />
                        </div>
                        <div className='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Gender:</label>
                          <select
                            value={childrenData.gender}
                            name='gender'
                            onChange={e => otherGuestInfo(e, index,true)}
                            class='form-control  mt-2 form-select select-styling'
                          >
                            <option selected>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='Female'>Female</option>
                          </select>
                        </div>
                        <div class='form-group field-icon-wrap  col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Nationality</label>
                          <select
                            value={childrenData.nationality} // Set the selected value from the state
                            onChange={e => otherGuestNationality(e, index,true)}
                            className='form-control  mt-2 form-select select-styling'
                            aria-label='Default select example'
                          >
                            <option selected>Select Nationality</option>
                            {countryList.map(item => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div class='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Passport Number</label>
                          <input
                            type='text'
                            class='form-control  mt-2'
                            value={childrenData.passportno}
                            name='passportno'
                            onChange={e => otherGuestpassportnumber(e, index,true)}
                            placeholder='Passport Number'
                          />
                        </div>
                        <div class='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Passport Expiry</label>
                          <input
                            type='date'
                            class='form-control mt-2'
                            value={childrenData.passportexpiry}
                            name='passportexpiry'
                            onChange={e => otherGuestpassportexpiry(e, index,true)}
                          />
                        </div>
                      </div>
                    ))}
                     {infantsArray.map((_, index) => (
                      <div className='row' key={index}>
                        <div className='mt-4'>
                          <h5>Infant {index + 1} Detail</h5>
                        </div>
                        <input
                          type='hidden'
                          name={`adultType${index}`}
                          value='adults'
                        />
                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>First Name</label>
                          <input
                            type='text'
                            value={infantsData.firstname}
                            name='firstname'
                            placeholder='First Name'
                            onChange={e => otherGuestFirstName(e, index,2)}
                            class='form-control mt-2'
                          />
                        </div>
                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Last Name</label>
                          <input
                            type='text'
                            class='form-control mt-2'
                            value={infantsData.lastname}
                            name='lastname'
                            placeholder='Last Name'
                            onChange={e => otherGuestLastName(e, index,2)}
                          />
                        </div>

                        <div class='form-group mt-4 col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Date Of Birth</label>
                          <input
                            type='date'
                            class='form-control mt-2'
                            value={infantsData.dob}
                            name='dob'
                            onChange={e => otherGuestdob(e, index,2)}
                            placeholder='First Name'
                          />
                        </div>
                        <div className='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Gender:</label>
                          <select
                            value={infantsData.gender}
                            name='gender'
                            onChange={e => otherGuestInfo(e, index,2)}
                            class='form-control form-select select-styling'
                          >
                            <option selected>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='Female'>Female</option>
                          </select>
                        </div>
                        <div class='form-group field-icon-wrap  col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Nationality</label>
                          <select
                            value={infantsData.nationality} // Set the selected value from the state
                            onChange={e => otherGuestNationality(e, index,2)}
                            className='form-control form-select select-styling'
                            aria-label='Default select example'
                          >
                            <option selected>Select Nationality</option>
                            {countryList.map(item => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div class='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Passport Number</label>
                          <input
                            type='text'
                            class='form-control '
                            value={infantsData.passportno}
                            name='passportno'
                            onChange={e => otherGuestpassportnumber(e, index,2)}
                            placeholder='Passport Number'
                          />
                        </div>
                        <div class='form-group col-md-4 col-sm-6 mt-2'>
                          <label className='fw-bold'>Passport Expiry</label>
                          <input
                            type='date'
                            class='form-control mt-2'
                            value={infantsData.passportexpiry}
                            name='passportexpiry'
                            onChange={e => otherGuestpassportexpiry(e, index,2)}
                          />
                        </div>
                      </div>
                    ))}
                    </div>
                    )}
                </div>
              </div>
              <article id="insurance" class="segment no-target insurance-module checkout-hotel-detail mt-3">
                <div class="global-insurance-container-v2 ins-grid-c1-15" id="hotelInsuranceContainer">
                    <div class="insurance-header remove-margin">
                        <div class="align-urgency cf">
                            <div class="shield-header-with-recommend-text">
                                <h2 class="benefits-heading">        
                                  <span class="insurance-header-title-padding">Protect your stay</span>
                                  <span class="badge badge-success badge-notification  insurance-badge insurance-badge-side-title bold-700 insurance-badge-line-height"><span class="insurtech-badge-text">Recommended</span></span>
                                </h2>
                            </div>
                        </div>
                    </div>      
                    <div id="US_HOTEL_GENERALI" data-ftl-template="US_HOTEL_GENERALI_RETRO" class="insurance-template insurance-offering ins-grid-c1-15" data-lob="hotel">
                        <div class="ins-wrapper remove-margin">
                          <div class="global-hotel template-body ins-grid-c1-15">
                            <form id="insurance-form" data-lob="hotel">
                                <fieldset class="fieldset-margin insurance-fields ins-grid-c1-15">
                                  <div class="insurance-benefit-solicitation">
                                    <div class="enhanceBenefitListTemplate ins-grid-c1-13 " data-template="benefit_popup_graphic_template">
                                      <div id="renderEnhanceBenefitList" data-stid="renderEnhanceBenefitList" class="benefits-container ">
                                          <ul data-stid="enhance-benefit-list" class="enhance-benefit type-300" title="Why should you protect your stay?">
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                          <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-clear.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Cancellation and interruption protection up to $100,000 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                      <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-skip_the_line.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Emergency assistance and transportation up to $200,000 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                      <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-lob_hotels.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Material misrepresentation of advertised property up to $500 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                      <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-timelapse.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Expenses due to travel delay up to $15,000 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                    <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-physical_activity_level.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Medical expenses up to $200,000 per plan</div>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <section id="socialProofingTemplate" class="socialProofingTemplate social-proof " data-template="placard" data-product="hotel" data-rfrr="SOCIAL_PROOF.PLACARD" data-track="ins-impression">
                                    <div class="placardTemplate ">
                                        <span class="placard-graphic" aria-hidden="true">                       
                                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6799 8C11.6799 9.66 10.2664 11 8.50213 11C6.73787 11 5.31371 9.66 5.31371 8C5.31371 6.34 6.73787 5 8.50213 5C10.2664 5 11.6799 6.34 11.6799 8ZM20.1823 8C20.1823 9.66 18.7688 11 17.0046 11C15.2403 11 13.8161 9.66 13.8161 8C13.8161 6.34 15.2403 5 17.0046 5C18.7688 5 20.1823 6.34 20.1823 8ZM8.50213 13C6.02579 13 1.0625 14.17 1.0625 16.5V19H15.9418V16.5C15.9418 14.17 10.9785 13 8.50213 13ZM15.9736 13.05C16.3456 13.02 16.6963 13 17.0046 13C19.4809 13 24.4442 14.17 24.4442 16.5V19H18.0674V16.5C18.0674 15.02 17.2065 13.89 15.9736 13.05Z" fill="#3D65D1"></path>
                                          </svg>
                                        </span>
                                        <div class="placard-content"> <p><b>27000</b><b>+</b> travelers protected their stay on Expedia last week.</p></div>
                                    </div>
                                  </section>
                                  <div class="insurance-selection-container retro">
                                      <span class="label required insReqMessage p-t-0" aria-hidden="true">Select an option to continue</span>
                                      <div class="one-plan-yes-highlight remove-yes-collapse">
                                          <div class="one-plan-yes-label">
                                              <div class="one-plan-CTA">
                                                  <label class="check insurance-product margin-adjustment" for="yes_insurance" data-cko-click="addGlobalInsuranceV2" data-piid="30010" data-insurancetypeid="30010" data-label="Yes, I want to add protection to my stay.">
                                                    <input type="radio" id="yes_insurance" name="insurance_piid" value="Generali US Hotel International" class="  cko-field-validate loggedForcedChoice" aria-label="Yes, I want to add protection to my stay." 
                                                        data-custom-rules="insuranceForcedChoiceValidate" data-product-type="hotel" />
                                                    <span class="inline-label">
                                                      <span class="yes-text yes-cta-text-header" aria-hidden="true">Yes, I want to add protection to my stay.</span>
                                                    </span>
                                                  </label>
                                              </div>
                                              <div id="one-plan-price" class="insurance-price" onclick="Checkout.GlobalInsuranceV2.Metrics.viewGlobalCalendarToolTipV2(Checkout.Model.productType)">
                                                  <div class="price type-600" aria-hidden="true">  $44.81</div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="insurance-offering decline-offering remove-no-collapse">
                                          <div class="no-highlight-slim">
                                            <label class="check decline" for="no_insurance" data-cko-click="removeGlobalInsuranceV2" data-piid="no_insurance" data-label="No, I'm willing to risk my $1,433.83 stay booking. I understand by declining this coverage that I may be responsible for certain cancellation fees and delay expenses personally or through alternate coverage.">
                                              <input type="radio" id="no_insurance" name="insurance_piid" value="none" class="  gb-whitelist loggedForcedChoice" aria-label="No, I'm willing to risk my $1,433.83 stay booking. I understand by declining this coverage that I may be responsible for certain cancellation fees and delay expenses personally or through alternate coverage." data-custom-rules="insuranceForcedChoiceValidate" data-product-type="hotel" data-ins-product-internal-name="Generali US Hotel International" data-product="hotel"/>
                                              <span class="inline-label"><span class="no-text" aria-hidden="true">No, I'm willing to risk my $1,433.83 stay booking. I understand by declining this coverage that I may be responsible for certain cancellation fees and delay expenses personally or through alternate coverage.</span></span>
                                            </label>
                                          </div>
                                      </div>
                                  </div>
                                </fieldset>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
              </article>
              <article class="segment no-target checkout-hotel-detail mt-3">
                <div class="cancellation-policies">
                  <h2 class="checkout-title-visual-alignment">Cancellation policy    </h2>
                  <ul class="rr-bullets rules-list" style={{listStyle:'circle',marginLeft:'20px'}}>
                    <div class="important-information-rr-cancellation-div">
                        <li>{Packagedetail.cancellation_policy}</li>
                    </div>
                  </ul>
                </div>
              </article>
              <article id="complete" class="segment no-target review-and-book checkout-hotel-detail">
                <div id="fxmp" class="hidden">
                    <p class="fx-header">
                        <strong>The following currencies are available for your booking:</strong>
                    </p>
                    <fieldset class="fx-fields">
                        <label class="check">
                            <input type="radio" class="fx-poscurrency gb-whitelist" name="fx_selected" value="false" data-cko-change="trackFX"/>
                            <span class="inline-label fx-label">
                              Pay in <span class="fx-code fx-poscurrency-code"></span> - <span class="fx-value fx-poscurrency-value"></span>            
                            </span>
                        </label>
                        <label class="check">
                            <input type="radio" class="fx-cccurrency gb-whitelist" name="fx_selected" value="true" checked="checked" data-cko-change="trackFX" />
                            <span class="inline-label fx-label">Pay in <span class="fx-code fx-cccurrency-code"></span> - <span class="fx-value fx-cccurrency-value"></span>            </span>
                        </label>
                    </fieldset>
                </div>
                <div id="important-information" class="important-information-rr-cancellation">
                    <h2 class="checkout-title-visual-alignment">Important information        </h2>
                    <div class="rr-cancellation-div">
                        <ul class="rr-bullets rules-list" style={{listStyle:'circle',marginLeft:'20px'}}>
                          <li>There is no front desk at this property. The host will greet guests on arrival. </li>
                        </ul>
                    </div>
                </div>
                <div id="booking-summary">
                    {/* <hr class="booking-summary-separator" aria-hidden="true"/>
                    <div class="duration">
                        <div class="duration-check-in">
                            <span class="check-in">Check-in:</span>
                            <span class="date-range">{moment(checkoutdetail?.checkIn).format('DD-MM-YYYY')}</span>
                        </div>
                        <div class="duration-check-out">
                            <span class="check-out">Check-out:</span>
                            <span class="date-range total-night-stay-desktop">{moment(checkoutdetail?.checkOut).format('DD-MM-YYYY')} ({daysBetween}-night stay)</span>
                        </div>
                    </div> */}
                    <hr class="booking-summary-separator" aria-hidden="true"/>
                </div>
                <div id="rules-and-restrictions" class="legal-consent page-2  consent-margin">
                    <div class="implicit-consent">
                      By clicking on the button below, I acknowledge that I have reviewed the 
                      <a href="/privacy?rfrr=HOT.CKO.Privacy" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.PRIVACY">Privacy Statement 
                      <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and  
                      <a href="https://travel.state.gov/content/passports/en/alertswarnings.html" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOT.CKO.TravelAdvice"> Government Travel Advice 
                      <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and have reviewed and accept the 
                      <a href="/Checkout/V1/HotelRulesAndRestrictions?tripid=d3ea7a5a-949a-5162-b4f0-022b5c7fe0a2&amp;rfrr=HOT.CKO.Rules&amp;np=1" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.RULES">Rules &amp; Restrictions 
                      <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and <a href="/terms?rfrr=HOT.CKO.Terms" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.TERMS">Terms of Use 
                      <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a>.        
                    </div>
                </div>
               
                <div class=' p-3'>
                  <div class='row'>
                    <div> <h4 style={{color:'black'}}>Payment Method</h4> </div>
                    <div class='section-tab check-mark-tab text-center mt-3 pb-4'>
                      <ul class='nav' id='myTab' role='tablist'>
                        <li class='nav-item'>
                          <i class='la la-check icon-element'></i>
                          <img
                            src='https://haramaynhotels.com/public/assets/images/payment-img.png'
                            alt=''
                          />
                        </li>
                      </ul>
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label htmlFor="cardNumber" className="form-label">Card Holder Name</label>
                      <input
                        type='text'
                        class='form-control card-holder-name'
                        name='name'
                      value={cardInfo.name}
                      onChange={handlecarddetail}
                        placeholder='Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label htmlFor="cardNumber" className="form-label">Card number</label>
                      <CardNumberElement className="form-control h-50" id="cardNumber" />
                    </div>
                    <div class='form-group mt-3 col-md-6'>
                      <label htmlFor="expiryDate" className="form-label">Expiration date</label>
                      <CardExpiryElement className="form-control h-50" id="expiryDate" />
                    </div>
                    <div class='form-group mt-3 col-md-6'>
                      <label htmlFor="cvc" className="form-label">CVC</label>
                      <CardCvcElement className="form-control h-50" id="cvc" />
                    </div>
                  </div>
                  {error && (
                  <div style={{color:"red"}}  className="error mt-4" role="alert">
                    {error}
                  </div>
                  )}
                </div>
             
                <button type="button" onClick={BookPackage} class="btn-primary btn-action complete-booking-cta booking-button">
                  <span class="btn-label" >Complete Booking</span>
                </button>
                <div class="security-message" aria-hidden="true">
                  <span class="icon icon-lock" aria-hidden="true"><FontAwesomeIcon icon={faLock} /></span>       
                  <span class="text1"> We use secure transmission and encrypted storage to protect your personal information.</span>
                  <span class="page-2 visa-mandate-message text1">
                    Payments are processed in the U.S. except where the travel provider (hotel / airline etc) processes your payment outside the U.S., in which case your card issuer may charge a foreign transaction fee.        
                  </span>
                </div>
              </article>
            </div>
            <div className='col-lg-4 order-first order-md-last'>
              <div className='checkout-hotel-detail'>
                <div className='tour_booking_amount_area'>
                  <div className='Hotel-img'>
                    <div class='card-img'>
                      <a href='hotel-single.html' class='d-block rounded-1'>
                        <img src={bgimage} alt='tour-img' className='rounded-1' />
                      </a>
                    </div>
                    <article id="price-summary-title" class="segment no-target">
                      <div class="price-summary-title">Adult details:</div>
                    </article>
                    <div className='card-body p-3'>
                      <ul class='list-items  list-items-2 mt-2 py-2'>
                        {Packagedetail.without_acc_adults !== '' &&
                          Packagedetail.without_acc_adults !== '0' && (
                            <li className='mt-2'>
                              <span>Adult Price :</span>
                              {Packagedetail.without_acc_adults} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.without_acc_adult_price}
                            </li>
                          )}
                        {Packagedetail.double_adults !== '' &&
                          Packagedetail.double_adults !== '0' && (
                            <li className='mt-2'>
                              <span>Adult Double Price :</span>
                              {Packagedetail.double_adults} X{' '}
                              {Packagedetail.currency} {Packagedetail.sharing2}
                            </li>
                          )}
                        {Packagedetail.triple_adults !== '' &&
                          Packagedetail.triple_adults !== '0' && (
                            <li className='mt-2'>
                              <span>Adult Triple Price :</span>
                              {Packagedetail.triple_adults} X{' '}
                              {Packagedetail.currency} {Packagedetail.sharing3}
                            </li>
                          )}
                        {Packagedetail.quad_adults !== '' &&
                          Packagedetail.quad_adults !== '0' && (
                            <li className='mt-2'>
                              <span>Adult Quad Price :</span>
                              {Packagedetail.quad_adults} X{' '}
                              {Packagedetail.currency} {Packagedetail.sharing4}
                            </li>
                          )}
                      </ul>
                      {(Packagedetail.children !== '' || Packagedetail.double_childs !== '' || Packagedetail.triple_childs !== '' || Packagedetail.quad_childs !== '') && (
                        <div>
                          <div className='border-line'></div>
                          <div className='mt-2'>
                            <h4 className='card-title'>Child Detail:</h4>
                          </div>
                        </div>
                      )}
                      <ul class='list-items mt-2 list-items-2 py-2'>
                        {Packagedetail.children !== '' &&
                          Packagedetail.children !== '0' && (
                            <li className='mt-2'>
                              <span>Child Price :</span>
                              {Packagedetail.children} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.child_price}
                            </li>
                          )}
                        {Packagedetail.double_childs !== '' &&
                          Packagedetail.double_childs !== '0' && (
                            <li className='mt-2'>
                              <span>Child Double Price :</span>
                              {Packagedetail.double_childs} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.double_child_price}
                            </li>
                          )}
                        {Packagedetail.triple_childs !== '' &&
                          Packagedetail.triple_childs !== '0' && (
                            <li className='mt-2'>
                              <span>Child Triple Price :</span>
                              {Packagedetail.triple_childs} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.triple_child_price}
                            </li>
                          )}
                        {Packagedetail.quad_childs !== '' &&
                          Packagedetail.quad_childs !== '0' && (
                            <li className='mt-2'>
                              <span>Child Quad Price :</span>
                              {Packagedetail.quad_childs} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.quad_child_price}
                            </li>
                          )}
                      </ul>
                      {(Packagedetail.infants !== '' || Packagedetail.double_infant !== '' || Packagedetail.triple_infant !== '' || Packagedetail.quad_infant !== '') && (
                      <div>
                      <div className='border-line'></div>
                      <div className='mt-2'>
                        <h4 class='card-title'>Infant Detail:</h4>
                      </div>
                      </div>
                      )}
                      <ul class='list-items mt-2 list-items-2 py-2'>
                        {Packagedetail.infants !== '' &&
                          Packagedetail.infants !== '0' && (
                            <li className='mt-2'>
                              <span>Infant Price :</span>
                              {Packagedetail.infants} X  {Packagedetail.currency}{' '}
                              {Packagedetail.infant_price}
                            </li>
                          )}
                        {Packagedetail.double_infant !== '' &&
                          Packagedetail.double_infant !== '0' && (
                            <li className='mt-2'>
                              <span>Infant Double Price :</span>
                              {Packagedetail.double_infant} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.double_infant_price}
                            </li>
                          )}
                        {Packagedetail.triple_infant !== '' &&
                          Packagedetail.triple_infant !== '0' && (
                            <li className='mt-2'>
                              <span>Infant Triple Price :</span>
                              {Packagedetail.triple_infant} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.triple_infant_price}
                            </li>
                          )}
                        {Packagedetail.quad_infant !== '' &&
                          Packagedetail.quad_infant !== '0' && (
                            <li className='mt-2'>
                              <span>Infant Quad Price :</span>
                              {Packagedetail.quad_infant} X{' '}
                              {Packagedetail.currency}{' '}
                              {Packagedetail.quad_infant_price}
                            </li>
                          )}
                      </ul>
                      {/* <div className='border-line'></div> */}
                    </div>
                  </div>
                </div>
              </div>
              <article>
                <section class="segmented-list price-summary checkout-hotel-detail">
                  <article id="price-summary-title" class="segment no-target">
                    <div class="price-summary-title">Price details</div>
                  </article>
                  <article id="price-summary" class="segment no-target">
                    <div id="newPriceDisplayEnabled" class="pricing-details-wrapper two-column">
                      <div id="details-content" className='tour_booking_amount_area'>
                        {/* <ul class='list-items list-items-2 mt-2 py-2'>
                          {Packagedetail.without_acc_adults !== '' &&
                            Packagedetail.without_acc_adults !== '0' && (
                              <li className='mt-2'>
                                <span>Adult Price :</span>
                                {Packagedetail.without_acc_adults} X{' '}
                                {Packagedetail.currency}{' '}
                                {Packagedetail.without_acc_adult_price}
                              </li>
                            )}
                          {Packagedetail.double_adults !== '' &&
                            Packagedetail.double_adults !== '0' && (
                              <li className='mt-2'>
                                <span>Adult Double Price :</span>
                                {Packagedetail.double_adults} X{' '}
                                {Packagedetail.currency} {Packagedetail.sharing2}
                              </li>
                            )}
                          {Packagedetail.triple_adults !== '' &&
                            Packagedetail.triple_adults !== '0' && (
                              <li className='mt-2'>
                                <span>Adult Triple Price :</span>
                                {Packagedetail.triple_adults} X{' '}
                                {Packagedetail.currency} {Packagedetail.sharing3}
                              </li>
                            )}
                          {Packagedetail.quad_adults !== '' &&
                            Packagedetail.quad_adults !== '0' && (
                              <li className='mt-2'>
                                <span>Adult Quad Price :</span>
                                {Packagedetail.quad_adults} X{' '}
                                {Packagedetail.currency} {Packagedetail.sharing4}
                              </li>
                            )}
                        </ul> */}
                        <div class="taxes">
                          <a data-control="modal" href="#taxes-and-fees-description" id="taxes-and-fees-link" data-role-dialog="true" data-modal-id="taxes-and-fees-link" data-js-theme="default" data-title="Taxes and fees" data-animation="slide-in-slide-out">     Taxes and fees <span class="icon icon-infoalt" aria-hidden="true"></span></a>
                          <div id="taxes-and-fees-description" class="hidden"><p>The taxes are tax recovery charges Expedia pays to its vendors (e.g. hotels); for details, please see our Terms of Use. We retain our service fees as compensation in servicing your travel reservation.</p></div>
                          <span class="taxes-and-fees-total-without-points amount-value " data-tax-included="false" data-price-update="totalTaxes"> {Packagedetail.currency}{' '} 0 </span>
                        </div>
                        <div class="fee-details due-at-hotel-fees non-daily"></div>
                        <hr class="fee-breakdown-bottom-separator" aria-hidden="true"/>
                        <div class="total-without-pwp">
                          <ul class='list-items  mt-2 list-items-2 py-3'>
                            <li className='fw-bold mt-2'>
                              <span>Sub Total:</span>
                              {Packagedetail.currency}{' '}
                              {Packagedetail.tour_total_price}
                            </li>
                            <li className='fw-bold mt-2'>
                              <span>Discount Price:</span>
                              {Packagedetail.currency}{' '}
                              {Packagedetail.discount_Price}
                            </li>

                            {/* <li className='fw-bold'>
                              <span>Agent Commisson:</span> 3432
                            </li> */}
                            <li className='fw-bold mt-2'>
                              <span>Total Price:</span>
                              {StripeCurrency=Packagedetail.currency}{' '}
                              {StripePayment=Packagedetail.tour_total_price -
                                Packagedetail.discount_Price}
                            </li>
                          </ul>
                        </div>
                        <div class="hotel-rate-information">Rates are quoted in US dollars.</div>
                      </div>
                    </div>
                  </article>
                </section>                            
              </article>
              <div className=' book-package-2 mt-2'>
                <h6 style={{color:'black'}}>Why Book with us?</h6>
                <ul class='list-items book-package-3  list-items-2 mt-2 py-2'>
                <li className=' fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faDollar}/></span>No-hassle best price guarantee
                  </li>
                  <div className='border-line'></div>
                  <li className='mt-2 fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faHeadphones}/></span>Customer care available 24/7
                  </li>
                  <div className='border-line'></div>
                  <li className='mt-2 fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faStar}/></span>Picked Packages to your Need
                  </li>
                  <div className='border-line'></div>
                  <li className='mt-2 fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faLock}/></span>Secure Payment & Privacy
                  </li>
                </ul>
              </div>
              {/* <div className='book-package-4'>
                <h3 >Got a Question?</h3>
                <div>
                  <p  className='mt-3'>Do not hesitate to give us a call. Our expert team would be happy to help you.</p>
                  <h5  className='mt-3'><FontAwesomeIcon icon={faPhone}/> 0121 777 2522</h5>
                  <h5  className='mt-3'><FontAwesomeIcon icon={faEnvelope}/> info@alhijaztours.net</h5>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </WithoutLayout>
    </>
  )
}

export default PackageCheckout
