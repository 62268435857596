import React,{useState} from "react";
import Layout from "../../Components/Layout/Layout";
import bgimage from '../../Images/Activity/cover.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ActivityCard from "../../Components/Activity/ActivityCard";
import { useSelector } from "react-redux";
import ActivitySearch from "../../Components/SearchBar/ActivitySearch";
function Activities (){
  const [ShowRatingFilter, setShowRatingFilter] = useState(false);
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const ActivityListing=useSelector((state) => state.hotels.ActivityListing);
const[newActivityListing,setNewActivityListing]=useState(ActivityListing.tours);
const[FilterActivityListing,setFilterActivityListing]=useState(ActivityListing.tours);
const handleCheckboxChange = (event) => {
  const { name, value } = event.target;

        setStarRating((prevDetails) => {
          const updatedStarRating = { ...prevDetails };
          if (updatedStarRating[name] !=="0") {
            updatedStarRating[name]="0";
          } else {
            updatedStarRating[name] =value;
          }

          return updatedStarRating;
        });
      };
      
      const filterbyStars=()=>{
        setShowRatingFilter(false);
        if(starRating.rating1==='0' && starRating.rating2==='0' && starRating.rating3==='0' && starRating.rating4==='0' && starRating.rating5==='0'){
          setFilterActivityListing(newActivityListing);
          
        }else{
          console.log(newActivityListing);
        const filteredTours = newActivityListing.filter((tour) => {
          const hotelRating = tour.starts_rating;
          if(hotelRating !=='')
          {
            return Object.keys(starRating).some((ratingKey) => Number(hotelRating) === Number(starRating[ratingKey]));

          }
        });
        setFilterActivityListing(filteredTours);
        
      }

      };

      const ToggleRatingFilter=()=>{
        setShowRatingFilter(!ShowRatingFilter);
      };
    return(
        <>
         <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={filterbyStars} className='btn btn-warning m-2'>Apply</button>
                </div>
                  
          </ModalBody>
        </Modal>
        <Layout>
      <div className='container-fluid px-4'>
        <div className="row mt-3"><ActivitySearch/> </div>
      </div>
      <div className='container-fluid px-4 hotel-top mt-3'>
        <div className='row mt-2'>
          <div className='col-md-3 col-md-pull-9 '>
            <div class="mobile-Filter-info">
                <ul>
                    <li><p onClick={ToggleRatingFilter}><FontAwesomeIcon icon={faStar}/><span > Rating</span></p>
                    </li>
                </ul>
            </div>
            <div className='page-sidebar hide-page_filter'>
              <div class="uitk-spacing uitk-spacing-margin-block-two uitk-spacing-padding-blockend-two">
                <div class="uitk-card uitk-card-roundcorner-all uitk-card-padded uitk-card-has-primary-theme">
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                    <div class="uitk-card-content-section uitk-layout-flex-item-align-self-center uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0">
                      <img class="uitk-illustration" alt="Price tracking" src="https://a.travel-assets.com/egds/illustrations/price_trend__positive__xsmall.svg"/>
                    </div>
                    <div class="uitk-card-content-section uitk-card-content-section-padded-inline-start uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                      <h3 class="uitk-heading uitk-heading-6">Price tracking</h3>
                    </div>
                  </div>
                  <hr class="uitk-spacing uitk-spacing-margin-block-four uitk-spacing-border-blockstart uitk-spacing-hr"/>
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                    <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                      <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Watch prices</div>
                      <div class="uitk-text uitk-type-200 uitk-text-default-theme">Get email notifications if prices go up or down</div>
                    </div>
                    <div class="uitk-card-content-section uitk-card-content-section-padded-inline-start uitk-layout-flex-item-align-self-center uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0">
                      <div class="uitk-switch uitk-switch-theme-standard uitk-switch-position-leading">
                        <input type="checkbox" class="uitk-switch-input" aria-label="Save search" aria-describedby="" value=""/>
                        <div aria-hidden="true" class="uitk-switch-control">
                          <div class="uitk-switch-control-handle">
                            <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="uitk-switch-content">
                          <label class="uitk-switch-label">
                            <span class="uitk-switch-label-content"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="uitk-heading uitk-heading-5 my-3">Filter by</h4>
              <div className='widget widget_has_radio_checkbox pb-3' style={{borderBottom:'0'}}>
                <div className='filter-show-hide'>                
                  <h4 className='uitk-heading uitk-heading-7 mb-2'>Star Rating</h4>                
                </div>
                <div>
                <ul className='uitk-button-toggle-group'>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10" name='rating1' value='1' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange}/>
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">1</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10"name='rating2' value='2' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange} />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">2</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10" name='rating3' value='3' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange} />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">3</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                    <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-star-10">
                      <input type="checkbox" aria-label="1 star" id="ShoppingSelectableFilterOption-star-10" name='rating4' value='4' class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" onChange={handleCheckboxChange} />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">4</span>
                    </label>
                  </div>
                  <div class="uitk-button-toggle uitk-button-toggle-is-inline" onChange={handleCheckboxChange}>
                    <label class="uitk-button-toggle-content" name='rating5' value='5' for="rating5">
                      <input type="checkbox" aria-label="5 star" id="rating5" name='rating5' value='5' class="uitk-button-toggle-input is-visually-hidden" onChange={handleCheckboxChange} aria-checked="false" />
                      <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                      </svg>
                      <span class="uitk-button-toggle-label-text" aria-hidden="true">5</span>
                    </label>
                  </div>
                </ul>
                <button onClick={filterbyStars} className='btn select-styling search-btn1'>Apply</button>
                </div>
              </div>
              {/* <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' > <h3>Filter by Rating</h3> </div>
                <div>
                  <ul>
                    <li>
                      <label>
                        <input type='checkbox' 
                        className='custom-textbox' 
                        onChange={handleCheckboxChange} name='rating5' value='5' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                  </ul>
                  <button style={{width:'96%'}} onClick={filterbyStars} className='btn btn-warning  m-2'>Apply</button>
                </div>
                  
              </div> */}
            </div>
          </div>
          <div className='col-md-9 col-md-push-3'>
            <section data-stid="results-header" class="uitk-spacing uitk-spacing-margin-blockstart-one mb-3">
              <header style={{boxShadow:'none !important', backgroundColor:'transparent'}}>
                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid tour-main">
                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-5">
                    <div data-stid="results-header-messages">
                      <a href="https://www.expedia.com/lp/b/sort-order-info" target="_blank" rel="noopener" analytics="[object Object]" class="uitk-more-info-trigger uitk-more-info-trigger-size-small uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading">
                        <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme">
                          <h5 className='title font-size-22 tc mb-2' id='tours_result'>
                              {ActivityListing.tours.length} Result found
                          </h5>
                          <b style={{fontWeight:'bold'}}>How our sort order works</b>
                          <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlinestart-one uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-sort">
                    <div class="">
                      <form class="uitk-form">
                        <div>
                          <div class="uitk-field uitk-field-select-field has-floatedLabel-label">
                            <label for="sort-filter-dropdown-sort" class="uitk-field-label is-visually-hidden">Sort by</label>
                            <select id="sort-filter-dropdown-sort" name="sort" class="uitk-field-select">
                              <option selected="" value="RECOMMENDED" aria-selected="true" data-opt-id="RECOMMENDED">Recommended</option>
                              <option value="PRICE_LOW_TO_HIGH" aria-selected="false" data-opt-id="PRICE_LOW_TO_HIGH">Price: low to high</option>
                              <option value="PRICE_HIGH_TO_LOW" aria-selected="false" data-opt-id="PRICE_HIGH_TO_LOW">Price: high to low</option>
                              <option value="DISTANCE" aria-selected="false" data-opt-id="DISTANCE">Distance from downtown</option>
                              <option value="REVIEW_RELEVANT" aria-selected="false" data-opt-id="REVIEW_RELEVANT">Guest rating + our picks</option>
                              <option value="PROPERTY_CLASS" aria-selected="false" data-opt-id="PROPERTY_CLASS">Star rating</option>
                              <option value="PACKAGE_SAVINGS" aria-selected="false" data-opt-id="PACKAGE_SAVINGS">Package discount</option>
                            </select>
                            <svg class="uitk-icon uitk-field-select-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                            </svg>
                            <div class="uitk-field-label" aria-hidden="true">Sort by</div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </header>
            </section>
            <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 my-2">
              <div data-testid="one-key-banner-1" id="one-key-banner-1" className="px-0">
                  <div>
                      <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0" data-stid="one-key-message-card">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                              <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                  <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                      <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                  </div>
                              </div>
                              <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                          <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                              <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">Earn OneKeyCash on this cruise when you sign in and book</h2>
                                          </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                          <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                            <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <ActivityCard newActivityListing={FilterActivityListing}/>
          </div>
        </div>
      </div>
        </Layout>
        </>
    );
}

export default Activities;