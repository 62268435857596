import React,{useState,useEffect} from "react";
import Layout from "../../Components/Layout/Layout";
import FlightCard from "../../Components/Flight/FlightCard";
import { useSelector } from 'react-redux';
import img1 from '../../Images/Flight/1.jpg';
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Axios from "axios";
import FlightSearch from "../../Components/SearchBar/FlightSearch";
import { CurrencyConverter } from "../../Components/GlobalData/GlobalData";
import { airportcode } from "../../Components/Data/AirportCodes";
import carimage from '../../Images/Flight/car.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slider, RangeSlider } from 'rsuite';
import { AirLineNames } from "../../Components/Data/AirLineNames";
import { faClock, faDollarSign,faStar,  faLock,  faEnvelope,  faPhone,
  faDollar,
  faHeadphones,
  faSearch,
  faStopCircle,
  faFilter,
  faPlane, } from "@fortawesome/free-solid-svg-icons";
function FlightListing(){
  const [filterData,setFilterData]=useState('');
  const [flightStop,setFlightStop]=useState({type:"Stop"});
  const [priceFilter,setPriceFilter]=useState('');
  const [findHotel,setFindHotel]=useState('5');
  const [showPrice, setShowPrice] = useState(true);
  const [ShowStopFilter, setShowStopFilter] = useState(false);
  const [ShowAllFilter, setShowAllFilter] = useState(false);
  const [ShowAirlineFilter, setShowAirlineFilter] = useState(false);
  const [showModifySearch, setShowModifySearch] = useState(false);
  const [SortFastestData,setSortFastestData]=useState({time:'',price:'',currency:''});
  const [SortCheepestData,setSortCheepestData]=useState({time:'',price:'',currency:''});
  const [JourneyDuration,setJourneyDuration]=useState({min:0,max:0});
  const [airlinesCount,setAirlinesCount]=useState({type:"Airline"});
  const [airlinesClass,setAirlinesClass]=useState({type:"Class"});
  const [ArivalCity,setArivalCity]=useState('');
  const [flightInfo,setFlightinfo]=useState({arivalcityname:'',arivalcitycode:'',departurename:'',departurecode:''});
  const [durationFilter,setDurationFiltert]=useState({type:"Journey"});
  const [departureTime,setDepartureTime]=useState({type:"DepartureTime"});
  const [AarlineFilter,setAirlineFilter]=useState({});
  const [baseCurrency, setBaseCurrency] = useState([]);
  const [baseCName, setBaseCName] = useState('GBP');
  const SearchFlights = useSelector((state) => state.hotels.OneWayFlight.PricedItineraries);
  var Airlinenamesdata2=AirLineNames;
  const [sliderValue, setSliderValue] = useState(0);
  const [rangeValues, setRangeValues] = useState(['00:00', '23:59']);
  const [selectedFilter, setSelectedFilter] = useState('');
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const FlightSearchData = useSelector(state => state.hotels.OneWayFlightSearchData);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  var FlightMarkup = JSON.parse(localStorage.getItem('FlightMarkup'));
  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    if(filter=='BestRate'){
      var num='3';
      setPriceFilter(num);
    }else if(filter=='CheapestFirst'){
      var num='1';
      setPriceFilter(num);

    }else if(filter=='FastestFirst'){
      var num='2';
      setPriceFilter(num);

    }
  };
  const GoHotels =()=>{
    setPriceFilter(findHotel);
    if(findHotel===5){
      var num=Number(findHotel)+1;
      setFindHotel(String(num));
    }else{
      var num=Number(findHotel)-1;
      setFindHotel(String(num));
    }
  };
  const handleRangeChange = (values) => {
    setRangeValues(values);
  };

  const handleRangeRelease = (values) => {
    setDepartureTime({ ...departureTime, time: values });
    // Add your logic here
  };

  // Function to format time in HH:mm format
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };

  // Function to convert time to minutes
  const timeToMinutes = (time) => {
    const [hours, mins] = time.split(':').map(Number);
    return hours * 60 + mins;
  };

  // Create an array of time values in 30-minute intervals
  const timeOptions = [];
  for (let i = 0; i <= 24 * 60; i += 30) {
    timeOptions.push(formatTime(i));
  }
  // const handleSliderChange = (event) => {
  //   const value = event.target.value;
  //   setSliderValue(value);
  // };


useEffect(()=>{
  CalculateSortFilterData();
  FilterFlightDuration();
  arrivalcountryname();
  GetFlightInfo();
  AllCurrency(SearchFlights[0]?.AirItineraryPricingInfo.PTC_FareBreakdowns[0]?.PassengerFare.TotalFare.CurrencyCode);
},[]);
useEffect(() => {
  // Your code to be executed every time hotelsSearchData changes
  // For example:

  // Add your logic here based on the updated hotelsSearchData
}, [FlightSearchData]);


const DisplayModifySearch=()=>{
  setShowModifySearch(!showModifySearch);
};
  const FilterFlightDuration = () => {
    const durations = [];
    SearchFlights.forEach((item) => {
      var sum=0
      item.OriginDestinationOptions[0].FlightSegments.forEach((item2) => {
        sum=sum+item2.JourneyDuration;
      });
      durations.push(sum);
    });
    const minDuration = Math.min(...durations);
    const maxDuration = Math.max(...durations);
    const minhour=Math.floor(minDuration / 60);
    const maxhour=Math.floor(maxDuration / 60);
    setJourneyDuration({min:minhour+1,max:maxhour+1});
    setSliderValue(maxhour+1);

  };
  const arrivalcountryname=()=>{
    if(SearchFlights !== null && SearchFlights !==''){
      const length=SearchFlights[0].OriginDestinationOptions[0].FlightSegments.length;
      const filteredOptions = airportcode.items.filter(option =>
        option.airportCode.includes(SearchFlights[0].OriginDestinationOptions[0].FlightSegments[Number(length)-1].ArrivalAirportLocationCode )
      );
      if(filteredOptions.length !== 0){
        setArivalCity(filteredOptions[0].cityName);
      }
    };
    
  };

  const GetFlightInfo =()=>{
    if(SearchFlights !== null && SearchFlights !==''){
      const filteredOptions = airportcode.items.filter(option =>
        option.airportCode.includes(FlightSearchData.DepartureCode )
      );
      const arivaloptions = airportcode.items.filter(option =>
        option.airportCode.includes(FlightSearchData.ArrivalCode)
      );
      if(filteredOptions.length !== 0){
       setFlightinfo({
        departurecode:filteredOptions[0]?.airportCode,
        departurename:filteredOptions[0]?.cityName,
        arivalcitycode:arivaloptions[0]?.airportCode,
        arivalcityname:arivaloptions[0]?.cityName
        
      });
      }
    };
    
    
  };
  const CalculateSortFilterData=()=>{
    var Indirectflight = SearchFlights.filter(flight => {
      // Check if the first element of FlightSegments array has an index greater than one
      return flight.OriginDestinationOptions[0].FlightSegments.length > 1;
    });
  var sotFlight = Indirectflight.sort(
          (a, b) =>
            Number(a.AirItineraryPricingInfo.PTC_FareBreakdowns[0]?.PassengerFare.TotalFare.Amount) -
            Number(b.AirItineraryPricingInfo.PTC_FareBreakdowns[0]?.PassengerFare.TotalFare.Amount)
        );

        const durations = [];
      SearchFlights.forEach((item) => {
        var sum = 0;
        item.OriginDestinationOptions[0].FlightSegments.forEach((item2) => {
          sum = sum + item2.JourneyDuration;
        });
        durations.push(sum);
      });
      
      // Now, sort the SearchFlights array based on the durations
      const Shorttest = SearchFlights.slice(); // Create a copy of the array to avoid mutating the original array
      
      Shorttest.sort((a, b) => {
        const durationA = durations[SearchFlights.indexOf(a)];
        const durationB = durations[SearchFlights.indexOf(b)];
      
        return durationA - durationB;
      });
      // var BestFlight= SearchFlights.sort((flight1, flight2) => {
      //   const score1 = calculateOverallScore(flight1);
      //   const score2 = calculateOverallScore(flight2);
      
      //   // Sort in descending order (higher score comes first)
      //   return score2 - score1;
      // });
      
      if(Shorttest !== null){
        var sum=0
        Shorttest[0].OriginDestinationOptions[0].FlightSegments.forEach((item3) => {
          sum = sum + item3.JourneyDuration;
        });
        var time=`${Math.floor( sum / 60)}h ${sum % 60}m`
        var price= Shorttest[0].AirItineraryPricingInfo.PTC_FareBreakdowns[0].PassengerFare.TotalFare.Amount; ;
        var curr=Shorttest[0].AirItineraryPricingInfo.PTC_FareBreakdowns[0].PassengerFare.TotalFare.CurrencyCode;
        setSortFastestData({time:time,currency:curr,price:price});
      }
      if(sotFlight !== null){
        var sum=0
        sotFlight[0].OriginDestinationOptions[0].FlightSegments.forEach((item3) => {
          sum = sum + item3.JourneyDuration;
        });
        var time2=`${Math.floor( sum / 60)}h ${sum % 60}m` ;
        var currency=sotFlight[0].AirItineraryPricingInfo.PTC_FareBreakdowns[0].PassengerFare.TotalFare.CurrencyCode;
        var price2=sotFlight[0].AirItineraryPricingInfo.PTC_FareBreakdowns[0].PassengerFare.TotalFare.Amount;
        setSortCheepestData({price:price2,currency:currency,time:time2})
      }
  };


  const handleAirlineStopChange = (event) => {
    setShowStopFilter(false);
    const { name, value } = event.target;
    
    const isSelected = flightStop[name] === value;
    
    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...flightStop };
      delete updatedFacilities[name];
      setFlightStop(updatedFacilities);
    } else {
      // If not selected, add it to the object
      setFlightStop({ ...flightStop, [name]: value });
    }
  };

  const handleAirlineNameChange = (event) => {
    setShowAirlineFilter(false);
    const { name, value } = event.target;
    
    const isSelected = airlinesCount[name] === value;
    
    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...airlinesCount };
      delete updatedFacilities[name];
      setAirlinesCount(updatedFacilities);
    } else {
      // If not selected, add it to the object
      setAirlinesCount({ ...airlinesCount, [name]: value });
    }
  };

  const handleAirlineClassChange = (event) => {
    setShowAllFilter(false);
    const { name, value } = event.target;
    
    const isSelected = airlinesClass[name] === value;
    
    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...airlinesClass };
      delete updatedFacilities[name];
      setAirlinesClass(updatedFacilities);
    } else {
      // If not selected, add it to the object
      setAirlinesClass({ ...airlinesClass, [name]: value });
    }
  };

  const handleSliderRelease = (value) => {
    setDurationFiltert({ ...durationFilter, time: value });
  };

  useEffect(()=>{
    Airlinefilter();
  },[SearchFlights]);

  useEffect(()=>{
    setFilterData(flightStop);
  },[flightStop]);

  useEffect(()=>{
    setFilterData(departureTime);
  },[departureTime]);

  useEffect(()=>{
    setFilterData(durationFilter);
  },[durationFilter]);

  useEffect(()=>{
    setFilterData(airlinesCount);
  },[airlinesCount]);

  useEffect(()=>{
    setFilterData(airlinesClass);
  },[airlinesClass]);

  useEffect(()=>{
    setFilterData(priceFilter);
  },[priceFilter]);

  const Airlinefilter=()=>{

    const airlineCodeCount = {};

    // Iterate through the flight list
    for (const flight of SearchFlights) {
      const validatingAirlineCode = flight.ValidatingAirlineCode;
  
      // Check if the airline code exists in the count object
      if (airlineCodeCount.hasOwnProperty(validatingAirlineCode)) {
        airlineCodeCount[validatingAirlineCode]++;
      } else {
        airlineCodeCount[validatingAirlineCode] = 1;
      }
    }
    setAirlineFilter(airlineCodeCount)
  };
  const CalculateFLightMarkup = price => {
    var admin = 0
    var client = 0
    if (price !== 'NaN') {
      FlightMarkup.markups.forEach(markup => {
        if (markup.services_type === 'flight') {
          if (markup.added_markup === 'alhijaz') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = Number(markup.markup_value)
              const markupAmount = (Number(price) * markupValue) / 100
              client = markupAmount
            } else {
              client = Number(markup.markup_value)
            }
          } else if (markup.added_markup === 'synchtravel') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = parseFloat(Number(markup.markup_value))
              const markupAmount = (Number(price) * markupValue) / 100
              admin = markupAmount
            } else {
              admin = Number(markup.markup_value)
            }
          }
        }
      })

      var total = Number(price) + admin + client
      return total.toFixed(2)
    }
  };
  // const handlePriceFilterChange=(event)=>{
  //   var num=event.target.value;
  //   setPriceFilter(num);
  // };

  const AllCurrency = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        setBaseCurrency(response.data.conversion_rates)
      })
      .catch(error => {
        // Handle errors here
        setShowPrice(false)
        console.error(error)
      })
  };

   

  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      const gbpprice = baseCurrency[baseCName] // Use square brackets to access the property
      var baseprice = (Number(gbpprice) * Number(price))
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrency[baseCName]
      var baseprice1 = (Number(gbpprice) * Number(price))
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = (Number(gbpprice2) * Number(baseprice1))
    }
    return baseprice
  };


  const ToggleStopFilter=()=>{
    setShowStopFilter(!ShowStopFilter);
  };
  const ToggleAllFilter=()=>{
    setShowAllFilter(!ShowAllFilter);
  };
  const ToggleAirlineFilter=()=>{
    setShowAirlineFilter(!ShowAirlineFilter);
  };
  return(
        <>
        <Modal isOpen={ShowStopFilter} toggle={ToggleStopFilter}>
          <ModalHeader toggle={ToggleStopFilter}>Flight Stop Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  {/* <h3 className='form-label'>Flight Stop</h3> */}
                  <ul>
                  <li>
                    <label>
                      <input className="custom-textbox" type='checkbox' onChange={handleAirlineStopChange} name='OneStop' value='OneStop' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> OneStop</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className="custom-textbox"onChange={handleAirlineStopChange} name='Direct' value='Direct' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Direct </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineStopChange} name='All' value='All' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> All</span>
                    </label>
                  </li>
                  </ul>
                  
                </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowAllFilter} toggle={ToggleAllFilter}>
          <ModalHeader toggle={ToggleAllFilter}>Flight Filters</ModalHeader>
          <ModalBody>
          <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  <h4 className='form-label'>Departure Times</h4>
                  <span><b>Outbound</b> <br/>{rangeValues[0]} - {rangeValues[1]}</span>
                  <RangeSlider
                  min={0}
                  max={24 * 60}
                  step={30}
                  tooltip={false}
                  className="mt-2"
                  value={[timeToMinutes(rangeValues[0]), timeToMinutes(rangeValues[1])]}
                  renderMark={(mark) => formatTime(mark)}
                  onChange={(values) => handleRangeChange([formatTime(values[0]), formatTime(values[1])])}
                  onChangeCommitted={(values) => handleRangeRelease([formatTime(values[0]), formatTime(values[1])])}

                />
                    </div>
              </div>
              <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  <h4 className='form-label'>Journey Duration</h4>
                  <span>{JourneyDuration.min}:00 hours- {sliderValue} Hours</span>  
                  
                    <Slider
                      progress
                      className="mt-2"
                      min={JourneyDuration.min}
                      max={JourneyDuration.max}
                      value={sliderValue}
                      step={0.5}
                      tooltip={false}
                      onChangeCommitted={(value, event) => {
                        handleSliderRelease(value, event);
                      }}
                      onChange={value => {
                        setSliderValue(value);
                      }}
                    />
                    </div>
              </div>
              <div className='widget widget_has_radio_checkbox mt-4'>
                <h4>Flight Type</h4>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class1' value='Y' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Economy</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class2' value='C' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Business </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class3' value='F' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> First</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class4' value='S' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Premium Economy</span>
                    </label>
                  </li>
                </ul>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowAirlineFilter} toggle={ToggleAirlineFilter}>
          <ModalHeader toggle={ToggleAirlineFilter}>Airlines Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <ul>
                {Object.entries(AarlineFilter).map(([code, count]) => (
                      <li key={code}>
                        <label>
                          <input
                            type='checkbox'
                            className="custom-textbox"
                            onChange={handleAirlineNameChange}
                            name={code}
                            value={code}
                          />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>{' '}
                            {Airlinenamesdata2[code] ? (
                              `${Airlinenamesdata2[code].AirLineName} (${count})`
                            ) : (
                              `Unknown Airline (${count})`
                            )}
                          </span>
                        </label>
                      </li>
                    ))}

                </ul>
              </div>
          </ModalBody>
        </Modal>
        <Layout>
      <div className='container-fluid px-4'>
        <div className='mt-2'><FlightSearch/> </div>
      </div>
        {/* <div style={{cursor:'pointer'}} className='d-flex mt-3 flight-modify-responsive p-2 hotel-top'onClick={DisplayModifySearch}>
          <div  className="d-flex align-items-center w-100 justify-content-between">
          <div className=' ms-2'>
            <h5 className='title font-size-24 tc' id='tours_result'>
              {flightInfo.departurename} ({flightInfo.departurecode}) - {flightInfo.arivalcityname} ({flightInfo.arivalcitycode}) 
            </h5>
            <h6 className='title font-size-24 tc' id='tours_result'>
            {FlightSearchData.adult !== 0 && (
              <>
                {FlightSearchData.adult} adult 
              </>
            )}
            {FlightSearchData.child !== 0 && (
              <>
                - {FlightSearchData.child} child
              </>
            )}
            {FlightSearchData.infant !== 0 && (
              <>
                - {FlightSearchData.infant} infant
              </>
            )} | {FlightSearchData.CabinType === 'no' ? 'All' : FlightSearchData.CabinType === 'y' ? 'Economy' : FlightSearchData.CabinType === 'c' ? 'Business' : FlightSearchData.CabinType === 'F' ? 'First' :  FlightSearchData.CabinType === 'S' ? 'Premium Economy' : ''}
            </h6>
          </div>
          <div className=' ms-2'>
            <h5 className='title font-size-24 tc' id='tours_result'>
            {SearchFlights.length} Results Found 
            </h5>
            <h6 className='title font-size-24 tc' id='tours_result'>
              {moment(FlightSearchData.DepartureDate).format('DD-MM-YYYY')}
            </h6>
          </div>
          </div>
        </div> */}
      <div className='container-fliud p-4 mt-2' style={{backgroundColor:'#eff3f7'}}>
        <div className='row'>
          <div className='col-md-3 col-md-pull-9 '>
            <div class="uitk-spacing uitk-spacing-margin-block-two uitk-spacing-padding-blockend-two">
              <div class="uitk-card uitk-card-roundcorner-all uitk-card-padded uitk-card-has-primary-theme">
                <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                  <div class="uitk-card-content-section uitk-layout-flex-item-align-self-center uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0">
                    <img class="uitk-illustration" alt="Price tracking" src="https://a.travel-assets.com/egds/illustrations/price_trend__positive__xsmall.svg"/>
                  </div>
                  <div class="uitk-card-content-section uitk-card-content-section-padded-inline-start uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                    <h3 class="uitk-heading uitk-heading-6">Price tracking</h3>
                  </div>
                </div>
                <hr class="uitk-spacing uitk-spacing-margin-block-four uitk-spacing-border-blockstart uitk-spacing-hr"/>
                <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                  <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                    <div class="uitk-text uitk-text-spacing-one uitk-type-300 uitk-text-default-theme">Watch prices</div>
                    <div class="uitk-text uitk-type-200 uitk-text-default-theme">Get email notifications if prices go up or down</div>
                  </div>
                  <div class="uitk-card-content-section uitk-card-content-section-padded-inline-start uitk-layout-flex-item-align-self-center uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0">
                    <div class="uitk-switch uitk-switch-theme-standard uitk-switch-position-leading">
                      <input type="checkbox" class="uitk-switch-input" aria-label="Save search" aria-describedby="" value=""/>
                      <div aria-hidden="true" class="uitk-switch-control">
                        <div class="uitk-switch-control-handle">
                          <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="uitk-switch-content">
                        <label class="uitk-switch-label">
                          <span class="uitk-switch-label-content"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile-Filter-info">
              <ul>
                  <li><p onClick={ToggleStopFilter}><FontAwesomeIcon icon={faStopCircle}/> Flight Stop</p></li>
                  <li><p onClick={ToggleAllFilter}><FontAwesomeIcon icon={faFilter}/><span > Filter</span></p></li>
                  <li><p onClick={ToggleAirlineFilter}><FontAwesomeIcon icon={faPlane}/><span > Airlines</span></p></li>
              </ul>
            </div>
            <div className='page-sidebar hide-page_filter'>
                <h3 class="uitk-heading uitk-heading-5">Filter by</h3>  
              <div className='widget widget_price_filter' style={{borderBottom:'0'}}>
                <div className='mb-0'>
                  <h4 class="uitk-heading uitk-heading-7 uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 my-3">Stops</h4>
                  <ul>
                    <li>
                      <label>
                        <input className="custom-textbox" type='checkbox' onChange={handleAirlineStopChange} name='OneStop' value='OneStop' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> OneStop</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className="custom-textbox"onChange={handleAirlineStopChange} name='Direct' value='Direct' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> Direct </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className="custom-textbox" onChange={handleAirlineStopChange} name='All' value='All' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> All</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='widget widget_has_radio_checkbox' style={{borderBottom:'0'}}>
                 <h4 class="uitk-heading uitk-heading-7 uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 my-3">Airlines</h4>
                <ul>
                {Object.entries(AarlineFilter).map(([code, count]) => (
                      <li key={code}>
                        <label>
                          <input
                            type='checkbox'
                            className="custom-textbox"
                            onChange={handleAirlineNameChange}
                            name={code}
                            value={code}
                          />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>{' '}
                            {Airlinenamesdata2[code] ? (
                              `${Airlinenamesdata2[code].AirLineName} (${count})`
                            ) : (
                              `Unknown Airline (${count})`
                            )}
                          </span>
                        </label>
                      </li>
                    ))}

                </ul>
              </div>
              <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                <div>
                  <fieldset>
                    <legend><h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockend-two">Departure time in Lahore</h4></legend>
                    <ul class="uitk-button-toggle-group uitk-spacing uitk-spacing-margin-blockstart-two uitk-button-toggle-group-wrap">
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-DEPARTURE_TIME-EARLY_MORNING">
                          <input aria-label="Early Morning 12:00am through 4:59am" id="ShoppingSelectableFilterOption-DEPARTURE_TIME-EARLY_MORNING" name="DEPARTURE_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="EARLY_MORNING"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 3h-2v3h2V3zM7.05 7.64 4.93 5.5 3.52 6.93l2.12 2.12 1.41-1.41z"></path>
                            <path fill-rule="evenodd" d="M18 14c0 1.54-.58 2.94-1.53 4H23v2H1v-2h6.53A6 6 0 1 1 18 14zm-6 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" clip-rule="evenodd"></path>
                            <path d="M23 13h-3v2h3v-2zM1 13h3v2H1v-2zm18.07-7.49-2.12 2.13 1.41 1.41 2.12-2.12-1.41-1.42z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Early Morning</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(12:00am - 4:59am)</span>
                        </label>
                      </div>
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-DEPARTURE_TIME-MORNING">
                          <input aria-label="Morning 5:00am through 11:59am" id="ShoppingSelectableFilterOption-DEPARTURE_TIME-MORNING" name="DEPARTURE_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="MORNING"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 4V1h-2v3h2z"></path>
                            <path fill-rule="evenodd" d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" clip-rule="evenodd"></path>
                            <path d="M20 11h3v2h-3v-2zM4 11H1v2h3v-2zm9 9v3h-2v-3h2zm-5.95-1.64L4.93 20.5 3.5 19.07l2.13-2.12 1.4 1.41zM18.36 7.05l2.12-2.12-1.41-1.42-2.12 2.13 1.41 1.41zm-1.41 11.31 2.12 2.13 1.42-1.42-2.13-2.12-1.4 1.41zM5.64 7.05 3.52 4.93 4.93 3.5l2.12 2.13-1.41 1.41z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Morning</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(5:00am - 11:59am)</span>
                        </label>
                      </div>
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-DEPARTURE_TIME-AFTERNOON">
                          <input aria-label="Afternoon 12:00pm through 5:59pm" id="ShoppingSelectableFilterOption-DEPARTURE_TIME-AFTERNOON" name="DEPARTURE_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="AFTERNOON"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 3h-2v3h2V3zM7.05 7.64 4.93 5.5 3.52 6.93l2.12 2.12 1.41-1.41zM18 14c0 1.54-.58 2.94-1.53 4H23v2H1v-2h6.53A6 6 0 1 1 18 14z"></path>
                            <path d="M23 13h-3v2h3v-2zM1 13h3v2H1v-2zm18.07-7.49-2.12 2.13 1.41 1.41 2.12-2.12-1.41-1.42z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Afternoon</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(12:00pm - 5:59pm)</span>
                        </label>
                      </div>
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-DEPARTURE_TIME-EVENING">
                          <input aria-label="Evening 6:00pm through 11:59pm" id="ShoppingSelectableFilterOption-DEPARTURE_TIME-EVENING" name="DEPARTURE_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="EVENING"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m14.01 2 1.49 1.49-1.49 1.49-1.49-1.5L14.02 2zM22 14.68A9.42 9.42 0 0 1 9.31 2 10.21 10.21 0 1 0 22 14.68z"></path>
                            <path d="m18.52 6.05 1.12 1.86 1.86 1.11-1.86 1.12L18.52 12l-1.11-1.86-1.86-1.12 1.86-1.11 1.11-1.86z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Evening</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(6:00pm - 11:59pm)</span>
                        </label>
                      </div>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                <div>
                  <fieldset>
                    <legend>
                      <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockend-two">Arrival time in Dubai</h4>
                    </legend>
                    <ul class="uitk-button-toggle-group uitk-spacing uitk-spacing-margin-blockstart-two uitk-button-toggle-group-wrap">
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-ARRIVAL_TIME-EARLY_MORNING">
                          <input aria-label="Early Morning 12:00am through 4:59am" id="ShoppingSelectableFilterOption-ARRIVAL_TIME-EARLY_MORNING" name="ARRIVAL_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="EARLY_MORNING"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 3h-2v3h2V3zM7.05 7.64 4.93 5.5 3.52 6.93l2.12 2.12 1.41-1.41z"></path>
                            <path fill-rule="evenodd" d="M18 14c0 1.54-.58 2.94-1.53 4H23v2H1v-2h6.53A6 6 0 1 1 18 14zm-6 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" clip-rule="evenodd"></path>
                            <path d="M23 13h-3v2h3v-2zM1 13h3v2H1v-2zm18.07-7.49-2.12 2.13 1.41 1.41 2.12-2.12-1.41-1.42z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Early Morning</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(12:00am - 4:59am)</span>
                        </label>
                      </div>
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-ARRIVAL_TIME-MORNING">
                          <input aria-label="Morning 5:00am through 11:59am" id="ShoppingSelectableFilterOption-ARRIVAL_TIME-MORNING" name="ARRIVAL_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="MORNING"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 4V1h-2v3h2z"></path>
                            <path fill-rule="evenodd" d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" clip-rule="evenodd"></path>
                            <path d="M20 11h3v2h-3v-2zM4 11H1v2h3v-2zm9 9v3h-2v-3h2zm-5.95-1.64L4.93 20.5 3.5 19.07l2.13-2.12 1.4 1.41zM18.36 7.05l2.12-2.12-1.41-1.42-2.12 2.13 1.41 1.41zm-1.41 11.31 2.12 2.13 1.42-1.42-2.13-2.12-1.4 1.41zM5.64 7.05 3.52 4.93 4.93 3.5l2.12 2.13-1.41 1.41z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Morning</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(5:00am - 11:59am)</span>
                        </label>
                      </div>
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-ARRIVAL_TIME-AFTERNOON">
                          <input aria-label="Afternoon 12:00pm through 5:59pm" id="ShoppingSelectableFilterOption-ARRIVAL_TIME-AFTERNOON" name="ARRIVAL_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="AFTERNOON"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 3h-2v3h2V3zM7.05 7.64 4.93 5.5 3.52 6.93l2.12 2.12 1.41-1.41zM18 14c0 1.54-.58 2.94-1.53 4H23v2H1v-2h6.53A6 6 0 1 1 18 14z"></path>
                            <path d="M23 13h-3v2h3v-2zM1 13h3v2H1v-2zm18.07-7.49-2.12 2.13 1.41 1.41 2.12-2.12-1.41-1.42z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Afternoon</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(12:00pm - 5:59pm)</span>
                        </label>
                      </div>
                      <div class="uitk-button-toggle uitk-button-toggle-is-stacked">
                        <label class="uitk-button-toggle-content" for="ShoppingSelectableFilterOption-ARRIVAL_TIME-EVENING">
                          <input aria-label="Evening 6:00pm through 11:59pm" id="ShoppingSelectableFilterOption-ARRIVAL_TIME-EVENING" name="ARRIVAL_TIME" type="checkbox" class="uitk-button-toggle-input is-visually-hidden" aria-checked="false" value="EVENING"/>
                          <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m14.01 2 1.49 1.49-1.49 1.49-1.49-1.5L14.02 2zM22 14.68A9.42 9.42 0 0 1 9.31 2 10.21 10.21 0 1 0 22 14.68z"></path>
                            <path d="m18.52 6.05 1.12 1.86 1.86 1.11-1.86 1.12L18.52 12l-1.11-1.86-1.86-1.12 1.86-1.11 1.11-1.86z"></path>
                          </svg>
                          <span class="uitk-button-toggle-label-text" aria-hidden="true">Evening</span>
                          <span class="uitk-button-toggle-label-subtext" aria-hidden="true">(6:00pm - 11:59pm)</span>
                        </label>
                      </div>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <div className='widget widget_price_filter' style={{borderBottom:'0'}}>
                <div className='mb-0'>
                  <h3 className='form-label'>Departure Times</h3>
                  <span><b>Outbound</b> <br/>{rangeValues[0]} - {rangeValues[1]}</span>
                  <RangeSlider
                  min={0}
                  max={24 * 60}
                  step={30}
                  tooltip={false}
                  className="mt-2"
                  value={[timeToMinutes(rangeValues[0]), timeToMinutes(rangeValues[1])]}
                  renderMark={(mark) => formatTime(mark)}
                  onChange={(values) => handleRangeChange([formatTime(values[0]), formatTime(values[1])])}
                  onChangeCommitted={(values) => handleRangeRelease([formatTime(values[0]), formatTime(values[1])])}

                />
                    </div>
              </div>
              <div className='widget widget_price_filter' style={{borderBottom:'0'}}>
                <div className='mb-0'>
                  <h3 className='form-label'>Journey Duration</h3>
                  <span>{JourneyDuration.min}:00 hours- {sliderValue} Hours</span>  
                   {/* <input
                    type="range"
                    className="form-range"
                    id="customRange1"
                    min={JourneyDuration.min}
                    max={JourneyDuration.max}
                    step={0.5}
                    value={sliderValue}
                    onMouseUp={handleSliderRelease} 
                    onChange={handleSliderChange}
                  /> */}
                    <Slider
                      progress
                      className="mt-2"
                      min={JourneyDuration.min}
                      max={JourneyDuration.max}
                      value={sliderValue}
                      step={0.5}
                      tooltip={false}
                      onChangeCommitted={(value, event) => {
                        handleSliderRelease(value, event);
                      }}
                      onChange={value => {
                        setSliderValue(value);
                      }}
                    />
                    </div>
              </div>
              <div className='widget widget_has_radio_checkbox' style={{borderBottom:'0'}}>
                <h3>Flight Type</h3>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class1' value='Y' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Economy</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class2' value='C' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Business </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class3' value='F' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> First</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className="custom-textbox" onChange={handleAirlineClassChange} name='class4' value='S' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Premium Economy</span>
                    </label>
                  </li>
                </ul>
              </div>
              {/* <button  className='btn select-styling search-btn1'>Filter</button> */}
            </div>
          </div>
          <div className='col-md-6 col-md-push-3'>
            <section data-test-id="step-indicator-parent" class="uitk-spacing uitk-spacing-padding-blockstart-two uitk-spacing-padding-blockend-three side-spacing">
              <div class="uitk-layout-grid uitk-layout-grid-align-content-center uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid" data-test-id="uitk-progress-indicator">
                <div class="uitk-layout-grid-item uitk-progress-indicator-container uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-extra_large">
                  <ul class="uitk-progress-indicator unfade-end">
                    <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 1 of 2. Choose departing flight. Current page, Choose departing flight">
                      <span class="is-visually-hidden">Current: Choose departing flight</span>
                      <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="true">
                        <span>
                          <span class="">Choose departing flight</span>
                        </span>
                      </div>
                      <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                      </svg>
                    </li>
                    <li class="uitk-progress-indicator-step-wrapper" aria-label="Step 2 of 2. Review your trip, Review your trip">
                      <div class="uitk-text uitk-type-start uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-progress-indicator-step-details-wrapper" aria-hidden="false">
                        <span><span class="">Review your trip</span></span>
                      </div>
                    </li>
                  </ul>
                  <div class="uitk-progress-indicator-fade-start">
                    <div class="uitk-scrim fade-leading secondary-background"></div>
                  </div>
                  <div class="uitk-progress-indicator-fade-end">
                    <div class="uitk-scrim fade-trailing secondary-background"></div>
                  </div>
                </div>
                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-layout-grid-justify-items-end uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-extra_large">
                  <div class="uitk-layout-grid-item"></div>
                </div>
              </div>
            </section>
            <div data-test-id="flexible-search-container" class="uitk-spacing side-spacing uitk-spacing-margin-blockend-six uitk-spacing-padding-blockend-six uitk-spacing-border-blockend">
              <div class="uitk-date-range-container">
                <ul role="tablist" class="uitk-date-range unfade-end">
                  <li class="uitk-date-range-button-wrapper-natural" role="presentation">
                    <button type="button" aria-selected="false" aria-label="Roundtrip flight from Sunday, March 17 to Monday, March 18. Price starting from $398. Highest Price" class="uitk-date-range-button" role="tab">
                      <span class="uitk-date-range-button-date-text">Sun, Mar 17</span>
                      <span class="uitk-date-range-button-text">$398</span>
                    </button>
                  </li>
                  <li class="uitk-date-range-button-wrapper-natural" role="presentation">
                    <button type="button" aria-selected="false" aria-label="Roundtrip flight from Monday, March 18 to Tuesday, March 19. Price starting from $384." class="uitk-date-range-button" role="tab">
                      <span class="uitk-date-range-button-date-text">Mon, Mar 18</span>
                      <span class="uitk-date-range-button-text">$384</span>
                    </button>
                  </li>
                  <li class="uitk-date-range-button-wrapper-natural" role="presentation">
                    <button type="button" aria-selected="false" aria-label="Roundtrip flight from Tuesday, March 19 to Wednesday, March 20. Price starting from $363. Lowest price" class="uitk-date-range-button positive" role="tab">
                      <span class="uitk-date-range-button-date-text">Tue, Mar 19</span>
                      <span class="uitk-date-range-button-text">$363</span>
                    </button>
                  </li>
                  <li class="uitk-date-range-button-wrapper-natural" role="presentation">
                    <button type="button" aria-selected="true" aria-label="Roundtrip flight from Wednesday, March 20 to Thursday, March 21. Price starting from $386.Selected date" class="uitk-date-range-button selected" role="tab">
                      <span class="uitk-date-range-button-date-text">Wed, Mar 20</span>
                      <span class="uitk-date-range-button-text">$386</span>
                    </button>
                  </li>
                  <li class="uitk-date-range-button-wrapper-natural" role="presentation">
                    <button type="button" aria-selected="false" aria-label="Roundtrip flight from Thursday, March 21 to Friday, March 22. Price starting from $398. Highest Price" class="uitk-date-range-button" role="tab">
                      <span class="uitk-date-range-button-date-text">Thu, Mar 21</span>
                      <span class="uitk-date-range-button-text">$398</span>
                    </button>
                  </li>
                  <li class="uitk-date-range-button-wrapper-natural" role="presentation">
                    <button type="button" aria-selected="false" aria-label="Roundtrip flight from Friday, March 22 to Saturday, March 23. Price starting from $387." class="uitk-date-range-button" role="tab">
                      <span class="uitk-date-range-button-date-text">Fri, Mar 22</span>
                      <span class="uitk-date-range-button-text">$387</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mb-3">
              {/* <div
                className={`col-md-4 col-sm-4 flight-filter12 ${selectedFilter === 'BestRate' ? 'flight-filter12-active' : ''}`}
                onClick={() => handleFilterClick('BestRate')}
              >
                <h6><FontAwesomeIcon  className={`${selectedFilter === 'BestRate' ? 'filter-logo-color' : ''}`} icon={faStar} /> Best Rate </h6>
                <div className='d-flex justify-content-between'>
                {showPrice ? (
                <h6 className={`m-2 ${selectedFilter === 'BestRate' ? '' : 'flight-filter-color'}  `}>{CurrencyRates === undefined? baseCName: CurrencyRates.selectedcurrency} {CalculateFLightMarkup(renderPrice(Number(SortCheepestData.price)))}</h6>
                ):(
                  <h6 className={`m-2 ${selectedFilter === 'BestRate' ? '' : 'flight-filter-color'}  `}>{SortCheepestData.currency} {CalculateFLightMarkup(Number(SortCheepestData.price))}</h6>
                )}
                <h6 style={{fontSize:'13px'}} className="mt-2">{SortCheepestData.time}</h6>
                </div>
              </div>
              <div
                className={`col-md-4 col-sm-4 flight-filter12 ${selectedFilter === 'CheapestFirst' ? 'flight-filter12-active' : ''}`}
                onClick={() => handleFilterClick('CheapestFirst')}
              >
                <h6><FontAwesomeIcon className={`${selectedFilter === 'CheapestFirst' ? 'filter-logo-color' : ''}`} icon={faDollarSign} /> Cheapest</h6>
                <div className='d-flex justify-content-between'>
                {showPrice ? (
                <h6 className={`m-2 ${selectedFilter === 'CheapestFirst' ? '' : 'flight-filter-color'}  `}>{CurrencyRates === undefined? baseCName: CurrencyRates.selectedcurrency} {CalculateFLightMarkup(renderPrice(Number(SortCheepestData.price)))}</h6>
                ):(
                  <h6 className={`m-2 ${selectedFilter === 'CheapestFirst' ? '' : 'flight-filter-color'}  `}>{SortCheepestData.currency} {CalculateFLightMarkup(Number(SortCheepestData.price))}</h6>
                )}
                <h6 style={{fontSize:'13px'}} className="mt-2">{SortCheepestData.time}</h6>
                </div>
              </div>
              <div
                className={`col-md-4 col-sm-4 flight-filter12 ${selectedFilter === 'FastestFirst' ? 'flight-filter12-active' : ''}`}
                onClick={() => handleFilterClick('FastestFirst')}
              >
                <h6><FontAwesomeIcon className={`${selectedFilter === 'FastestFirst' ? 'filter-logo-color' : ''}`} icon={faClock} /> Fastest</h6>
                <div className='d-flex justify-content-between'>
                {showPrice ? (
                <h6   className={`m-2 ${selectedFilter === 'FastestFirst' ? '' : 'flight-filter-color'}  `}> {CurrencyRates === undefined? baseCName: CurrencyRates.selectedcurrency} {CalculateFLightMarkup(renderPrice(Number(SortFastestData.price)))}</h6>
                ):(
                  <h6  className={`m-2 ${selectedFilter === 'FastestFirst' ? '' : 'flight-filter-color'}  `}>{SortFastestData.currency} {CalculateFLightMarkup(Number(SortFastestData.price))}</h6>
                )}
                <h6 style={{fontSize:'13px'}} className="mt-2">{SortFastestData.time}</h6>
                </div>
              </div> */}
              <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-two my-2" data-test-id="listing-header-bar">
                <div class="uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-margin-blockend-two uitk-spacing-margin-blockstart- uitk-layout-flex-item uitk-layout-flex-item-flex-basis-zero uitk-layout-flex-item-flex-grow-1" data-test-id="price-disclaimer">Prices may change based on availability and are not final until you complete your purchase. You can review any additional fees before checkout.</div>
                <div class="uitk-spacing uitk-spacing-margin-blockend-two uitk-spacing-margin-blockstart- uitk-layout-flex-item">
                  <div>
                    <form class="uitk-form">
                      <div data-test-id="sortBar">
                        <div class="uitk-field uitk-field-select-field has-floatedLabel-label">
                          <label for="sort-filter-dropdown-SORT" class="uitk-field-label is-visually-hidden">Sort by</label>
                          <select id="sort-filter-dropdown-SORT" name="SORT" class="uitk-field-select">
                            <option value="PRICE_INCREASING" aria-selected="true" data-opt-id="PRICE_INCREASING" onClick={() => handleFilterClick('CheapestFirst')}>Price (Lowest)</option>
                            <option value="PRICE_DECREASING" aria-selected="false" data-opt-id="PRICE_DECREASING" onClick={() => handleFilterClick('FastestFirst')} >Price (Highest)</option>
                            <option value="PRICE_DECREASING" aria-selected="false" data-opt-id="PRICE_DECREASING" onClick={() => handleFilterClick('BestRate')}>Price (Best)</option>
                            <option value="DURATION_INCREASING" aria-selected="false" data-opt-id="DURATION_INCREASING">Duration (Shortest)</option>
                            <option value="DURATION_DECREASING" aria-selected="false" data-opt-id="DURATION_DECREASING">Duration (Longest)</option>
                            <option value="DEPARTURE_INCREASING" aria-selected="false" data-opt-id="DEPARTURE_INCREASING">Departure (Earliest)</option>
                            <option value="DEPARTURE_DECREASING" aria-selected="false" data-opt-id="DEPARTURE_DECREASING">Departure (Latest)</option>
                            <option value="ARRIVAL_INCREASING" aria-selected="false" data-opt-id="ARRIVAL_INCREASING">Arrival (Earliest)</option>
                            <option value="ARRIVAL_DECREASING" aria-selected="false" data-opt-id="ARRIVAL_DECREASING">Arrival (Latest)</option>
                          </select>
                          <svg class="uitk-icon uitk-field-select-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                          </svg>
                          <div class="uitk-field-label" aria-hidden="true">Sort by</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="uitk-spacing bex-homepage-module SimpleContainer px-0">
                <div data-testid="one-key-banner-1" id="one-key-banner-1" className="px-0">
                    <div>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme" data-stid="one-key-message-card">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                                <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                    <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                        <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                    </div>
                                </div>
                                <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                            <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">Earn OneKeyCash on top of airline miles when you sign in and book a flight</h2>
                                            </div>
                                        </div>
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                            <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                              <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FlightCard filterData={filterData} />
          </div>
          <div className="col-md-3">
            <div className='book-package-2 mt-0 rounded-4' style={{backgroundColor:'white'}}>
              <div className="text-center p-4 rounded-4" style={{backgroundColor:'white'}}>
                <h5 style={{color:'#d39d00'}}>Found Flight? Now Find a Hotel</h5>
                <p className="mt-2">Choose From a Wide Range of Properties Which alhijaztours.net Offers. Search Now!</p>
                <button onClick={GoHotels} className="btn search-btn1 btn-success mt-4 w-100 ">Explore hotels</button>
              </div>
            </div>
            <div className=' book-package-2 mt-3 rounded-4' style={{backgroundColor:'white'}}>
              <h6>Why Book with us?</h6>
              <ul class='list-items book-package-3  list-items-2 mt-2 py-2'>
              <li className=' fs-6'>
                  <span className='book-package-side-box'><FontAwesomeIcon icon={faDollar}/></span>No-hassle best price guarantee
                </li>
                <div className='border-line'></div>
                <li className='mt-2 fs-6'>
                  <span className='book-package-side-box'><FontAwesomeIcon icon={faHeadphones}/></span>Customer care available 24/7
                </li>
                <div className='border-line'></div>
                <li className='mt-2 fs-6'>
                  <span className='book-package-side-box'><FontAwesomeIcon icon={faStar}/></span>Picked Packages to your Need
                </li>
                <div className='border-line'></div>
                <li className='mt-2 fs-6'>
                  <span className='book-package-side-box'><FontAwesomeIcon icon={faLock}/></span>Secure Payment & Privacy
                </li>
              </ul>
            </div>
            <div className='book-package-4 rounded-4'>
              <h3 >Got a Question?</h3>
              <div>
                <p  className='mt-3'>Do not hesitate to give us a call. Our expert team would be happy to help you.</p>
                <h5  className='mt-3'><FontAwesomeIcon icon={faPhone}/> 0121 777 2522</h5>
                <h5  className='mt-3'><FontAwesomeIcon icon={faEnvelope}/> info@Expedia.net</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="uitk-spacing SimpleContainer">
            <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                    <figure class="uitk-image uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-image-ratio-3-2 uitk-image-ratio figure-tree">
                        <div class="uitk-image-placeholder">
                            <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/footer/bnaBanners/BEX_Footer.jpg"/>
                        </div>
                    </figure>
                    <div class="uitk-card-content-section uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-tree">
                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-six">
                            <div role="region" aria-labelledby="marquee-title" class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start width-q">
                                <h2 class="uitk-heading uitk-heading-3" id="marquee-title">Go further with the Expedia app</h2>
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-type-paragraph-300 uitk-spacing-padding-blockstart-two uitk-spacing-padding-inlineend-four">Save on select hotels and earn OneKeyCash on bookings in the app. Our app deals help you to save on trips so you can travel more and manage it all on the go.</div>
                                <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-twelve uitk-spacing-padding-inlineend-four uitk-spacing-padding-blockend-two">Scan the QR code with your device camera and download our app</div>
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid switch">
                                    <div class="uitk-spacing uitk-spacing-padding-blockstart-six uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <a id="download-btn" name="download-btn" href="https://expe.app.link/?~customer_ad_set_name=EXPEDIA-US&amp;~customer_ad_name=BRAND.EXPEDIA.OWNED.BRANCH.DEFAULT.MAD&amp;~customer_campaign=BRAND&amp;~customer_placement=BEX-HP-MARQUEEDESKTOP&amp;~placement_id=Homepage&amp;%24canonical_url=https%3A%2F%2Fwww.expedia.com%2F&amp;%24desktop_url=https%3A%2F%2Fwww.expedia.com%2Fapp&amp;custom_web_attribute=add49b7a-17c6-412c-957e-78218a70f45a" class="uitk-button uitk-button-large uitk-button-fullWidth uitk-button-has-text uitk-button-as-link uitk-button-primary">Switch to the app</a>
                                    </div>
                                </div>
                            </div>
                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start QR-code">
                                <div class="uitk-spacing uitk-spacing-padding-inlinestart-four">
                                    <figure class="uitk-image uitk-image-ratio-1-1 uitk-image-ratio">
                                        <div class="uitk-image-placeholder">
                                            <img alt="QR code" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/qr-code/footer_qr_hp/1_QR_SHEET_CONTROL_FOOTER_HP.png"/>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      </Layout>
        </>
    );
}
export default FlightListing;